<template>
    <div>
      <es-title title="Liquidação">
        <div slot="left">
          <div v-if="detalhes == 0 || detalhes == 2" class="button is-warnning" @click="detalhes = 1, getResumo()">Voltar</div>
          <es-datepicker v-if="detalhes == 1" v-model="data" type="button" @selected="getResumo()"></es-datepicker>
        </div>
        <div slot="right">
          <div v-if="this.qtdPendentes > 0" class="button is-danger" @click="detalhes = 2, getPendentes(), countUfsPendentes()">
              {{'Pendentes Excluidos ' + this.qtdPendentes}}
          </div>
          <div v-if="this.qtdPendentes > 0" class="button is-danger" @click="detalhes = 2, getPendentes(), countUfsPendentes()">
              {{'Pendentes ' + this.qtdPendentes}}
          </div>
          <div class="button is-success" @click="load()"><icon name="download"></icon></div>
        </div>
      </es-title>
      <div v-if="detalhes == 2">
        <div class="tabs is-boxed">
          <ul>
            <li v-for="(aba, idx) in abas" v-if="pendentes[aba].length > 0" :class="{'is-active': tab == aba}" @click="set_tab(aba)">
              <a>
                <span>{{ aba }}</span>
                <span :class="{'tag is-rounded': true, 'is-danger': tab == idx}">
                  {{ufsPendentes[aba]}}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <es-table :config="tblPendentes" :rows="pendentes[tab]">
          <div slot="buttons">
            <div class="button" @click="trazer()">Trazer</div>
            <div class="button is-danger" @click="excluir_pendentes()">Excluir Selecionados</div>
          </div>
        </es-table>
      </div>
      <div v-if="detalhes == 1">
        <div class="tabs is-boxed">
          <ul>
            <li v-for="(aba, idx) in AbaGrupos" v-if="resumo[idx].length > 0" :class="{'is-active': tabResumo == idx}" @click="setTab(idx)">
              <a>
                <span>{{ aba }}</span>
              </a>
            </li>
          </ul>
        </div>
        <es-table :config="tblResumo" :rows="resumo[tabResumo]" key="tblResumo">
          <div slot="buttons">
            <div class="button" @click="gerarMapa()">Gerar</div>
            <div class="button" @click="adiarMapa()">Adiar</div>
          </div>
        </es-table>
      </div>
      <div v-else-if="detalhes == 0">
        <es-table :config="tblLiquidacoes" :rows="liquidacoes" key="tblLiquidacoes">
          <div slot="buttons">
            <div class="button" @click="postergar()">postergar</div>
            <div class="button" @click="adiarPagamento()">Adiar</div>
          </div>
        </es-table>
      </div>
      <div v-else>

      </div>
      <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      filtro: {},
      detalhes: 1,
      tblPendentes: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_liquidacao', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante', align: 'center'},
          {name: 'cidade_nome', label: 'Município'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar',
        sum: 'valor',
        key: 'id',
        button: true
      },
      tblLiquidacoes: {
        fields: [
          {name: 'data_liquidacao', label: 'Data', type: 'date'},
          {name: 'status_nome', label: 'Status'},
          {name: 'apresentante_nome', label: 'Apresentante', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'center'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cidade_nome', label: 'Município', align: 'left'},
          {name: 'pagamento', label: 'Pagamento', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar',
        sum: 'valor',
        key: 'id',
        inactive: 'processado',
        button: true
      },
      tblResumo: {
        fields: [
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'conta_debito', label: 'Conta Débito', align: 'center'},
          {name: 'favorecido_nome', label: 'Favorecido', type: 'toupper'},
          {name: 'count', label: 'Qtd', align: 'center'},
          {name: 'gerados', label: 'G', align: 'center', type: 'nozero'},
          {name: 'resto', label: 'Não Gerado', type: 'currency'},
          {name: 'gerados_total', label: 'Total Gerado', type: 'currency'},
          {name: 'total', label: 'Total', type: 'currency'}
        ],
        key: 'id',
        sum: 'total',
        group: {field: 'conta_debito', agg: 'total'},
        rowclick: this.setFiltro,
        inactive: 'gerar',
        button: true
      },
      liquidacoes: [],
      resumo: utils.gerarGrupoApresentante(),
      abas: utils.ufs,
      pendentes: utils.gerarUfs(),
      AbaGrupos: utils.grupoApresentante,
      tab: -1,
      tabResumo: 0,
      qtdPendentes: 0,
      ufsPendentes: {},
      status: utils.liquidacaoStatus
    }
  },
  created () {
    this.data = utils.today()
    this.getResumo()
  },
  methods: {
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/liquidacao/${this.data}`).then((res) => {
        this.getResumo()
        console.log(res.data)
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getResumo () {
      axios.get(`api/v1/batimento/liquidacao/${this.data}`).then((res) => {
        this.resumo = this.monta_dados(res.data, this.tblResumo)
        this.montarAbasGrupos(this.resumo)
        this.countPententes()
      })
      this.detalhes = 1
    },
    monta_dados (dados, conf) {
      var lista = []
      if (dados.length === 0) {
        return lista
      }
      var groupTotal = 0.0
      var groupValue = dados[0][conf.group.field]
      for (var i in dados) {
        if (groupValue !== dados[i][conf.group.field]) {
          var row = {}
          for (var j in conf.fields) {
            if (conf.fields[j].name === conf.group.field) {
              row[conf.fields[j].name] = groupValue
            } else if (conf.fields[j].name === conf.group.agg) {
              row[conf.fields[j].name] = Number(groupTotal)
            } else {
              row[conf.fields[j].name] = ''
            }
          }
          groupTotal = 0.0
          groupValue = dados[i][conf.group.field]
          row.group = true
          lista.push(row)
        }
        groupTotal += Number(dados[i][conf.group.agg])
        dados[i].group = false
        lista.push(dados[i])
      }
      row = {}
      for (j in conf.fields) {
        if (conf.fields[j].name === conf.group.field) {
          row[conf.fields[j].name] = groupValue
        } else if (conf.fields[j].name === conf.group.agg) {
          row[conf.fields[j].name] = groupTotal
        } else {
          row[conf.fields[j].name] = ''
        }
      }
      row.group = true
      lista.push(row)
      return lista
    },
    setFiltro (idx) {
      this.filtro = {data: this.data, grupo: this.resumo[this.tabResumo][idx].grupo, uf: this.resumo[this.tabResumo][idx].uf}
      this.getLiquidacao()
    },
    getLiquidacao () {
      this.liquidacoes = []
      axios.post(`api/v1/batimento/liquidacao/sintetico`, this.filtro).then((res) => {
        for (var i in res.data) {
          res.data[i]['status_nome'] = this.status[res.data[i].status]
        }
        this.liquidacoes = res.data
      })
      this.detalhes = 0
    },
    getPendentes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes`).then((res) => {
        this.montar_abas(res.data)
      })
    },
    montar_abas (data) {
      this.pendentes = utils.gerarUfs()
      if (data.length > 0) {
        this.tab = data[0].uf
      }
      for (var i in data) {
        this.pendentes[data[i].uf].push(data[i])
      }
    },
    montarAbasGrupos (data) {
      this.resumo = utils.gerarGrupoApresentante()
      if (data.length > 0) {
        this.tab = data[0].grupo
      } else {
        return
      }
      for (var i in data) {
        if (data[i].group === true) {
          this.resumo[data[i - 1].grupo].push(data[i])
        } else {
          if (data[i].grupo !== undefined && data[i].grupo !== '') {
            this.resumo[data[i].grupo].push(data[i])
          }
        }
      }
    },
    set_tab (aba) {
      this.tab = aba
    },
    setTab (idx) {
      this.tabResumo = idx
    },
    postergar () {
      var pendente = {
        ids: []
      }
      for (var i in this.liquidacoes) {
        if (this.liquidacoes[i].gerar === true && this.liquidacoes[i].processado === false) {
          pendente.ids.push(this.liquidacoes[i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/postergar`, pendente).then((res) => {
        this.getLiquidacao()
        this.countPententes()
      })
    },
    trazer () {
      var pendente = {
        ids: []
      }
      for (var i in this.pendentes[this.tab]) {
        if (this.pendentes[this.tab][i].gerar === true) {
          pendente.ids.push(this.pendentes[this.tab][i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/trazer`, pendente).then((res) => {
        this.getResumo()
        this.countPententes()
      })
    },
    excluir_pendentes () {
      var pendente = {
        ids: []
      }
      for (var i in this.pendentes[this.tab]) {
        if (this.pendentes[this.tab][i].gerar === true) {
          pendente.ids.push(this.pendentes[this.tab][i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/excluir/pendentes`, pendente).then((res) => {
        this.getResumo()
        this.countPententes()
      })
    },
    countPententes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes/count`).then((res) => {
        this.qtdPendentes = res.data
      })
    },
    countUfsPendentes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes/uf/count`).then((res) => {
        this.ufsPendentes = utils.gerarUfs()
        for (var i in res.data) {
          this.ufsPendentes[res.data[i].uf] = res.data[i].count
        }
      })
    },
    gerarMapa () {
      this.showLoading()
      axios.patch(`api/v1/batimento/liquidacao/gerarmapa/${this.data}/${this.tabResumo}`).then((res) => {
        this.getResumo()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiarMapa () {
      this.showLoading()
      axios.put(`api/v1/batimento/liquidacao/adiarmapa/${this.data}/${this.tabResumo}`).then((res) => {
        this.getResumo()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiarPagamento () {
      this.showLoading()
      var ids = []
      for (var i in this.liquidacoes) {
        if (this.liquidacoes[i].gerar === true) {
          ids.push(this.liquidacoes[i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/adiarpagamento`, ids).then((res) => {
        this.getLiquidacao()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  }
}
</script>
