<template>
  <div>
    <es-title title="Aprovação"></es-title>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="(aba, idx) in abas " v-if="pagamentos[idx].length > 0" :class="{'is-active': tab == idx}" @click="tab = idx">
          <a>
            <span>{{ aba }}</span>
            <span class="tag is-danger is-rounded">{{ pagamentos[idx].length }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <es-table :config="tb1" :rows="pagamentos[tab]">
        <div slot="buttons">
          <div class="button" @click="aprovar()">Aprovar</div>
          <div class="button" @click="rejeitar()">Rejeitar</div>
        </div>
      </es-table>
    </div>
    <br><br><br><br><br>
    <es-title title="Aguardando Liberação"></es-title>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="(aba_liberacao, idx) in abas_liberacao " v-if="pagamentos_liberacao[idx].length > 0" :class="{'is-active': tab_liberacao == idx}" @click="tab_liberacao = idx">
          <a>
            <span>{{ aba_liberacao }}</span>
            <span class="tag is-danger is-rounded">{{ pagamentos_liberacao[idx].length }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <es-table :config="tb1_liberacao" :rows="pagamentos_liberacao[tab_liberacao]">
        <div slot="buttons">
          <div class="button is-success" @click="liberar()">Liberar Pagamento</div>
          <div class="button is-danger" @click="rejeitar_liberacao()">Rejeitar Pagamento</div>
        </div>
      </es-table>
    </div>
    <es-loading :show="loading"></es-loading>
    <es-modal title="Cadastrar Follow Up - Motivo da rejeição" :config="modalFollowUp" >
      <es-form :config="frmFollowUp"></es-form>
    </es-modal>
    <es-modal title="Motivo da rejeição" :config="modalFollowUp_liberacao" >
      <es-form :config="frmFollowUp_liberacao"></es-form>
    </es-modal>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      pagamentos: utils.gerarOrigens(),
      pagamentos_liberacao: utils.gerarOrigens(),
      tab: 0,
      tab_liberacao: 0,
      dados_rejeitar: {},
      dados_rejeitar_liberacao: {},
      abas: utils.origens,
      abas_liberacao: utils.origens,
      tb1: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'lancamento', label: 'Tipo', align: 'center'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'Uf', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'tooltip', data: 'observacao'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'numero_conta_debito', label: 'Conta Debito', align: 'center'},
          {name: 'usuario', label: 'Usuário', align: 'center', type: 'toupper'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        toggle: 'gerar',
        key: 'id',
        button: true
      },
      tb1_liberacao: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'lancamento', label: 'Tipo', align: 'center'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'Uf', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'tooltip', data: 'observacao'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'numero_conta_debito', label: 'Conta Debito', align: 'center'},
          {name: 'usuario', label: 'Usuário', align: 'center', type: 'toupper'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        toggle: 'gerar',
        key: 'id',
        button: true
      },
      modalFollowUp: {
        active: false,
        width: 'large',
        cancel: this.cancel,
        save: this.salvar_FollowUp,
        delete: this.delete
      },
      modalFollowUp_liberacao: {
        active: false,
        width: 'large',
        cancel: this.cancel,
        save: this.salvar_FollowUp_liberacao,
        delete: this.delete
      },
      frmFollowUp: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário Logado', width: 3, type: 'label'}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Ocorrência', type: 'textarea'}
            ]
          }
        ]
      },
      frmFollowUp_liberacao: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário Logado', width: 3, type: 'label'}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Ocorrência', type: 'textarea'}
            ]
          }
        ]
      }
    }
  },
  created () {
    this.get()
    this.getLiberacao()
  },
  methods: {
    get () {
      axios.get('api/v1/batimento/pagamentos/1/').then((res) => {
        this.montar_abas(res.data)
        console.log(res.data)
      })
    },
    getLiberacao () {
      axios.get('api/v1/batimento/pagamentos/99/').then((res) => {
        this.montar_abas_liberacao(res.data)
        console.log(res.data)
      })
    },
    toggle_modalFollowUp () {
      this.modalFollowUp.active = !this.modalFollowUp.active
    },
    toggle_modalFollowUp_liberacao () {
      this.modalFollowUp_liberacao.active = !this.modalFollowUp_liberacao.active
    },
    montar_abas (data) {
      this.pagamentos = utils.gerarOrigens()
      if (data.length > 0) {
        this.tab = data[0].origem
      }
      for (var i in data) {
        this.pagamentos[data[i].origem].push(data[i])
      }
    },
    montar_abas_liberacao (data) {
      this.pagamentos_liberacao = utils.gerarOrigens()
      if (data.length > 0) {
        this.tab_liberacao = data[0].origem
      }
      for (var i in data) {
        this.pagamentos_liberacao[data[i].origem].push(data[i])
      }
    },
    set_tab (idx) {
      this.tab = idx
    },
    set_tab_liberacao (idx) {
      this.tab_liberacao = idx
    },
    aprovar () {
      var lista = []
      for (var i in this.pagamentos[this.tab]) {
        if (this.pagamentos[this.tab][i].gerar) {
          lista.push(this.pagamentos[this.tab][i].id)
        }
      }
      axios.post('api/v1/batimento/pagamentos/aprovacao/0/', lista).then((res) => {
        console.log(res.data)
        if (res.data === 'Erro') {
          this.error('Você não tem permissão para aprovar!')
        } else {
          this.get()
          this.getLiberacao()
          this.warning(res.data)
        }
      })
    },
    liberar () {
      var lista = []
      for (var i in this.pagamentos_liberacao[this.tab_liberacao]) {
        if (this.pagamentos_liberacao[this.tab_liberacao][i].gerar) {
          lista.push(this.pagamentos_liberacao[this.tab_liberacao][i].id)
        }
      }
      axios.post('api/v1/batimento/pagamentos/liberacao/0/', lista).then((res) => {
        console.log(res.data)
        if (res.data === 'Erro') {
          this.error('Você não tem permissão para libear pagamento!')
        } else {
          this.get()
          this.getLiberacao()
          this.warning(res.data)
        }
      })
    },
    rejeitar () {
      this.toggle_modalFollowUp()
    },
    rejeitar_liberacao () {
      this.toggle_modalFollowUp_liberacao()
    },
    salvar_FollowUp () {
      var lista = []
      for (var i in this.pagamentos[this.tab]) {
        if (this.pagamentos[this.tab][i].gerar) {
          lista.push(this.pagamentos[this.tab][i].id)
        }
      }
      this.dados_rejeitar.lista = lista
      this.dados_rejeitar.ocorrencia = this.frmFollowUp.data.ocorrencia
      axios.post('api/v1/batimento/pagamentos/rejeitar/', this.dados_rejeitar).then((res) => {
        this.toggle_modalFollowUp()
        this.get()
        this.success(res.data)
      })
    },
    salvar_FollowUp_liberacao () {
      var lista = []
      for (var i in this.pagamentos_liberacao[this.tab_liberacao]) {
        if (this.pagamentos_liberacao[this.tab_liberacao][i].gerar) {
          lista.push(this.pagamentos_liberacao[this.tab_liberacao][i].id)
        }
      }
      this.dados_rejeitar_liberacao.lista = lista
      this.dados_rejeitar_liberacao.ocorrencia = this.frmFollowUp_liberacao.data.ocorrencia
      axios.post('api/v1/batimento/pagamentos/rejeitar/', this.dados_rejeitar_liberacao).then((res) => {
        this.toggle_modalFollowUp_liberacao()
        this.getLiberacao()
        this.success(res.data)
      })
    }
  }
}
</script>

<style lang="scss">

a.info {
font-family:verdana,arial,helvetica,sans-serif;
position:relative;
border-bottom:1px dashed #ccc;
text-decoration:none;
color:#333333;
}
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
 display:block;
 position:absolute;
 z-index:1;
 bottom:1em;
 width:500px !important;
 border:1px solid #F2AF1D;
 background-color:#FDF1D5;
 color:#000;
 text-align:justify;
 font-size:12px;
 font-weight:normal;
 padding:3px;
 line-height:15px;
}
.es-table-wrapper .es-table-scroller {
  overflow-x: inherit !important;
}
</style>
