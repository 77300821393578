Vue.filter('textoLimit', function (value) {
  var data = value.substring(0,20)
  return data
})
<template>
  <div class="es-table-wrapper" v-if="rows.length > 0">
    <div class="es-table-scroller">
      <table class="table es-table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th class="has-text-centered" v-for="(name, idx) in fields_names" @click="ordernar(name, fields[idx].label)" style="cursor: pointer;">{{ fields[idx].label }} </th>
            <th style="text-align: -moz-center;" v-if="config.toggle != undefined && config.toggle != ''" @click="toggle_row_all()"><icon name="check-circle"></icon></th>
            <th style="width: 90px !important; display: block;border-bottom: none;border-left: none;" v-if="config.actions != undefined && config.actions.length > 0" class="has-text-centered">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, idx) in rows" :class="{'group': row.group, 'inactive': row[config.inactive]}">
            <template v-for="(field, fdx) in fields">
              <td v-if="row.group"
                :class="{
                  'has-text-right': field.align == 'right' || field.type == 'currency' || field.type == 'number',
                  'has-text-centered': field.align == 'center' || (field.type == 'date' || field.type == 'documento')
                }"
              >
                  {{ getValue(row, field.name) | type(field.type) }}
              </td>
              <td v-else :class="getClassObject(field, row)"
                @click="field.cellclick === false ? row_click(idx): (field.cellclick === true) ? execute(field.cellfunction, idx, field.name): ''"
              >
                <template v-if="field.type == 'comprovante'">
                  <div style="width:30px">
                    <div class="" v-if="row[field.name] == 0"></div>
                    <div class="" v-else-if="row[field.name] == 1"><icon name="check-circle"></icon></div>
                    <div class="" v-else-if="row[field.name] == 2"><icon name="check-circle" class="es-success"></icon></div>
                  </div>
                </template>
                <template v-else-if="field.name == 'ocorrencia'">
                  <div><a class="info">  {{ getValue(row, field.name) }}<span class="tooltip-content">Obs :   {{ getValue(row, field.data) }}</span></a></div>
                </template>
                
                <template v-else-if="field.type == 'tooltip' && field.name != 'favorecido' ">
                  <div><a class="info">  {{ getValue(row, field.name) }} <span>{{ getValue(row, field.name) }}</span></a></div>
                </template>
                <template v-else-if="field.type == 'tooltip' && field.data != 'undefined' ">
                  <div><a class="info">  {{ getValue(row, field.name) }} <span>Obs :   {{ getValue(row, field.data) }}</span></a></div>
                </template>
                <template v-else-if="field.type === 'id' || field.name === 'tp_ocorrencia'">
                  <a href="javascript:void(null)" class="info"> ...
                    <span>
                      {{ getValue(row, field.name) | type('id') }}
                    </span>

                  </a>
                </template>
                <template v-else-if="field.type !== undefined">
                  {{ getValue(row, field.name) | type(field.type) }}
                </template>
                <template v-else>
                  {{ getValue(row, field.name) }}
                </template>
              </td>
            </template>
            <td v-if="config.toggle != undefined && config.toggle != ''" class="has-text-centered toggle" @click="toggle_row(idx)"><icon :name="row[config.toggle] ? 'check': ''" scale="1"></icon></td>
            <td v-if="config.actions != undefined && config.actions.length > 0" class="" :class="[row.statusProcessado == 'F' ? 'inactive-cell' : '' ]" >
              <span v-for="item in config.actions"  v-if="(typeof item.action === 'function' && item.action !== '' && item.action !== undefined)"  @click="executeIcon(item.action, idx)">
                <icon v-if="rows[idx].status === 'C' || rows[idx].status == '1'" :name="item.icon"></icon>
                <icon v-else-if="rows[idx].status == '0'  " :name="item.iconSecundario"></icon>
                <icon v-else :name="item.icon"></icon>
              </span>
              <span v-for="item in config.actions"   v-if="((typeof item.action !== 'function' || item.action === '' || item.action === undefined) && (row[item.field] !== undefined && row[item.field] !== ''))">
                <icon :name="item.icon"></icon>
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-if="sum_fields.length == 0">
            <td :colspan="sum_field_pos_left"></td>
            <td v-if="config.sum != undefined && config.sum != ''" class="has-text-right">{{ total | currency }}</td>
            <td v-if="sum_field_pos_right > 0" :colspan="sum_field_pos_right"></td>
            <td v-if="config.toggle != undefined && config.toggle != ''" @click="toggle_row_all()"></td>
            <td v-if="config.actions != undefined && config.actions.length > 0"></td>
          </tr>
          <tr v-else>
            <td v-for="(field, idx) in fields_names">{{ totalField(idx) | number }}</td>
          </tr>
        </tfoot>
      </table>
      <div class="level es-table-buttons">
        <div class="level-left">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
  <!--
  <div v-else class="es-table-empty">
    <h2 class="subtitle">Não há dados para exibir!</h2>
  </div>-->
</template>

<script>

export default {
  data () {
    return {
      fields: [],
      fields_names: [],
      novaRows: [],
      header_columns_size: 0,
      iconVisible: [],
      col: 0,
      cfg_fields: {},
      sum_field_pos_left: 0,
      sum_field_pos_right: 0,
      sum_fields: []
    }
  },
  props: ['config', 'rows'],
  created () {
    this.setCol()
    this.configure()
  },
  filters: {
    limittexto: function (value) {
      var texto = value.substr(0, 25)
      return texto
    }
  },
  methods: {
    ordernar (chave, label) {
      this.rows.sort(function (a, b) {
        if (label === 'Valor') {
          return parseFloat(a[chave]) < parseFloat(b[chave]) ? -1 : 1
        } else {
          return a[chave].localeCompare(b[chave])
        }
      })
    },
    configure () {
      this.sum_fields = []
      for (var i in this.config.fields) {
        var field = {
          name: '',
          label: '',
          placeholder: '',
          sum: false,
          align: 'left',
          type: '',
          clickable: false,
          color: '',
          style: '',
          header: false,
          cellfunction: this.config.fields[i].onclick === undefined ? null : this.config.fields[i].onclick,
          cellclick: this.config.fields[i].onclick !== undefined,
          formats: [],
          formats_objs: []
        }
        Object.assign(field, this.config.fields[i])
        for (var j in field.formats) {
          var format = field.formats[j]
          var reg = {
            condition: format.substr(0, 1),
            formula: format.split(':')[0].slice(2, -1),
            style: format.split(':')[1]
          }
          field.formats_objs.push(reg)
        }
        this.fields_names.push(field.name)
        this.fields.push(field)
        if (field.name === this.config.sum) {
          this.sum_field_pos_left = i
        }

        if (field.sum) {
          this.sum_fields.push(field.name)
        }
      }
      if (this.sum_field_pos_left === 0) {
        this.sum_field_pos_left = this.config.fields.length
      }
      if (this.sum_field_pos_left !== this.config.fields.length - 1) {
        this.sum_field_pos_right = (this.config.fields.length - 1) - this.sum_field_pos_left
      }
    },
    setCol () {
      var col = 0
      if (this.config.sum !== undefined && this.config.sum !== '') {
        col += 1
      }
      if (this.config.fields.indexOf(this.config.actions) >= 0) {
        col += 1
      }
      if (this.config.button !== undefined && this.config.button !== '') {
        col += 1
      }
      this.col = col
    },
    toggle_row (idx) {
      this.rows[idx][this.config.toggle] = !this.rows[idx][this.config.toggle]
    },
    toggle_row_all () {
      for (var i in this.rows) {
        this.$set(this.rows[i], [this.config.toggle], !this.rows[i][this.config.toggle])
      }
    },
    row_click (idx) {
      if (typeof this.config.rowclick === 'function') {
        this.config.rowclick(idx)
      } else {
        this.$emit('rowclick', idx)
      }
    },
    execute (action, idx, field) {
      if (typeof action === 'function') {
        action(idx, field.split('.')[0])
      } else {
        this.$parent[action](idx)
      }
    },
    executeIcon (action, idx) {
      if (typeof action === 'function') {
        action(idx)
      } else {
        this.$parent[action](idx)
      }
    },
    totalField (idx) {
      var tot = 0.0
      if (this.fields[idx].sum) {
        for (var i in this.rows) {
          tot += this.getValue(this.rows[i], this.fields[idx].name) !== undefined ? Number(this.getValue(this.rows[i], this.fields[idx].name)) : 0.0
        }
      }
      return tot
    },
    getValue (obj, path) {
      if (obj === undefined || path === undefined) return ''
      if (typeof path === 'string') path = path.split('.')
      if (path.length === 0) return obj
      var val = this.getValue(obj[path[0]], path.slice(1))
      return val
    },
    getClassObject (field, row) {
      var obj = {
        'has-text-right': field.align === 'right' || field.type === 'currency' || field.type === 'number',
        'has-text-centered': field.align === 'center' || field.type === 'date' || field.type === 'documento' || field.type === 'comprovante',
        'es-success': field.color === 'success',
        'es-danger': field.color === 'danger',
        'es-info': field.color === 'info',
        'es-link': field.color === 'link',
        'es-warning': field.color === 'warning',
        'clickable': field.clickable,
        'es-table-header-column': field.header,
        'inactive-cell': row.statusProcessado === 'F'
      }
      obj[field.style] = field.style !== ''
      for (var i in field.formats_objs) {
        var b = this.getFormulaValidate(field.name, field.formats_objs[i], row)
        obj[field.formats_objs[i].style] = b
      }
      return obj
    },
    getFormulaValidate (field, f, row) {
      var formula = f.formula.split(',')
      var operator = '+'
      var val = this.getValue(row, formula[1])
      for (var i in formula) {
        if (formula[i] === '*') {
          operator = formula[i]
          continue
        }
        if (i === '1') continue
        if (this.getValue(row, formula[i]) === '') continue
        switch (operator) {
          case '+':
            val += this.getValue(row, formula[i])
            break
          case '-':
            val -= this.getValue(row, formula[i])
            break
          case '*':
            val *= this.getValue(row, formula[i])
            break
          case '/':
            val /= this.getValue(row, formula[i])
            break
        }
      }
      val = Math.round(val * 100) / 100
      var res = false
      switch (f.condition) {
        case '>':
          res = val > this.getValue(row, field)
          break
        case '<':
          res = val < this.getValue(row, field)
          break
        case '=':
          res = val === this.getValue(row, field)
          break
      }
      return res
    }
  },

  computed: {
    total () {
      var tot = 0.0
      for (var i in this.rows) {
        if (this.rows[i][this.config.toggle] || this.rows[i][this.config.toggle] === undefined) {
          if (!this.rows[i].group) {
            tot += Number(this.rows[i][this.config.sum])
          }
        }
      }
      return tot
    }
  }
}
</script>

<style lang="sass">

.es-table-wrapper
  position: relative
  .es-table-scroller
    /* margin-left: 141px */
    overflow-x: scroll
    overflow-y: visible
    padding-bottom: 5px
    width: 100%
    .es-table
      margin-bottom: 0em
      .es-table-sticky-col
        border-left: solid 1px #DDEFEF
        border-right: solid 1px #DDEFEF
        left: 0
        position: absolute
        top: auto
        width: 120px

.es-table-empty
  display: grid
  height: 300px
  align-content: start
  justify-content: start
  h2
    background: #dedede
    padding: 0.3em 0.5em
    color: #fff
    border-radius: 0.2em

.es-table-header-column
  background: #ededed
  font-weight: bold

.es-table-buttons
  padding: 0.5em
  background: #ededed
  border-radius: 0.2em
  border: solid #ccc 1px
.inactive-cell
  color: #777;
  cursor: not-allowed;
</style>
<style lang="css">
a.info {
  font-family:verdana,arial,helvetica,sans-serif;
  position:relative;
  border-bottom:1px dashed #ccc;
  text-decoration:none;
  color:#333333;
};
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 2em;
  left: 0;
  width: auto !important;
  border: 1px solid #F2AF1D;
  background-color: #FDF1D5;
  color: #000;
  text-align: justify;
  font-size: 10px;
  font-weight: normal;
  padding: 10px;
  min-width: 237px;
  line-height: 15px;
}
.table {
    font-size: 11px !important;
}
.fa-icon {
  margin-left: 5px !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: -130%; 
  bottom: 100%; 
  margin-left: -180px;
  opacity: 0;
  transition: opacity 0.3s;
}

.info:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
</style>
