<template>
  <div>
    <es-title title="Despesas Eletronica">
        <div slot="left">
          <es-datepicker v-if="tab == 0" v-model="data" type="button" @selected="get_resumo()"></es-datepicker>
          <a v-if="tab != 0" class="button is-warnning" @click="set_tab(0)">Voltar</a>
        </div>
        <div slot="right">
          <div class="button is-link" @click="getModalTrazerCustas()"><icon name="download"></icon> Trazer Custas</div>
          <div class="button is-warning" @click="getCustasSemDocumento()"><span class="tag is-danger is-rounded">{{ totalSemDoc }}</span>&nbsp; Sem Documento</div>
          <div class="button is-primary" @click="getPostergados()">Postergadas</div>
          <!-- <div class="button is-success" @click="load()"><icon name="download"></icon></div> -->
        </div>
    </es-title>
    <es-filter :config="fPesquisa"></es-filter>
    <br>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="(aba, idx) in abas " :class="{'is-active': tabMovimento == idx}" @click="setTab(idx)">
          <a> <span>{{ aba }}</span> </a>
        </li>
      </ul>
    </div>
    <div v-if="tabMovimento == 1">
      <div v-if="tab == 0">
        <table class="table is-bordered is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered" rowspan="2" width="500px">Apresentante</th>
              <th class="has-text-centered" rowspan="2" width="10%">UF</th>
              <th class="has-text-centered" colspan="4" width="500px">Confirmação</th>
              <th class="has-text-centered" rowspan="2" width="500px">Total</th>
            </tr>
            <tr>
              <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">P</th>
              <th class="has-text-centered">G</th>
              <th class="has-text-centered">Total</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx, key) in resumo">
              <tr v-if :key="key">
                <td>{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td class="has-text-centered">{{ item.uf }}</td>
                <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'C')">{{ item.confirmacao_qtd | nozero }}</td>
                <td class="has-text-centered">{{ item.confirmacao_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.confirmacao_gerados | nozero }}</td>
                <td class="has-text-right">{{ item.confirmacao_total | currency }}</td>
                <td class="has-text-right">{{ item.total | currency }}</td>                
              </tr>
              <tr :key="key">
                <td colspan="11" style="background: #aeaeae;">
                  <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> Simplificado </span></h5>
                    <table style="width:100%" class="table is-bordered is-hoverable is-fullwidth">
                     <thead>
                      <tr>
                        <th>Data</th>
                        <th>UF</th>
                        <th>Favorecido</th>
                        <th>Recno</th>
                        <th>Banco</th>
                        <th>Agência</th>
                        <th>Conta</th>
                        <th>N Pagamento</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tr style="background: #fff;">
                      <th>02/04/2019</th>
                      <th>AC</th>
                      <th>BANCOOB</th>
                      <th>00001</th>
                      <th>756</th>
                      <th>00001-1</th>
                      <th>1111-1</th>
                      <th> - </th>
                      <th>R$ 775,19</th>
                    </tr>
                    <tr>
                      <td colspan="11" style="background: #aeaeae;">
                        <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> Por Apresentante</span></h5>
                        <table style="width:100%" class="table is-bordered is-hoverable is-fullwidth">
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Apresentante</th>
                              <th>QTD</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tr style="background: #fff;">
                            <th>02/04/2019</th>
                            <th>033</th>
                            <th>1</th>
                            <th>R$ 100,00</th>
                          </tr>
                          <tr style="background: #fff;">
                            <th>02/04/2019</th>
                            <th>237</th>
                            <th>1</th>
                            <th>R$ 175,19</th>
                          </tr>
                          <tr style="background: #fff;">
                            <th>02/04/2019</th>
                            <th>341</th>
                            <th>2</th>
                            <th>R$ 200,00</th>
                          </tr>
                          <tr style="background: #fff;">
                            <th>02/04/2019</th>
                            <th>001</th>
                            <th>2</th>
                            <th>R$ 200,00</th>
                          </tr>
                          <td colspan="11" style="background: #aeaeae;">
                            <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> Por Título</span></h5>
                            <table style="width:100%" class="table is-bordered is-hoverable is-fullwidth">
                              <thead>
                                <tr>
                                  <th>Data</th>
                                  <th>N Título</th>
                                  <th>Valor</th>
                                </tr>
                              </thead>
                              <tr style="background: #fff;">
                                <th>02/04/2019</th>
                                <th>001</th>
                                <th>R$ 100,00</th>
                              </tr>
                              <tr style="background: #fff;">
                                <th>02/04/2019</th>
                                <th>0011</th>
                                <th>R$ 175,19</th>
                              </tr>
                              <tr style="background: #fff;">
                                <th>02/04/2019</th>
                                <th>002</th>
                                <th>R$ 100,00</th>
                              </tr>
                              <tr style="background: #fff;">
                                <th>02/04/2019</th>
                                <th>0027</th>
                                <th>R$ 100,00</th>
                              </tr>
                              <tr style="background: #fff;">
                                <th>02/04/2019</th>
                                <th>0022</th>
                                <th>R$ 100,00</th>
                              </tr>
                              <tr style="background: #fff;">
                                <th>02/04/2019</th>
                                <th>0022</th>
                                <th>R$ 100,00</th>
                              </tr>
                            </table> 
                          </td>
                        </table> 
                      </td>
                    </tr>
                  </table> 
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <button class="button" @click="gerarPagamentosDia()">Gerar</button>
                <button class="button is-info" @click="relatorioPdf()">Relatório Banco PDF</button>
                <button class="button is-success is-pulled-right" @click="regerarPagamentosDia()">Regerar Custas</button>
              </td>
              <td colspan="4" class="has-text-right">{{ total_retorno | currency }}</td>
              <td colspan="4" class="has-text-right">{{ total_confirmacao | currency }}</td>
              <td class="has-text-right">{{ (total_retorno + total_confirmacao) | currency}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <es-loading :show="loading"></es-loading>
  </div>

</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      data_atual: '',
      resumo: [],
      resumo_santander: [],
      resumo_empresas: [],
      tipoPG: '1',
      totalSemDoc: '0',
      custas: [],
      followup: [],
      favorecidos: [],
      tabMovimento: 1,
      postergados: [],
      lista: {},
      subtitulo: '',
      selected: {},
      filter: {
        apresentante: '',
        uf: '',
        tipo_arquivo: ''
      },
      abas: {
        1: 'Banco',
        2: 'Empresas'
      },
      tab: 0,
      tabFavorecido: 0,
      token: '',
      tblFavorecido: {
        fields: [
          {name: 'conta_id', label: 'ID', align: 'right'},
          {name: 'favorecido_nome', label: 'Nome', type: 'toupper'},
          {name: 'favorecido_documento', label: 'Documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      tblCustas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'municipio', label: 'Municipio'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'data_custa', label: 'Data', type: 'date'},
          {name: 'status', label: 'S', align: 'center'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_numero', label: 'Cartório', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'pagamento_id', label: 'Pagamento', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'data_fechamento', label: 'Data do Pagamento', type: 'date'},
          {name: 'tp_ocorrencia', label: 'Ocorrência', align: 'center'}
        ],
        sum: 'valor',
        group: {field: 'data_custa', agg: 'valor'},
        toggle: 'gerar',
        inactive: 'inativo',
        key: 'id',
        actions: [
          {action: this.modalAddFollowUp, icon: 'commenting-o'},
          {action: this.modalAddFollowUp, icon: 'trash-o'}
        ],
        button: true
      },
      tblFollowUp: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'ocorrencia', label: 'Ocorrencia', type: 'tooltip'},
          {name: 'dataGravacao', label: 'Data', type: 'date'},
          {name: 'usario_nome', label: 'Usuário', align: 'center'}
        ],
        key: 'id',
        button: true
      },
      tblPostegadas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_custa', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'municipio', label: 'Municipio'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_numero', label: 'Cartório', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', align: 'center'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar'
      },
      frmCustas: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'documento', label: 'Documento', width: 4, type: 'document', onExit: this.getFavorecido},
              {name: 'favorecido', label: 'Favorecido', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'banco', label: 'Banco', width: 2, type: 'text'},
              {name: 'agencia', label: 'Agência', width: 3, type: 'text'},
              {name: 'favorecido_conta', label: 'Conta', width: 3, type: 'text'},
              {name: 'tipo_conta', label: 'Tipo Conta', type: 'select', values: [{value: 'C', text: 'Corrente'}, {value: 'P', text: 'Poupança'}]}
            ]
          }
        ]
      },
      fPesquisa: {
        fields: [
          {name: 'data_Pesquisa', label: 'Data Inicial', type: 'data', width: 1},
          {name: 'data_PesquisaF', label: 'Data Final', type: 'dataF', width: 1},
          {
            name: 'apresentante_Pesquisa_id',
            label: 'Apresentante',
            width: 2,
            type: 'select',
            values: []
          },
          {
            name: 'UF_Pesquisa',
            label: 'Estado',
            placeholder: 'UF',
            type: 'select',
            width: 2,
            values: [
              {value: '0', text: 'Todos'},
              {value: 'AC', text: 'Acre'},
              {value: 'AL', text: 'Alagoas'},
              {value: 'AP', text: 'Amapá'},
              {value: 'AM', text: 'Amazonas'},
              {value: 'BA', text: 'Bahia'},
              {value: 'CE', text: 'Ceará'},
              {value: 'DF', text: 'Distrito Federal'},
              {value: 'ES', text: 'Espírito Santo'},
              {value: 'GO', text: 'Goiás'},
              {value: 'MA', text: 'Maranhão'},
              {value: 'MT', text: 'Mato Grosso'},
              {value: 'MS', text: 'Mato Grosso do Sul'},
              {value: 'MG', text: 'Minas Gerais'},
              {value: 'PA', text: 'Pará'},
              {value: 'PB', text: 'Paraíba'},
              {value: 'PR', text: 'Paraná'},
              {value: 'PE', text: 'Pernambuco'},
              {value: 'PI', text: 'Piauí'},
              {value: 'RJ', text: 'Rio de Janeiro'},
              {value: 'RN', text: 'Rio Grande do Norte'},
              {value: 'RS', text: 'Rio Grande do Sul'},
              {value: 'RO', text: 'Rondônia'},
              {value: 'RR', text: 'Roraima'},
              {value: 'SC', text: 'Santa Catarina'},
              {value: 'SP', text: 'São Paulo'},
              {value: 'SE', text: 'Sergipe'},
              {value: 'TO', text: 'Tocantins'}
            ]
          },
          {name: 'valor', label: 'Valor da custa', width: 3, type: 'moeda', disable: true},
          {name: 'documento', label: 'Documento', placeholder: 'CPF/CNPJ', type: 'document', width: 2}
        ],
        button: {label: 'Localizar', side: 'right', action: this.localizar}
      },
      frmTrazerCustas: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'data_pg', label: 'Pagar em', type: 'text', disable: true},
              {name: 'data_custa_trazer', label: 'Data da Custa', type: 'data', width: 3},
              {
                name: 'apresentante_id',
                label: 'Apresentante',
                width: 4,
                type: 'select',
                values: []
              },
              {name: 'uf', label: 'UF', width: 2, type: 'select', values: [{value: 'AC', text: 'AC'}, {value: 'AL', text: 'AL'}, {value: 'AM', text: 'AM'}, {value: 'AP', text: 'AP'}, {value: 'BA', text: 'BA'}, {value: 'CE', text: 'CE'}, {value: 'DF', text: 'DF'}, {value: 'ES', text: 'ES'}, {value: 'GO', text: 'GO'}, {value: 'MA', text: 'MA'}, {value: 'MG', text: 'MG'}, {value: 'MS', text: 'MS'}, {value: 'MT', text: 'MT'}, {value: 'PA', text: 'PA'}, {value: 'PB', text: 'PB'}, {value: 'PE', text: 'PE'}, {value: 'PI', text: 'PI'}, {value: 'PR', text: 'PR'}, {value: 'RJ', text: 'RJ'}, {value: 'RN', text: 'RN'}, {value: 'RO', text: 'RO'}, {value: 'RR', text: 'RR'}, {value: 'RS', text: 'RS'}, {value: 'SC', text: 'SC'}, {value: 'SE', text: 'SE'}, {value: 'SP', text: 'SP'}, {value: 'TO', text: 'TO'}]},
              {name: 'tipo_arq', label: 'Tipo do Arquivo', width: 3, type: 'select', values: [{value: 'R', text: 'Retorno'}, {value: 'C', text: 'Confirmação'}]}
            ]
          }
        ]
      },
      frmFollowUp: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário Logado', width: 3, type: 'label'},
              {name: 'valor', label: 'Valor da custa', width: 3, type: 'moeda_custa', disable: true},
              {name: 'valor_subtrair', label: 'Subtrair', type: 'moeda_custa', onExit: this.subtrair},
              {name: 'NovoValor', label: 'Novo Valor', type: 'moeda_custa', disable: true}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Ocorrência', type: 'textarea'}
            ]
          }
        ]
      },
      modal: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        save: this.salvar_conta
      },
      modalFollowUp: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        save: this.salvar_FollowUp,
        delete: this.delete
      },
      modalTrazerCustas: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        trazer: this.trazer_custa
      },
      total_retorno: 0.0,
      total_confirmacao: 0.0,
      total_retorno_san: 0.0,
      total_confirmacao_san: 0.0,
      total_retorno_emp: 0.0,
      total_confirmacao_emp: 0.0
    }
  },
  created () {
    this.data = utils.lastUtilDay()
    this.data_atual = utils.lastUtilDay()
    this.get_resumo()
    this.getTotalSemDoc()
    this.get_apresentante()
    this.frmTrazerCustas.data.apresentante_id = '001'
    this.frmTrazerCustas.data.uf = 'AC'
    this.frmTrazerCustas.data.tipo_arq = 'R'
    this.frmTrazerCustas.data.data_pg = this.data
    this.frmFollowUp.data.valor_subtrair = '0,00'
  },
  methods: {
    setTab (idx) {
      this.set_tab(0)
      this.tabMovimento = idx
      this.tipoPG = idx
    },
    localizar (arg) {
      this.showLoading()
      var data = arg.data
      var dataF = arg.dataF
      if (arg.documento !== undefined) {
        arg.documento = utils.documentoNumero(arg.documento)
      } else {
        arg.documento = '0'
      }
      if ((arg.valor === undefined) || (arg.valor === '')) {
        arg.valor = '0'
      }
      if ((dataF === undefined) || (data === '') || dataF === '') {
        this.error('Preencha os dois campos Data')
      } else if (arg.apresentante_Pesquisa_id === undefined) {
        console.log(arg.apresentante_Pesquisa_id)
        this.error('Informe o apresentante')
      } else if (arg.UF_Pesquisa === undefined) {
        console.log(arg.UF_Pesquisa)
        this.error('Informe o estado')
      } else {
        axios.post(`api/v1/batimento/custas/localizar`, arg).then((res) => {
          if (res.data === '0') {
            this.error('Não existe custas para esse filtro')
          } else {
            this.subtitulo = `Custas encontradas`
            this.tab = 1
            this.custas = res.data
            for (var i = 0; i < res.data.length; i++) {
              switch (res.data[i]['tp_ocorrencia']) {
                case '2':
                  this.custas[i]['tp_ocorrencia'] = 'Protestado'
                  break
                case '3':
                  this.custas[i]['tp_ocorrencia'] = 'Retirada'
                  break
                default:
                  this.custas[i]['tp_ocorrencia'] = 'Carregado'
              }
            }
          }
          this.closeLoading()
        }).catch((err) => {
          this.error(err.response.data)
        })
      }
    },
    subtrair () {
      var valSubtrair1 = this.frmFollowUp.data.valor_subtrair
      var valSub = parseFloat(this.retirarMascara(valSubtrair1))
      var valAtual = this.frmFollowUp.data.valor
      var valAtualSemM = parseFloat(this.retirarMascara(valAtual))
      var valorSubtraido = parseFloat(valAtualSemM) - parseFloat(valSub)
      var valorFinal = ''
      if (valorSubtraido.toFixed(2) < 0) {
        valorFinal = '-' + this.aplicaMascara(String(valorSubtraido.toFixed(2)))
      } else {
        valorFinal = this.aplicaMascara(String(valorSubtraido.toFixed(2)))
      }
      this.$set(this.frmFollowUp.data, 'valor_subtrair', this.aplicaMascara(valSubtrair1))
      this.$set(this.frmFollowUp.data, 'NovoValor', valorFinal)
    },
    get_resumo () {
      axios.get(`api/v1/batimento/custas/${this.data}`).then((res) => {
        this.resumo = []
        this.resumo_santander = []
        this.resumo_empresas = []
        if (Object.keys(res.data).length === 0) {
          console.log('sem dados banco')
        } else {
          this.monta_resumo(res.data)
        }
        axios.get(`api/v1/batimento/custas/Santander/${this.data}`).then((res) => {
          console.log('entrou 2')
          if (Object.keys(res.data).length === 0) {
            console.log('sem dados santander')
          } else {
            this.monta_resumo_santander(res.data)
          }
        }).catch((err) => {
        })

        axios.get(`api/v1/batimento/custas/Empresas/${this.data}`).then((res) => {
          console.log('entrou 3')
          if (Object.keys(res.data).length === 0) {
            console.log('sem dados empresa')
          } else {
            this.monta_resumo_empresas(res.data)
          }
        }).catch((err) => {
        })
      }).catch((err) => {
      })
      this.getTotalSemDoc()
    },
    get_apresentante () {
      axios.get(`api/v1/batimento/apresentantes/filtro/`).then((res) => {
        for (var i in res.data) {
          this.frmTrazerCustas.levels[0].fields[2].values.push({value: res.data[i].codigo, text: res.data[i].codigo + ' - ' + res.data[i].nome})
          this.fPesquisa.fields[2].values.push({value: res.data[i].codigo, text: res.data[i].codigo + ' - ' + res.data[i].nome})
        }
        this.fPesquisa.fields[2].values['0'].selected = 'true'
      })
    },
    relatorioPdf () {
      this.showLoading()
      var dt = this.data
      var tipo = this.tabMovimento
      console.log(dt)
      axios.get(`api/v1/batimento/custas/relatorio/geral/pdf/${dt}/${tipo}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioUFPdf () {
      this.showLoading()
      var dt = this.data
      var tipo = this.tabMovimento
      console.log(dt)
      axios.patch(`api/v1/batimento/custas/relatorio/geral/pdf/${dt}/${tipo}/${this.filter.apresentante}/${this.filter.uf}/${this.filter.tipo_arquivo}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    modalAddFollowUp (idx) {
      this.get_followup(this.custas[idx].id)
      this.frmFollowUp.data.idCusta = this.custas[idx].id
      this.$set(this.frmFollowUp.data, 'valor', this.aplicaMascara(this.custas[idx].valor))
      this.$set(this.frmFollowUp.data, 'NovoValor', this.aplicaMascara(this.custas[idx].valor))
      this.frmFollowUp.data.tela = 'C'
      this.toggle_modalFollowUp()
    },
    retirarMascara (n) {
      var v = n
      v = v.replace(/\D/g, '') // permite digitar apenas numero
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1.$2') // coloca virgula antes dos ultimos 2 digitos
      return v
    },
    aplicaMascara (n) {
      var v = n
      v = v.replace(/\D/g, '') // permite digitar apenas numero
      v = v.replace(/(\d{1})(\d{14})$/, '$1.$2') // coloca ponto antes dos ultimos digitos
      v = v.replace(/(\d{1})(\d{11})$/, '$1.$2') // coloca ponto antes dos ultimos 11 digitos
      v = v.replace(/(\d{1})(\d{8})$/, '$1.$2') // coloca ponto antes dos ultimos 8 digitos
      v = v.replace(/(\d{1})(\d{5})$/, '$1.$2') // coloca ponto antes dos ultimos 5 digitos
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos
      return v
    },
    monta_resumo_empresas (res) {
      this.total_retorno_emp = 0.0
      this.total_confirmacao_emp = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            totalRetorno += Number(obj['retorno_total'])
            this.total_retorno_emp += Number(obj['retorno_total'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao_emp += Number(obj['confirmacao_total'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo_empresas = list
    },
    monta_resumo_santander (res) {
      this.total_retorno_san = 0.0
      this.total_confirmacao_san = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            totalRetorno += Number(obj['retorno_total'])
            this.total_retorno_san += Number(obj['retorno_total'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao_san += Number(obj['confirmacao_total'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo_santander = list
    },
    monta_resumo (res) {
      this.total_retorno = 0.0
      this.total_confirmacao = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            totalRetorno += Number(obj['retorno_total'])
            this.total_retorno += Number(obj['retorno_total'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao += Number(obj['confirmacao_total'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo = list
    },
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/${this.data}`).then((res) => {
        this.get_resumo()
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getTotalSemDoc () {
      axios.get(`api/v1/batimento/custas/total_sem_documento/${this.data}`).then((res) => {
        this.totalSemDoc = res.data
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getModalTrazerCustas () {
      this.toggle_modalTrazerCustas()
    },
    getCustasSemDocumento () {
      this.showLoading()
      axios.get(`api/v1/batimento/custas/sem_documento/${this.data}`).then((res) => {
        this.subtitulo = `Custas sem Documento`
        this.tab = 1
        this.custas = res.data
        for (var i = 0; i < res.data.length; i++) {
          switch (res.data[i]['tp_ocorrencia']) {
            case '2':
              this.custas[i]['tp_ocorrencia'] = 'Protestado'
              break
            case '3':
              this.custas[i]['tp_ocorrencia'] = 'Retirada'
              break
            default:
              this.custas[i]['tp_ocorrencia'] = 'Carregado'
          }
        }
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getPostergados () {
      this.showLoading()
      axios.get(`api/v1/batimento/custas/postergados`).then((res) => {
        this.postergados = res.data
        this.tab = 2
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    get_custas_apresentante_uf (idx, tipo) {
      if (this.tipoPG === '1') {
        this.selected = this.resumo[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo[idx].apresentante
        this.filter.uf = this.resumo[idx].uf
      } if (this.tipoPG === '2') {
        this.selected = this.resumo_santander[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo_santander[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo_santander[idx].apresentante
        this.filter.uf = this.resumo_santander[idx].uf
      } if (this.tipoPG === '3') {
        this.selected = this.resumo_empresas[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo_empresas[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo_empresas[idx].apresentante
        this.filter.uf = this.resumo_empresas[idx].uf
      }
      this.filter.tipo_arquivo = tipo
      this.get_custas()
    },
    get_custas () {
      axios.get(`api/v1/batimento/custas/${this.data}/${this.filter.apresentante}/${this.filter.uf}/${this.filter.tipo_arquivo}`).then((res) => {
        this.subtitulo = `${this.selected.apresentante} - ${this.selected.uf} - ${utils.tipo_arquivo[this.selected.tipo]}`
        this.tab = 1
        this.custas = res.data
        for (var i = 0; i < res.data.length; i++) {
          switch (res.data[i]['tp_ocorrencia']) {
            case '2':
              this.custas[i]['tp_ocorrencia'] = 'Protestado'
              break
            case '3':
              this.custas[i]['tp_ocorrencia'] = 'Retirada'
              break
            default:
              this.custas[i]['tp_ocorrencia'] = 'Carregado'
          }
        }
      })
    },
    get_followup (idx) {
      axios.get(`api/v1/batimento/custas/followup/${idx}`).then((res) => {
        this.followup = res.data
      })
    },
    gerar_pagamentos () {
      this.showLoading()
      axios.post('api/v1/batimento/custas/pagamentos', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success('Pagamentos gerados com sucesso!')
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    gerarPagamentosDia () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/pagamentos/${this.data}/${this.tipoPG}`).then((res) => {
        this.get_resumo()
        if (res.data === 'Favorecido sem documento, informe o documento do favorecido!') {
          this.success(res.data)
        } else {
          this.success(`Arquivo ${res.data.arquivo} gerado com sucessso!`)
        }
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    regerarPagamentosDia () {
      this.showLoading()
      axios.put(`api/v1/batimento/custas/pagamentos/regerar/${this.data}/${this.tipoPG}`).then((res) => {
        this.get_resumo()
        if (res.data === 'Favorecido sem documento, informe o documento do favorecido!') {
          this.success(res.data)
        } else {
          this.success(`Arquivo ${res.data.arquivo} gerado com sucessso!`)
        }
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiar () {
      this.showLoading()
      axios.patch('api/v1/batimento/custas/adiar', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    postergar () {
      this.showLoading()
      axios.post('api/v1/batimento/custas/adiar', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazer () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/trazer/${utils.lastUtilDay()}`, this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazerPostergados () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/trazer/${utils.lastUtilDay()}`, this.getIds(this.postergados)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    restaurar () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/restaurar`, this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getIds (lista) {
      var ids = []
      for (var i in lista) {
        if (lista[i].gerar) {
          ids.push(lista[i].id)
        }
      }
      return ids
    },
    set_tab (n) {
      if (n === 0) {
        this.get_resumo()
      }
      this.tab = n
      this.custa = []
    },
    toggle_gerar (idx) {
      this.custas[idx].gerar = !this.custas[idx].gerar
    },
    toggle_modal () {
      this.modal.active = !this.modal.active
    },
    toggle_modalFollowUp () {
      this.modalFollowUp.active = !this.modalFollowUp.active
    },
    toggle_modalTrazerCustas () {
      this.modalTrazerCustas.active = !this.modalTrazerCustas.active
    },
    nova_conta (idx) {
      if ((this.custas[idx].favorecido === null) || (this.custas[idx].favorecido === '')) {
        this.toggle_modal()
        this.frmCustas.data.id = this.custas[idx].id
      }
    },
    novoFollowUp (idx) {
      axios.get(`api/v1/batimento/custas/followup/${this.custas[idx].id}`).then((res) => {
        this.followup = res.data
      })
      this.toggle_modalFollowUp()
      this.frmFollowUp.data.idCusta = this.custas[idx].id
      this.$set(this.frmFollowUp.data, 'valor_subtrair', '0,00')
    },
    salvar_conta () {
      var banco = this.frmCustas.data.banco
      var ttbanco = banco.trim()
      var tbanco = ttbanco.length
      var agencia = this.frmCustas.data.agencia
      var tagencia = agencia.indexOf('\t')
      var esagencia = agencia.indexOf(' ')
      var conta = this.frmCustas.data.favorecido_conta
      var tconta = conta.indexOf('\t')
      var esconta = conta.indexOf(' ')
      if (tbanco < 3) {
        alert('Campo banco tem que conter 3 dígitos')
        document.getElementById('banco').focus()
      } else if ((tagencia !== -1) || (esagencia !== -1)) {
        alert('Não é permitido espaços em branco para agência')
        document.getElementById('agencia').focus()
      } else if ((tconta !== -1) || (esconta !== -1)) {
        alert('Não é permitido espaços em branco para conta')
        document.getElementById('favorecido_conta').focus()
      } else {
        axios.post(`api/v1/batimento/custas/conta`, this.frmCustas.data).then((the) => {
          this.toggle_modal()
          this.frmCustas.data = {}
          this.get_custas()
          this.getTotalSemDoc()
          this.getCustasSemDocumento()
          this.modal.active = false
        })
      }
    },
    trazer_custa () {
      this.showLoading()
      var ok = 'True'
      if (this.frmTrazerCustas.data.data_custa_trazer === undefined || this.frmTrazerCustas.data.data_custa_trazer === '') {
        alert('Selecione a Data')
        ok = 'false'
      }
      if (this.frmTrazerCustas.data.apresentante_id === undefined || this.frmTrazerCustas.data.apresentante_id === '') {
        alert('Selecione o apresentatne')
        ok = 'false'
      }
      if (this.frmTrazerCustas.data.uf === undefined || this.frmTrazerCustas.data.uf === '') {
        alert('Selecione o Estado')
        ok = 'false'
      }
      if (this.frmTrazerCustas.data.tipo_arq === undefined || this.frmTrazerCustas.data.tipo_arq === '') {
        alert('Selecione o tipo do Arquivo')
        ok = 'false'
      }

      if (ok === 'True') {
        axios.post(`api/v1/batimento/custas/trazer/nacional/`, this.frmTrazerCustas.data).then((res) => {
          if (res.data === 'Nenhuma custa encontrada - Verifique se a UF esta hábilitada no ADMIN!') {
            this.error(res.data)
          } else {
            this.get_resumo()
            this.getTotalSemDoc()

            this.success(res.data)
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    salvar_FollowUp () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.valor_subtrair === '') {
        this.frmFollowUp.data.valor_subtrair = '0,00'
      }
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha a Ocorrência')
      } else {
        console.log(parseInt(this.frmFollowUp.data.NovoValor))
        var inteiro = this.frmFollowUp.data.NovoValor
        var negativo = inteiro.indexOf('-')
        if (negativo === 0) {
          this.$set(this.frmFollowUp.data, 'valor_subtrair', '0,00')
          alert('A Custa não pode ter valor Negativo')
        } else {
          axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
            this.toggle_modalFollowUp()
            this.frmFollowUp.data = {}
            this.get_custas()
            this.modalFollowUp.active = false
          })
        }
      }
    },
    delete () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.valor_subtrair === '' || this.frmFollowUp.data.valor_subtrair === undefined) {
        this.frmFollowUp.data.valor_subtrair = '0,00'
      }
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha o Ocorrência')
      } else {
        axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.get_custas()
          this.modalFollowUp.active = false
        })

        axios.post(`api/v1/batimento/custas/update-delete`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.frmFollowUp.data = {}
          this.get_custas()
          this.getCustasSemDocumento()
          this.modalFollowUp.active = false
        })
      }
    },
    cancel () {
      this.frmCustas.data = {}
    },
    getFavorecido () {
      var documento = this.frmCustas.data.documento
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.frmCustas.data.favorecido = ''
        this.frmCustas.data.banco = ''
        this.frmCustas.data.agencia = ''
        this.frmCustas.data.favorecido_conta = ''
        this.frmCustas.data.tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${this.frmCustas.data.documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
        } else {
          this.warning('Favorecido não encontrado.')
          this.frmCustas.data.favorecido = ''
          this.frmCustas.data.banco = ''
          this.frmCustas.data.agencia = ''
          this.frmCustas.data.favorecido_conta = ''
          this.frmCustas.data.tipo_conta = ''
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.frmCustas.data.favorecido = this.favorecidos[idx].favorecido_nome
      this.frmCustas.data.banco = this.favorecidos[idx].favorecido_banco
      this.frmCustas.data.agencia = this.favorecidos[idx].favorecido_agencia
      this.frmCustas.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.frmCustas.data.tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    },
    excluir () {
      axios.patch(`api/v1/batimento/custas/excluir`, this.getIds(this.postergados)).then((res) => {
        this.success(res.data)
        this.getPostergados()
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  },
  computed: {
    total_custas () {
      var tot = 0.0
      for (var i in this.custas) {
        if (this.custas[i].gerar === 1) {
          tot += Number(this.custas[i].valor)
        }
      }
      return tot
    }
  }
}
</script>

<style lang="scss">

a.info {
font-family:verdana,arial,helvetica,sans-serif;
position:relative;
border-bottom:1px dashed #ccc;
text-decoration:none;
color:#333333;
}
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
 display:block;
 position:absolute;
 z-index:1;
 bottom:1em;
 width:500px !important;
 border:1px solid #F2AF1D;
 background-color:#FDF1D5;
 color:#000;
 text-align:justify;
 font-size:12px;
 font-weight:normal;
 padding:3px;
 line-height:15px;
}
.modal-card-body {
  min-height: 300px !important;
}
.es-loading {
  z-index: 100;
}
</style>
