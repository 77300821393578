<template>
  <div>
      <es-title title="Faturamentos">
        <div slot="left">
          <es-datepicker v-if="tab == 0" v-model="data" type="button" @selected="get_resumo()"></es-datepicker>
          <a v-if="tab != 0" class="button is-warnning" @click="setTab(0)">Voltar</a>
        </div>
        <div slot="right">
          <div class="button is-primary" @click="CarregarCustas">Carregar</div>
        </div>
      </es-title>
      <div v-if="tab == 0">
        <es-table :config="tblFaturamentos" :rows="faturamentos" key="tblFaturamentos"  @rowclick="getDetalhamento">
          <div slot="buttons">
            <button class="button" @click="GerarBoletoUnitario">Enviar</button>
          </div>
        </es-table>
      </div>
      <div v-else>
        <h5 class="title is-5 es-primary"><span><icon name="chevron-right"  style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
        <es-table :config="tblCustas" :rows="custas" key="tblCustas">
          <div slot="buttons">
            <button class="button is-success" @click="GerarBoletoUnitario()" >Gerar Boleto</button>
          </div>
        </es-table>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'
export default {
  data () {
    return {
      tab: 0,
      subtitulo: '',
      apresentante: '',
      faturamentos: [],
      custas: [],
      tblFaturamentos: {
        fields: [
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'vencimento', label: 'Vencimento', type: 'date'},
          {name: 'quantidade', label: 'Qtd.'},
          {name: 'status', label: 'Status'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor'
      },
      tblCustas: {
        fields: [
          {name: 'id', label: 'ID', align: 'center'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'custas_tipo', label: 'Tipo', align: 'center'},
          {name: 'custas_prazo', label: 'Prazo', align: 'center'},
          {name: 'demais_despesas', label: 'Demais Despesas', align: 'center'},
          {name: 'cartorio_numero', label: 'Cartório', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'pagamento_id', label: 'Pagamento', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        group: {field: 'data_custa', agg: 'valor'},
        toggle: 'gerar',
        inactive: 'inativo',
        key: 'id',
        button: true
      }
    }
  },
  created () {
    this.getFaturamentos()
    this.data = utils.lastUtilDay()
  },
  methods: {
    getFaturamentos () {
      axios.get(`api/v1/batimento/faturamento`).then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].status === 'C') {
            res.data[i].status = 'CARREGADO'
          } else if (res.data[i].status === 'E') {
            res.data[i].status = 'ENVIADO'
          } else {
            res.data[i].status = 'PAGO'
          }
        }
        this.faturamentos = res.data
      }).catch((err) => {
      })
    },
    getDetalhamento (idx) {
      this.tab = 1
      this.apresentante = this.faturamentos[idx].apresentante_codigo
      this.subtitulo = this.faturamentos[idx].apresentante_nome
      axios.get(`api/v1/batimento/faturamento/custas/${this.apresentante}`).then((res) => {
        this.custas = res.data
        console.log(res.data[0].custas_tipo)
      }).catch((err) => {
      })
    },
    GerarBoletoUnitario () {
      axios.get(`api/v1/batimento/faturamento/boleto`).then((res) => {
        console.log(res.data)
      }).catch((err) => {
        console.log(err)
      })
    },
    CarregarCustas () {
      axios.patch(`api/v1/batimento/faturamento`).then((res) => {
        this.custas = res.data
      }).catch((err) => {
        console.log(err)
      })
    },
    setTab (tab) {
      this.tab = tab
    }
  }
}
</script>

<style>

</style>
