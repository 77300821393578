<template>
  <div :id=config.id :class="{'modal': true, 'is-active': config.active}">
    <div class="modal-background"></div>
    <div :class="{'modal-card': true, 'es-small': config.width == 'small', 'es-medium': config.width == 'medium', 'es-large': config.width == 'large', 'es-huge': config.width == 'huge' }">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ config.subtitle !== '' && title !== '' ? `${title} - ${config.subtitle}` : title + config.subtitle  }}</p>
        <button class="delete" aria-label="close" @click="toggle(config.close_action)"></button>
      </header>
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="modal-card-foot">
        <button v-if="typeof config.save === 'function'" class="button is-success" @click="save()" >Salvar</button>
        <button v-if="typeof config.trazer === 'function'" class="button is-success" @click="trazer()" >Trazer</button>
        <button v-if="typeof config.cancel === 'function'" class="button" @click="cancel()" >Cancel</button>
        <button v-if="typeof config.delete === 'function'" class="button is-danger" @click="excluir()" >Excluir </button>
        <button v-if="typeof config.habilitar === 'function'" class="button is-link" @click="habilitar()" >Habilitar Custa</button>
        <button v-for="button in buttons" class="button" @click="button.action" >{{ button.label }}</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      filter: '',
      buttons: []
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    config: Object
  },
  created () {
    this.config.subtitle = ''
    this.configure()
  },
  methods: {
    configure () {
      for (var i in this.config.buttons) {
        this.buttons.push(this.config.buttons[i])
      }
    },
    toggle (action) {
      this.config.active = false
    },
    save (idx = -1) {
      if (this.config.save !== undefined) {
        this.config.save()
      } else if (this.config.save !== undefined && idx !== -1) {
        this.config.save(idx)
      }
    },
    trazer (idx = -1) {
      if (this.config.trazer !== undefined) {
        this.config.trazer()
      } else if (this.config.trazer !== undefined && idx !== -1) {
        this.config.trazer(idx)
      }
    },
    excluir (idx = -1) {
      if (this.config.delete !== undefined) {
        this.config.delete()
      } else if (this.config.delete !== undefined && idx !== -1) {
        this.config.delete(idx)
      }
    },
    habilitar (idx = -1) {
      if (this.config.habilitar !== undefined) {
        this.config.habilitar()
      } else if (this.config.habilitar !== undefined && idx !== -1) {
        this.config.habilitar(idx)
      }
    },
    cancel () {
      this.config.active = false
      if (this.config.cancel !== undefined) {
        this.config.cancel()
      }
    }
  }
}
</script>

<style lang="sass">

.modal-card.es-small
  width: 20% !important

.modal-card.es-medium
  width: 40% !important

.modal-card.es-large
  width: 60% !important

.modal-card.es-huge
  width: 80% !important

</style>
