import moment from 'moment'

export default {
  grupoApresentante: {
    0: 'BRADESCO',
    1: 'RESGATE'
  },
  GrupoApresentantev: {
    0: 'BANRISUL',
    1: 'SICREDI',
    2: 'BANCOOB',
    3: 'ECOCRED',
    4: 'AILOS',
    5: 'CITIBANK',
    6: 'SANTANDER',
    7: 'BCR',
    8: 'UNICRED',
    9: 'CREDSIS',
    10: 'ECOCRED',
    11: 'BANCO DO BRASIL',
    12: 'UNICRED - NACIONAL'
  },
  pagamentoStatus: {
    0: 'GERAR',
    1: 'AGUARDANDO APROVACAO',
    2: 'ENVIADO',
    3: 'PENDENTE',
    4: 'AGUARDANDO RESPOSTA DO BANCO',
    5: 'PAGAMENTO CONFIRMADO',
    6: 'ERRO DO BANCO',
    7: 'ENCERRADO MANUALMENTE'
  },
  liquidacaoStatus: {
    'C': 'CARREGADO',
    'P': 'PENDENTE',
    'S': 'PROCESSADO',
    'R': 'RESOLVIDO'
  },
  ufs: {
    'AC': 'AC',
    'AL': 'AL',
    'AM': 'AM',
    'AP': 'AP',
    'BA': 'BA',
    'CE': 'CE',
    'DF': 'DF',
    'ES': 'ES',
    'GO': 'GO',
    'MA': 'MA',
    'MG': 'MG',
    'MS': 'MS',
    'MT': 'MT',
    'PA': 'PA',
    'PB': 'PB',
    'PE': 'PE',
    'PI': 'PI',
    'PR': 'PR',
    'RJ': 'RJ',
    'RN': 'RN',
    'RO': 'RO',
    'RR': 'RR',
    'RS': 'RS',
    'SC': 'SC',
    'SE': 'SE',
    'SP': 'SP',
    'TO': 'TO'
  },
  origens: {
    0: 'AVULSO',
    1: 'CUSTAS BRADESCO',
    2: 'CERTIDÃO',
    3: 'CANCELAMENTO',
    4: 'OBRIGAÇÃO DE FAZER',
    5: 'DEVOLUÇÃO',
    6: 'LIQUIDAÇÃO',
    7: 'GRAVAÇÃO ELETRÔNICA',
    8: 'RESGATE',
    9: 'CUSTAS SANTANDER',
    10: 'CUSTAS EMPRESAS',
    11: 'CUSTAS DE CANCELAMENTO'
  },
  tipo_arquivo: {
    'R': 'RETORNO',
    'C': 'CONFIRMAÇÃO'
  },
  gerarOrigens () {
    var obj = {}
    for (var i in this.origens) {
      obj[i] = []
    }
    return obj
  },
  gerarUfs () {
    var obj = {}
    for (var i in this.ufs) {
      obj[this.ufs[i]] = []
    }
    return obj
  },
  gerarGrupoApresentante () {
    var obj = {}
    var keys = Object.keys(this.grupoApresentante)
    for (var i in keys) {
      obj[keys[i]] = []
    }
    return obj
  },
  gerarGrupoApresentantev () {
    var obj = {}
    var keys = Object.keys(this.GrupoApresentantev)
    for (var i in keys) {
      obj[keys[i]] = []
    }
    return obj
  },
  today () {
    return moment().format('DD/MM/YYYY')
  },
  lastUtilDay () {
    var sub = 1
    switch (moment().isoWeekday()) {
      case 1:
        sub = 3
        break
      case 7:
        sub = 2
        break
    }
    return moment().subtract(sub, 'days').format('DD/MM/YYYY')
  },
  roundNumber (value) {
    return Math.round(value * 100) / 100
  },
  numberFormat (value) {
    var n, c, d, t, s, i, j
    n = value
    c = isNaN(c = Math.abs(c)) ? 2 : c
    d = d === undefined ? ',' : d
    t = t === undefined ? '.' : t
    s = n < 0 ? '-' : ''
    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + ''
    j = (j = i.length) > 3 ? j % 3 : 0
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
  },
  documento (value) {
    if (value.length <= 11) {
      return value.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2')
    } else {
      return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d)/, '$1-$2')
    }
  },
  documentoNumero (value) {
    return value.replace(/[^0-9]/g, '')
  },
  validarDocumento (value) {
    value = this.documentoNumero(value)
    if (value.length === 14) {
      return this.validarCnpj(value)
    } else if (value.length === 11) {
      return this.validaCPF(value)
    }
  },
  validaCPF (cpf) {
    var numeros, digitos, soma, i, resultado, digitosIguais
    digitosIguais = 1
    if (cpf.length < 11) return false
    for (i = 0; i < cpf.length - 1; i++) {
      if (parseInt(cpf.charAt(i)) !== parseInt(cpf.charAt(i + 1))) {
        digitosIguais = 0
        break
      }
    }
    if (!digitosIguais) {
      numeros = cpf.substring(0, 9)
      digitos = cpf.substring(9)
      soma = 0
      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (parseInt(resultado) !== parseInt(digitos.charAt(0))) return false
      numeros = cpf.substring(0, 10)
      soma = 0
      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (parseInt(resultado) !== parseInt(digitos.charAt(1))) return false
      return true
    } else {
      return false
    }
  },
  validarCnpj (cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    if (cnpj.length !== 14) return false
    if (/0{14}/.test(cnpj)) return false
    for (var i = 0, n = 0; i < 12;) {
      n += cnpj[i] * b[++i]
    }
    if (parseInt(cnpj[12]) !== (((n %= 11) < 2) ? 0 : 11 - n)) return false
    for (i = 0, n = 0; i <= 12;) {
      n += cnpj[i] * b[i++]
    }
    if (parseInt(cnpj[13]) !== (((n %= 11) < 2) ? 0 : 11 - n)) return false
    return true
  },
  getIDs (lista) {
    var ids = []
    for (var i in lista) {
      if (lista[i].gerar) {
        ids.push(lista[i].id)
      }
    }
    return ids
  }
}
