import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/pages/Dashboard'
import Custas from '@/pages/Custas'
import ValoresPendentes from '@/pages/ValoresPendentes'
import DespesasEletronica from '@/pages/DespesasEletronica'
import CadContaDespesasEletronica from '@/pages/CadContaDespesasEletronica'
import CustasExcluidas from '@/pages/CustasExcluidas'
import Conciliacao from '@/pages/Conciliacao'
import Liquidacao from '@/pages/Liquidacao'
import VisualizarLiquidacao from '@/pages/VisualizarLiquidacao'
import Pagfor from '@/pages/Pagfor'
import Aprovacao from '@/pages/Aprovacao'
import Arquivo from '@/pages/Arquivo'
import ArqRetornoSantander from '@/pages/ArqRetornoSantander'
import ArqComprovanteSantander from '@/pages/ArqComprovanteSantander'
import Login from '@/pages/Login'
import Gravacao from '@/pages/Gravacao'
import Gravacaoteste from '@/pages/Gravacaoteste'
import Pagamentos from '@/pages/Pagamentos'
import Pendentes from '@/pages/Pendentes'
import Movimento from '@/pages/Movimento'
import Solicitacao from '@/pages/Solicitacao'
import Faturamento from '@/pages/Faturamento'
import Configuracao from '@/pages/configuracao'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Dachboard',
      component: Dashboard
    },
    {
      path: '/custas',
      name: 'Custas',
      component: Custas
    },
    {
      path: '/custas/excluidas',
      name: 'Excluidas',
      component: CustasExcluidas
    },
    {
      path: '/despesaseletronica',
      name: 'DespesasEletronica',
      component: DespesasEletronica
    },
    {
      path: '/despesaseletronica/cadastrar/conta',
      name: 'CadContaDespesasEletronica',
      component: CadContaDespesasEletronica
    },
    {
      path: '/valoresPendentes',
      name: 'ValoresPendentes',
      component: ValoresPendentes
    },
    {
      path: '/conciliacao',
      name: 'Conciliacao',
      component: Conciliacao
    },
    {
      path: '/liquidacao',
      name: 'Liquidacao',
      component: Liquidacao
    },
    {
      path: '/VisualizarLiquidacao',
      name: 'VisualiarLiquidacao',
      component: VisualizarLiquidacao
    },
    {
      path: '/pagfor',
      name: 'Pagfor',
      component: Pagfor
    },
    {
      path: '/aprovacao',
      name: 'Aprovacao',
      component: Aprovacao
    },
    {
      path: '/arquivo',
      name: 'Arquivo',
      component: Arquivo
    },
    {
      path: '/arquivo/retorno/santander',
      name: 'ArqretSantander',
      component: ArqRetornoSantander
    },
    {
      path: '/arquivo/comprovante/santander',
      name: 'ArqComprovanteSantander',
      component: ArqComprovanteSantander
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/gravacao',
      name: 'Gravacao',
      component: Gravacao
    },
    {
      path: '/gravacao/gravacaoteste',
      name: 'Gravacaoteste',
      component: Gravacaoteste
    },
    {
      path: '/pagamentos',
      name: 'Pagamentos',
      component: Pagamentos
    },
    {
      path: '/pendentes',
      name: 'Pendentes',
      component: Pendentes
    },
    {
      path: '/movimento',
      name: 'Movimento',
      component: Movimento
    },
    {
      path: '/solicitacao',
      name: 'Solicitacao',
      component: Solicitacao
    },
    {
      path: '/faturamento',
      name: 'Faturamento',
      component: Faturamento
    },
    {
      path: '/Configuracao',
      name: 'configuracao',
      component: Configuracao
    }
  ]

})
