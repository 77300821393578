<template>
  <div :class="['es-loading', {'open': show}]" @click="toggle()">
    <figure class="es image">
        <img src="../assets/loading.gif" >
      </figure>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: ['show']
}
</script>

<style>
.es-loading {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
.es-loading.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
</style>
