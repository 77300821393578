import { render, staticRenderFns } from "./ArqRetornoSantander.vue?vue&type=template&id=42fe1ec0&"
import script from "./ArqRetornoSantander.vue?vue&type=script&lang=js&"
export * from "./ArqRetornoSantander.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports