<template>
    <div>
      <es-title title="Visualizar Liquidação">
        <div slot="left">
          <div v-if="detalhes == 0 || detalhes == 2" class="button is-warnning" @click="detalhes = 1, getResumo()">Voltar</div>
          <es-datepicker v-if="detalhes == 1" v-model="data" type="button" @selected="getResumo()"></es-datepicker>
        </div>
        <div slot="right">
          <div v-if="this.qtdPendentes > 0" class="button is-danger" @click="detalhes = 2, getPendentes(), countUfsPendentes()">
              {{'Pendentes ' + this.qtdPendentes}}
          </div>
        </div>
      </es-title>
      <div v-if="detalhes == 2">
        <div class="tabs is-boxed">
          <ul>
            <li v-for="(aba, idx) in abas" v-if="pendentes[aba].length > 0" :class="{'is-active': tab == aba}" @click="set_tab(aba)">
              <a>
                <span>{{ aba }}</span>
                <span :class="{'tag is-rounded': true, 'is-danger': tab == idx}">
                  {{ufsPendentes[aba]}}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <es-table :config="tblPendentes" :rows="pendentes[tab]">
          <div slot="buttons">
            <div class="button" @click="trazer()">Trazer</div>
          </div>
        </es-table>
      </div>
      <div v-if="detalhes == 1">
        <div class="tabs is-boxed">
          <ul>
            <li v-for="(aba, idx) in AbaGrupos" :class="{'is-active': tabResumo == idx}" @click="setTab(idx, aba.nome)">
              <a>
                <span>{{ aba.nome }}</span>
              </a>
            </li>
          </ul>
        </div>
        <es-table :config="tblResumo" :rows="resumo[tabResumo]" key="tblResumo">
          <div slot="buttons">
            <div class="button" @click="gerarMapa()">Fechar espelho</div>
            <div class="button is-danger is-outlined" @click="gerarComprovante(tabResumo)">
              <icon name="file-pdf"></icon><span> Relatório PDF (Sintético)</span>
            </div>
            <div v-if="exibirBtnSicred == '1'" class="button is-info" @click="gerarComprovanteUF(tabResumo)">
              <icon name="file-pdf"></icon><span> Relatório PDF (Sintético - UF) </span>
            </div>
          </div>
        </es-table>
      </div>
      <div v-else-if="detalhes == 0">
        <es-table :config="tblLiquidacoes" :rows="liquidacoes" key="tblLiquidacoes">
          <div slot="buttons">
            <div class="button" @click="postergar()">postergar</div>
            <div class="button" @click="adiarPagamento()">Adiar</div>
          </div>
        </es-table>
      </div>
      <div v-else>

      </div>
      <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      filtro: {},
      detalhes: 1,
      exibirBtnSicred: '0',
      tblPendentes: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_liquidacao', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante', align: 'center'},
          {name: 'cidade_nome', label: 'Município'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar',
        sum: 'valor',
        key: 'id',
        button: true
      },
      tblLiquidacoes: {
        fields: [
          {name: 'data_liquidacao', label: 'Data', type: 'date'},
          {name: 'status_nome', label: 'Status'},
          {name: 'apresentante_nome', label: 'Apresentante', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'center'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cidade_nome', label: 'Município', align: 'left'},
          {name: 'pagamento', label: 'Pagamento', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar',
        sum: 'valor',
        key: 'id',
        inactive: 'processado',
        button: true
      },
      tblResumo: {
        fields: [
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'count', label: 'Qtd', align: 'center'},
          {name: 'gerados', label: 'G', align: 'center', type: 'nozero'},
          {name: 'resto', label: 'Não Gerado', type: 'currency'},
          {name: 'gerados_total', label: 'Total Gerado', type: 'currency'},
          {name: 'total', label: 'Total', type: 'currency'}
        ],
        key: 'id',
        sum: 'total',
        group: {},
        rowclick: this.setFiltro,
        inactive: 'gerar',
        button: false
      },
      liquidacoes: [],
      resumo: utils.gerarGrupoApresentantev(),
      abas: utils.ufs,
      pendentes: utils.gerarUfs(),
      AbaGrupos: [],
      tab: -1,
      tabResumo: 0,
      qtdPendentes: 0,
      ufsPendentes: {},
      status: utils.liquidacaoStatus
    }
  },
  created () {
    this.data = utils.today()
    this.getResumo()
  },
  methods: {
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/liquidacao/visualizar/${this.data}`).then((res) => {
        this.getResumo()
        this.success('Liquidações carregadas com sucesso.')
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    gerarComprovante (tab) {
      var monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      var date = new Date()
      var day = date.getDate()
      var monthIndex = date.getMonth()
      var year = date.getFullYear()
      axios.patch(`api/v1/batimento/liquidacao/visualizar/pdf/${this.data}/${this.AbaGrupos[tab].nome}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Liquidacao_' + this.AbaGrupos[tab].nome + day + '_' + monthNames[monthIndex] + '_' + year + '.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((err) => {
        console.log('deu errado')
        this.error(err.response.data)
      })
    },
    gerarComprovanteUF (tab) {
      var monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      var date = new Date()
      var day = date.getDate()
      var monthIndex = date.getMonth()
      var year = date.getFullYear()
      axios.get(`api/v1/batimento/liquidacao/visualizar/pdf/${this.data}/${this.AbaGrupos[tab].nome}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Liquidacao_' + this.AbaGrupos[tab].nome + day + '_' + monthNames[monthIndex] + '_' + year + '.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((err) => {
        console.log('deu errado')
        this.error(err.response.data)
      })
    },
    montaAbaGrupos (dados) {
      var primeirocod = dados[0].aprecodigo
      this.AbaGrupos.push({id: 0, nome: dados[0].apresentante})
      var contador = 0
      for (var i in dados) {
        if (primeirocod !== dados[i].aprecodigo) {
          primeirocod = dados[i].aprecodigo
          contador = contador + 1
          this.AbaGrupos.push({id: contador, nome: dados[i].apresentante})
          dados[i].grupo = contador
        } else {
          dados[i].grupo = contador
        }
      }
      this.resumo = this.monta_dados(dados, this.tblResumo)
      this.montarAbasGrupos(this.resumo)
    },
    getResumo () {
      axios.get(`api/v1/batimento/liquidacao/visualizar/${this.data}`).then((res) => {
        this.AbaGrupos = []
        this.liquidacao = []
        this.resumo = []
        if (res.data.length > 0) {
          this.montaAbaGrupos(res.data)
          this.exibirBtnSicred = 0
          this.setTab(0, 'Banco')
        } else {
          this.error('Não existe dados para essa data')
        }
      })
      this.detalhes = 1
    },
    monta_dados (dados, conf) {
      var lista = []
      if (dados.length === 0) {
        return lista
      }
      var groupTotal = 0.0
      var groupValue = dados[0][conf.group.field]
      for (var i in dados) {
        if (groupValue !== dados[i][conf.group.field]) {
          var row = {}
          for (var j in conf.fields) {
            if (conf.fields[j].name === conf.group.field) {
              row[conf.fields[j].name] = groupValue
            } else if (conf.fields[j].name === conf.group.agg) {
              row[conf.fields[j].name] = Number(groupTotal)
            } else {
              row[conf.fields[j].name] = ''
            }
          }
          groupTotal = 0.0
          groupValue = dados[i][conf.group.field]
          row.group = true
          lista.push(row)
        }
        groupTotal += Number(dados[i][conf.group.agg])
        dados[i].group = false
        lista.push(dados[i])
      }
      row = {}
      for (j in conf.fields) {
        if (conf.fields[j].name === conf.group.field) {
          row[conf.fields[j].name] = groupValue
        } else if (conf.fields[j].name === conf.group.agg) {
          row[conf.fields[j].name] = groupTotal
        } else {
          row[conf.fields[j].name] = ''
        }
      }
      row.group = true
      lista.push(row)
      return lista
    },
    setFiltro (idx) {
      this.filtro = {data: this.data, grupo: this.resumo[this.tabResumo][idx].grupo, aprecodigo: this.resumo[this.tabResumo][idx].aprecodigo, uf: this.resumo[this.tabResumo][idx].uf}
      this.getLiquidacao()
    },
    getLiquidacao () {
      this.liquidacoes = []
      axios.post(`api/v1/batimento/liquidacao/visualizar/sintetico`, this.filtro).then((res) => {
        for (var i in res.data) {
          res.data[i]['status_nome'] = this.status[res.data[i].status]
        }
        this.liquidacoes = res.data
      })
      this.detalhes = 0
    },
    getPendentes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes`).then((res) => {
        this.montar_abas(res.data)
      })
    },
    montar_abas (data) {
      this.pendentes = utils.gerarUfs()
      if (data.length > 0) {
        this.tab = data[0].uf
      }
      for (var i in data) {
        this.pendentes[data[i].uf].push(data[i])
      }
    },
    montarAbasGrupos (data) {
      this.resumo = utils.gerarGrupoApresentantev()
      if (data.length > 0) {
        this.tab = data[0].grupo
      } else {
        return
      }
      for (var i in data) {
        if (data[i].group === true) {
          this.resumo[data[i - 1].grupo].push(data[i])
        } else {
          if (data[i].grupo !== undefined && data[i].grupo !== '') {
            this.resumo[data[i].grupo].push(data[i])
          }
        }
      }
    },
    set_tab (aba) {
      this.tab = aba
    },
    setTab (idx, nome) {
      if (nome === 'SICREDI') {
        this.exibirBtnSicred = 1
      } else {
        this.exibirBtnSicred = 0
      }
      this.tabResumo = idx
    },
    postergar () {
      var pendente = {
        ids: []
      }
      for (var i in this.liquidacoes) {
        if (this.liquidacoes[i].gerar === true && this.liquidacoes[i].processado === false) {
          pendente.ids.push(this.liquidacoes[i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/postergar`, pendente).then((res) => {
        this.getLiquidacao()
        this.countPententes()
      })
    },
    trazer () {
      var pendente = {
        ids: []
      }
      for (var i in this.pendentes[this.tab]) {
        if (this.pendentes[this.tab][i].gerar === true) {
          pendente.ids.push(this.pendentes[this.tab][i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/trazer`, pendente).then((res) => {
        this.getResumo()
        this.countPententes()
      })
    },
    countPententes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes/count`).then((res) => {
        this.qtdPendentes = res.data
      })
    },
    countUfsPendentes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes/uf/count`).then((res) => {
        this.ufsPendentes = utils.gerarUfs()
        for (var i in res.data) {
          this.ufsPendentes[res.data[i].uf] = res.data[i].count
        }
      })
    },
    gerarMapa () {
      this.showLoading()
      axios.patch(`api/v1/batimento/liquidacao/visualizar/gerarmapa/${this.data}/${this.tabResumo}`).then((res) => {
        this.getResumo()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiarMapa () {
      this.showLoading()
      axios.put(`api/v1/batimento/liquidacao/visualizar/adiarmapa/${this.data}/${this.tabResumo}`).then((res) => {
        this.getResumo()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiarPagamento () {
      this.showLoading()
      var ids = []
      for (var i in this.liquidacoes) {
        if (this.liquidacoes[i].gerar === true) {
          ids.push(this.liquidacoes[i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/visualizar/adiarpagamento`, ids).then((res) => {
        this.getLiquidacao()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  }
}
</script>

<style lang="scss">
.table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #a7a4a4 !important;
}
</style>
