<template>
  <div>
    <div class="notification is-danger" id="info_danger">
        <button class="delete" @click="fechar_info()"></button>
          Informe a data para trazer as custas.<br>
          <strong>Lembrando:</strong> Informe a data da custa e não a data da exclusão.
    </div>
    <es-title title="Emolumentos Excluidos">
        <div slot="left">
          <es-datepicker v-if="tab == 0" v-model="data" type="button" @selected="get_resumo()"></es-datepicker>
          <a v-if="tab != 0" class="button is-warnning" @click="set_tab(0)">Voltar</a>
        </div>
        <div slot="right">
         <!-- <div class="button is-primary" @click="getPostergados()">Postergadas</div>
           <div class="button is-success" @click="load()"><icon name="download"></icon></div> -->
        </div>
    </es-title>
    <div v-if="tab == 0">
      <table class="table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th class="has-text-centered" rowspan="2" width="35%">Apresentante</th>
    		    <th class="has-text-centered" rowspan="2" width="5%">UF</th>
            <th class="has-text-centered" colspan="4" width="25%">Retorno</th>
            <th class="has-text-centered" colspan="4" width="25%">Confirmação</th>
            <th class="has-text-centered" rowspan="2" width="15%">Total</th>
          </tr>
        	<tr>
        		<th class="has-text-centered">Qtd.</th>
            <th class="has-text-centered">P</th>
            <th class="has-text-centered">G</th>
        		<th class="has-text-centered">Total</th>
            <th class="has-text-centered">Qtd.</th>
            <th class="has-text-centered">P</th>
            <th class="has-text-centered">G</th>
        		<th class="has-text-centered">Total</th>
        	</tr>
        </thead>
        <tbody>
          <template v-for="(item, idx, key) in resumo">
            <tr v-if="item.grupo" class="group" :key="key">
              <td colspan="2">{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
              <td colspan="4" class="has-text-right">{{ item.retorno_total | currency }}</td>
              <td colspan="4" class="has-text-right">{{ item.confirmacao_total | currency }}</td>
              <td class="has-text-right">{{ item.confirmacao_total + item.retorno_total | currency }}</td>
            </tr>
            <tr v-else :key="key">
              <td>{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
              <td class="has-text-centered">{{ item.uf }}</td>
              <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'R')">{{ item.retorno_qtd | nozero }}</td>
              <td class="has-text-centered">{{ item.retorno_pendente | nozero }}</td>
              <td class="has-text-centered">{{ item.retorno_gerados | nozero }}</td>
              <td class="has-text-right">{{ item.retorno_total | currency }}</td>
              <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'C')">{{ item.confirmacao_qtd | nozero }}</td>
              <td class="has-text-centered">{{ item.confirmacao_pendente | nozero }}</td>
              <td class="has-text-centered">{{ item.confirmacao_gerados | nozero }}</td>
              <td class="has-text-right">{{ item.confirmacao_total | currency }}</td>
              <td class="has-text-right">{{ item.total | currency }}</td>
            </tr>
        </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">

            </td>
            <td colspan="4" class="has-text-right">{{ total_retorno | currency }}</td>
            <td colspan="4" class="has-text-right">{{ total_confirmacao | currency }}</td>
            <td class="has-text-right">{{ (total_retorno + total_confirmacao) | currency}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div v-else-if="tab == 1">
      <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
     <es-table :config="tblCustas" :rows="custas" @rowclick="nova_conta">
        <div slot="buttons">
          <!-- <div class="button" @click="gerar_pagamentos()">Gerar</div> -->
          <!-- <div v-if="data === data_atual" class="button" @click="adiar()">Adiar</div> -->
          <div  class="button" @click="trazer()"> Trazer </div>
          <!-- <div class="button" @click="restaurar()">Restaurar</div> -->
          <!-- <div class="button" @click="postergar()">Postergar</div> -->
        </div>
      </es-table>

    </div>
    <div v-else>
      <es-table :config="tblPostegadas" :rows="postergados">
        <div slot="buttons">
          <div class="button" @click="trazerPostergados()">Trazer</div>
          <div class="button" @click="habilitar()">Habilitar Custa</div>
        </div>
      </es-table>
    </div>
    <es-modal title="Add Conta" :config="modal">
      <es-form v-if="tabFavorecido == 0" :config="frmCustas"></es-form>
      <es-table v-else-if="tabFavorecido==1" :config="tblFavorecido" :rows="favorecidos" @rowclick="setFavorecido"></es-table>
    </es-modal>
    <es-modal title="Cadastrar Follow Up" :config="modalFollowUp" >
      <es-form :config="frmFollowUp"></es-form>
      <div style="margin-top: 10px;">
        <es-table :config="tblFollowUp" :rows="followup">
        </es-table>
      </div>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>

</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      data_atual: '',
      resumo: [],
      custas: [],
      followup: [],
      favorecidos: [],
      postergados: [],
      lista: {},
      subtitulo: '',
      selected: {},
      filter: {
        apresentante: '',
        uf: '',
        tipo_arquivo: ''
      },
      tab: 0,
      tabFavorecido: 0,
      token: '',
      tblFavorecido: {
        fields: [
          {name: 'conta_id', label: 'ID', align: 'right'},
          {name: 'favorecido_nome', label: 'Nome', type: 'toupper'},
          {name: 'favorecido_documento', label: 'Documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      tblCustas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'municipio', label: 'Municipio'},
          {name: 'data_custa', label: 'Data', type: 'date'},
          {name: 'status', label: 'S', align: 'center'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_numero', label: 'Cartório', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'pagamento_id', label: 'Pagamento', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        group: {field: 'data_custa', agg: 'valor'},
        toggle: 'gerar',
        inactive: 'inativo',
        key: 'id',
        actions: [
          {action: this.modalAddFollowUp, icon: 'check-square-o'}
        ],
        button: true
      },
      tblFollowUp: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'ocorrencia', label: 'Ocorrencia', type: 'tooltip'},
          {name: 'dataGravacao', label: 'Data', type: 'date'},
          {name: 'usario_nome', label: 'Usuário', align: 'center'}
        ],
        key: 'id',
        button: true
      },
      tblPostegadas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_custa', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'municipio', label: 'Municipio'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_numero', label: 'Cartório', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', align: 'center'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar'
      },
      frmCustas: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'documento', label: 'Documento', width: 4, type: 'document', onExit: this.getFavorecido},
              {name: 'favorecido', label: 'Favorecido', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'banco', label: 'Banco', width: 2, type: 'text'},
              {name: 'agencia', label: 'Agência', width: 3, type: 'text'},
              {name: 'favorecido_conta', label: 'Conta', width: 3, type: 'text'},
              {name: 'tipo_conta', label: 'Tipo Conta', type: 'select', values: [{value: 'C', text: 'Corrente'}, {value: 'P', text: 'Poupança'}]}
            ]
          }
        ]
      },
      frmFollowUp: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário', width: 6, type: 'label'}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Ocorrência', type: 'textarea'}
            ]
          }
        ]
      },
      modal: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        save: this.salvar_conta
      },
      modalFollowUp: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        habilitar: this.habilitar
      },
      total_retorno: 0.0,
      total_confirmacao: 0.0
    }
  },
  created () {
    this.data = utils.lastUtilDay()
    this.data_atual = utils.lastUtilDay()
    this.get_resumo()
  },
  methods: {
    get_resumo () {
      axios.get(`api/v1/batimento/custas/excluidas/${this.data}`).then((res) => {
        this.resumo = []
        this.monta_resumo(res.data)
      }).catch((err) => {
      })
    },
    modalAddFollowUp (idx) {
      this.get_followup(this.custas[idx].id)
      this.frmFollowUp.data.idCusta = this.custas[idx].id
      this.frmFollowUp.data.tela = 'W'
      this.toggle_modalFollowUp()
    },
    monta_resumo (res) {
      this.total_retorno = 0.0
      this.total_confirmacao = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            totalRetorno += Number(obj['retorno_total'])
            this.total_retorno += Number(obj['retorno_total'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao += Number(obj['confirmacao_total'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo = list
    },
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/${this.data}`).then((res) => {
        this.get_resumo()
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    fechar_info () {
      var element = document.getElementById('info_danger')
      element.classList.add('remover_info')
    },
    getPostergados () {
      this.showLoading()
      axios.get(`api/v1/batimento/custas/postergados`).then((res) => {
        this.postergados = res.data
        this.tab = 2
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    get_custas_apresentante_uf (idx, tipo) {
      this.selected = this.resumo[idx]
      this.selected.tipo = tipo
      this.filter.apresentante = this.resumo[idx].apresentante_codigo
      this.filter.apresentante_nome = this.resumo[idx].apresentante
      this.filter.uf = this.resumo[idx].uf
      this.filter.tipo_arquivo = tipo
      this.get_custas()
    },
    get_custas () {
      axios.get(`api/v1/batimento/custas/excluidas/${this.data}/${this.filter.apresentante}/${this.filter.uf}/${this.filter.tipo_arquivo}`).then((res) => {
        this.subtitulo = `${this.selected.apresentante} - ${this.selected.uf} - ${utils.tipo_arquivo[this.selected.tipo]}`
        this.tab = 1
        this.custas = res.data
      })
    },
    get_followup (idx) {
      axios.get(`api/v1/batimento/custas/followup/${idx}`).then((res) => {
        this.followup = res.data
      })
    },
    gerar_pagamentos () {
      this.showLoading()
      axios.post('api/v1/batimento/custas/pagamentos', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success('Pagamentos gerados com sucesso!')
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    gerarPagamentosDia () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/pagamentos/${this.data}`).then((res) => {
        this.get_resumo()
        this.success(`Arquivo ${res.data.arquivo} gerado com sucessso!`)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiar () {
      this.showLoading()
      axios.patch('api/v1/batimento/custas/adiar', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    postergar () {
      this.showLoading()
      axios.post('api/v1/batimento/custas/adiar', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazer () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/trazer/${utils.lastUtilDay()}`, this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazerPostergados () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/trazer/${utils.lastUtilDay()}`, this.getIds(this.postergados)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    restaurar () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/restaurar`, this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getIds (lista) {
      var ids = []
      for (var i in lista) {
        if (lista[i].gerar) {
          ids.push(lista[i].id)
        }
      }
      return ids
    },
    set_tab (n) {
      if (n === 0) {
        this.get_resumo()
      }
      this.tab = n
      this.custa = []
    },
    toggle_gerar (idx) {
      this.custas[idx].gerar = !this.custas[idx].gerar
    },
    toggle_modal () {
      this.modal.active = !this.modal.active
    },
    toggle_modalFollowUp () {
      this.modalFollowUp.active = !this.modalFollowUp.active
    },
    nova_conta (idx) {
      if (this.custas[idx].favorecido === null) {
        this.toggle_modal()
        this.frmCustas.data.id = this.custas[idx].id
      }
    },
    novoFollowUp (idx) {
      axios.get(`api/v1/batimento/custas/followup/${this.custas[idx].id}`).then((res) => {
        console.log(res.data)
        this.followup = res.data
      })
      this.toggle_modalFollowUp()
      this.frmFollowUp.data.idCusta = this.custas[idx].id
    },
    salvar_conta () {
      axios.post(`api/v1/batimento/custas/conta`, this.frmCustas.data).then((the) => {
        this.toggle_modal()
        this.frmCustas.data = {}
        this.get_custas()
        this.modal.active = false
      })
    },
    salvar_FollowUp () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha a Ocorrência')
      } else {
        axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.frmFollowUp.data = {}
          this.get_custas()
          this.modalFollowUp.active = false
        })
      }
    },
    habilitar () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha o Ocorrência')
      } else {
        axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.get_custas()
          this.modalFollowUp.active = false
        })

        axios.post(`api/v1/batimento/custas/update-habilitar`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.frmFollowUp.data = {}
          this.get_custas()
          this.modalFollowUp.active = false
        })
      }
    },
    cancel () {
      this.frmCustas.data = {}
    },
    getFavorecido () {
      var documento = this.frmCustas.data.documento
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.frmCustas.data.favorecido = ''
        this.frmCustas.data.banco = ''
        this.frmCustas.data.agencia = ''
        this.frmCustas.data.favorecido_conta = ''
        this.frmCustas.data.tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${this.frmCustas.data.documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
        } else {
          this.warning('Favorecido não encontrado.')
          this.frmCustas.data.favorecido = ''
          this.frmCustas.data.banco = ''
          this.frmCustas.data.agencia = ''
          this.frmCustas.data.favorecido_conta = ''
          this.frmCustas.data.tipo_conta = ''
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.frmCustas.data.favorecido = this.favorecidos[idx].favorecido_nome
      this.frmCustas.data.banco = this.favorecidos[idx].favorecido_banco
      this.frmCustas.data.agencia = this.favorecidos[idx].favorecido_agencia
      this.frmCustas.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.frmCustas.data.tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    },
    excluir () {
      axios.patch(`api/v1/batimento/custas/excluir`, this.getIds(this.postergados)).then((res) => {
        this.success(res.data)
        this.getPostergados()
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  },
  computed: {
    total_custas () {
      var tot = 0.0
      for (var i in this.custas) {
        if (this.custas[i].gerar === 1) {
          tot += Number(this.custas[i].valor)
        }
      }
      return tot
    }
  }
}
</script>

<style lang="scss">
.remover_info {
  display: none !important;
}
a.info {
font-family:verdana,arial,helvetica,sans-serif;
position:relative;
border-bottom:1px dashed #ccc;
text-decoration:none;
color:#333333;
}
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
 display:block;
 position:absolute;
 z-index:1;
 bottom:1em;
 width:500px !important;
 border:1px solid #F2AF1D;
 background-color:#FDF1D5;
 color:#000;
 text-align:justify;
 font-size:12px;
 font-weight:normal;
 padding:3px;
 line-height:15px;
}
</style>
