<template>
  <div class="columns is-multiline es-login-container" style="height: 110%;bottom: 0;">

    <div class="column is-2 is-text-centered es-centralizado">
      <figure class="es image">
        <img src="../assets/logo.png" width="100%">
      </figure>
      <div class="field">
        <div class="field">
        <div class="control">
          <input class="input" type="text" placeholder="Login" v-model="login">
        </div>
      </div>
        <div class="control">
          <input class="input" type="password" placeholder="Senha" v-model="senha" @click="autenticar">
        </div>
      </div>
      <div class="es button is-link is-fullwidth" @click="autenticar">Logar</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      login: '',
      senha: ''
    }
  },
  created () {
    this.is_authenticated()
  },
  methods: {
    autenticar () {
      axios.post('login/', {login: this.login, senha: this.senha}).then((res) => {
        this.$session.start()
        this.$session.set('nome_usuario', res.data.nome)
        this.$session.set('authenticated', true)
        this.$session.set('token', res.data.token)
        this.$session.set('acesso', res.data.acesso)
        axios.defaults.headers.common.Authorization = 'Token ' + this.$session.get('token')
        this.$router.push('/')
      })
      .catch((err) => {
        this.senha = ''
      })
    }
  }
}
</script>

<style lang="sass">
.es-login-container
  display: flex
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  align-content: center
  justify-content: center
  background-color: #f5f5f5
.es.image
  margin-bottom: 2em
.es.button
  margin-top: 1em
.es-centralizado
  margin: auto
</style>
