<template>
  <div>
    <es-title title="Cadastro de Contas - Despesas Eletronica"></es-title>
    <es-filter :config="fPesquisa"></es-filter>
    <br>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="(aba, idx) in abas ">
          <a>
            <span>{{ aba }}</span>
          </a>
        </li>
      </ul>
    </div>
    <article v-if="contaQtd == 0" class="message is-danger">
      <div class="message-body">Favorecido - Não possui conta cadastrada</div>
    </article>
    <div v-else>
      <es-table :config="tblContas" :rows="contas" @rowclick="ativar"></es-table>
    </div>
    <es-loading :show="loading"></es-loading>
    <es-modal title="Ativar Conta" :config="modal">
      <h1 class="is-size-5"><b>Deseja ativar essa conta para o favorecido - {{ favorecido_ativa }} ? </b></h1>
      <h1 class="is-size-5"><b>Esta conta encontra-se {{ status_ativa }} </b></h1>
      <hr>
      <div class="columns">
        <div class="column">
          <nav class="panel">
            <a class="panel-block">
              <p><b>Banco:</b> {{ banco_ativar }}</p>
            </a>
            <a class="panel-block">
              <p><b>Agencia:</b> {{ agencia_ativa }}</p>
            </a>
          </nav>
        </div>
        <div class="column">
          <a class="panel-block">
            <p><b>Conta:</b> {{ this.numero_ativa }}</p>
          </a>
          <a class="panel-block">
            <p><b>Tipo:</b> {{ this.tipo_ativa }}</p>
          </a>
        </div>
      </div>
    </es-modal>
  </div>

</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      contas: [],
      id_ativar: '',
      banco_ativar: '',
      agencia_ativa: '',
      numero_ativa: '',
      tipo_ativa: '',
      favorecido_ativa: '',
      status_ativa: '',
      filter: {
        apresentante: '',
        uf: '',
        tipo_arquivo: ''
      },
      abas: {
        1: 'Contas'
      },
      contaQtd: 0,
      token: '',
      tblContas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'dataCad', label: 'Cadastrado em', type: 'date'},
          {name: 'dataDes', label: 'Desativado em', type: 'date'},
          {name: 'favorecido__nome', label: 'Favorecido', type: 'toupper'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agenciadg', label: 'Agência', align: 'right'},
          {name: 'numerodg', label: 'Conta', align: 'right'},
          {name: 'tipo', label: 'Tipo', align: 'center'},
          {name: 'status', label: 'Status', align: 'center'},
          {name: 'usuario__username', label: 'Usuario', align: 'center'}
        ],
        toggle: 'gerar',
        key: 'id',
        inactive: 'inativo',
        button: true
      },
      fPesquisa: {
        fields: [
          {
            name: 'favorecidoid',
            label: 'Favorecido',
            width: 2,
            type: 'select1',
            values: []
          },
          {name: 'banco', label: 'Banco', placeholder: '000', type: 'banco', width: 1, value: ''},
          {name: 'agencia', label: 'Agência', placeholder: '00000', type: 'text', width: 2, value: ''},
          {name: 'digito_ag', label: 'Ag. Dígito', placeholder: '0', type: 'digito', width: 1},
          {name: 'conta', label: 'Conta', placeholder: '00000', type: 'text'},
          {name: 'digito_ct', label: 'Ct. Digito', placeholder: '0', type: 'digito', width: 1},
          {name: 'tipo_conta', label: 'Tipo Conta', type: 'select2', values: [{value: 'C', text: 'Corrente'}, {value: 'P', text: 'Poupança'}]}
        ],
        button: {label: 'Cadastrar', side: 'right', action: this.cadastrarConta},
        select: {label: 'Cadastrar', side: 'right', action: this.trazercontas}

      },
      modal: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        save: this.ativar_conta
      }
    }
  },
  created () {
    this.get_apresentante()
  },
  methods: {
    cancel () {
      this.modal.active = false
    },
    trazercontas (arg) {
      if (arg.favorecidoid === undefined) {
        this.error('Informe o favorecido')
      } else {
        axios.patch(`api/v1/batimento/despesaseletronica/contas/${arg.favorecidoid}`).then((res) => {
          if (res.data.length !== 0) {
            this.contaQtd = 1
            this.contas = res.data
          } else {
            this.contaQtd = 0
          }
        })
      }
    },
    get_apresentante () {
      axios.get(`api/v1/batimento/despesaseletronica/favorecido/filtro/`).then((res) => {
        for (var i in res.data) {
          this.fPesquisa.fields[0].values.push({value: res.data[i].id, text: res.data[i].nome})
        }
        this.fPesquisa.fields[0].values['0'].selected = 'true'
      })
    },
    cadastrarConta (arg) {
      if ((arg.favorecidoid === undefined) || (arg.favorecidoid === '')) {
        this.error('Informe o favorecido')
        document.getElementById('favorecidoid').focus()
      } else if ((arg.banco === undefined) || (arg.banco === '')) {
        this.error('Informe o banco')
        document.getElementById('banco').focus()
      } else if (arg.banco.length < 3) {
        this.error('Campo banco deve conter 3 DÍGITOS')
        document.getElementById('banco').focus()
      } else if (arg.agencia === undefined) {
        this.error('Informe a agência')
        document.getElementById('agencia').focus()
      } else if (arg.conta === undefined) {
        this.error('Informe a conta')
        document.getElementById('conta').focus()
      } else if (arg.digito_ct === undefined) {
        this.error('Informe o dígito da conta')
        document.getElementById('digito_ct').focus()
      } else if (arg.tipo_conta === undefined) {
        this.error('Informe o tipo da conta')
        document.getElementById('tipo_conta').focus()
      } else {
        axios.post(`api/v1/batimento/despesaseletronica/conta/cadastrar`, arg).then((res) => {
          this.trazercontas(arg)
          arg.banco = ''
          arg.agencia = ''
          arg.conta = ''
          arg.digito_ag = ''
          arg.digito_ct = ''
          document.getElementById('banco').value = ''
          document.getElementById('agencia').value = ''
          document.getElementById('digito_ag').value = ''
          document.getElementById('conta').value = ''
          document.getElementById('digito_ct').value = ''
          this.modal.active = false
        })
      }
    },
    ativar_conta (arg) {
      axios.put(`api/v1/batimento/despesaseletronica/conta/habilitar/${this.id_ativar}`).then((res) => {
        this.contas = []
        if (res.data.length !== 0) {
          this.contaQtd = 1
          this.contas = res.data
        } else {
          this.contaQtd = 0
        }
        this.modal.active = false
      })
    },
    ativar (idx) {
      this.toggle_modal(idx)
    },
    toggle_gerar (idx) {
      this.contas[idx].gerar = !this.contas[idx].gerar
    },
    toggle_modal (idx) {
      this.id_ativar = this.contas[idx].id
      this.banco_ativar = this.contas[idx].banco
      this.agencia_ativa = this.contas[idx].agenciadg
      this.numero_ativa = this.contas[idx].numerodg
      this.tipo_ativa = this.contas[idx].tipo
      this.favorecido_ativa = this.contas[idx].favorecido__nome
      this.status_ativa = this.contas[idx].status
      this.modal.active = !this.modal.active
    }
  }
}
</script>