<template>
    <div>
      <es-title title="Retorno Santander">
        <div slot="left">
          <div v-if="detalhes == 0 || detalhes == 2" class="button is-warnning" @click="detalhes = 1, getResumo()">Voltar</div>
          <es-datepicker v-if="detalhes == 1" v-model="data" type="button" @selected="getResumo()"></es-datepicker>
        </div>
        <div slot="left" class="select" style="margin-left: 5px;">
          <select v-model="optionSelected" @change="montarGrid(optionSelected)">
            <option v-for="option in options" v-bind:value="option.value"> {{ option.text }} </option>
          </select>
        </div>
        <div slot="right">
          <div class="button is-success" @click="toggleModalImportacao()"><icon name="download"></icon> Carregar </div>
        </div>
      </es-title>
      <div v-if="detalhes == 2">
        <div class="tabs is-boxed">
          <ul>
            <li v-for="(aba, idx) in abas" v-if="pendentes[aba].length > 0" :class="{'is-active': tab == aba}" @click="set_tab(aba)">
              <a>
                <span>{{ aba }}</span>
                <span :class="{'tag is-rounded': true, 'is-danger': tab == idx}">
                  {{ufsPendentes[aba]}}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="detalhes == 1">
        <div class="tabs is-boxed">
          <ul>
            <li v-for="(aba, idx) in AbaGrupos"  @click="setTab(idx, aba.uf, optionSelected)">
              <a>
                <span>{{ aba.uf }}</span>
              </a>
            </li>
          </ul>
        </div>
        <es-table :config="tblResumo" :rows="resumo" key="tblResumo">
          <div slot="buttons">
            <div class="button" @click="gerarMapa()">Finalizar</div>
            <div class="button is-danger" id="totalGerado"> Total: {{ TotalSomaUF | currency }} </div>
            <div class="button is-success" id="totalLiquidacao"> Total Liquidação: {{ totalLiquidacao | currency }} </div>
            <div class="button is-info" id="totalSobra"> Total Sobra: {{ totalSobra | currency }} </div>
          </div>
        </es-table>
      </div>
      <es-modal title="Cadastrar Follow Up" :config="modalFollowUp" >
        <es-form :config="frmFollowUp"></es-form>
        <div style="margin-top: 10px;">
          <es-table :config="tblFollowUp" :rows="followup">
          </es-table>
        </div>
      </es-modal>
      <es-modal title="Planilha - Liquidações" :config="modalImportacao">
        <div class="field">
          <div class="file is-centered is-boxed is-success has-name">
            <label class="file-label">
              <input class="file-input" type="file" @change="importacao" accept="application/x-zip-compressed">
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Selecionar arquivo txt
                </span>
              </span>
              <span class="file-name">
                {{ arquivoNome }}
              </span>
            </label>
          </div>
        </div>
      </es-modal>
      <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      filtro: {},
      followup: [],
      detalhes: 1,
      tblPendentes: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_liquidacao', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante', align: 'center'},
          {name: 'cidade_nome', label: 'Município'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar',
        sum: 'valor',
        key: 'id',
        button: true
      },
      tblResumo: {
        fields: [
          {name: 'id', label: 'ID', type: 'toupper'},
          {name: 'data_credito', label: 'Data', type: 'date'},
          {name: 'lancamento_Desc', label: 'Lançamento', type: 'toupper'},
          {name: 'Comarca_Municipio', label: 'Município', type: 'toupper'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'Documento', label: 'Documento', align: 'center'},
          {name: 'valor_credito', label: 'Valor do Crédito', type: 'currency'},
          {name: 'cod_cartorio', label: 'Cartório', type: 'toupper'},
          {name: 'ibge', label: 'IBGE', align: 'center', type: 'toupper'},
          {name: 'grupo', label: 'PG', type: 'toupper'}
        ],
        key: 'id',
        sum: 'valor',
        rowclick: this.setFiltro,
        button: true,
        devolver: '',
        actions: [
          {action: this.setarIten, icon: 'check-circle-o', iconSecundario: 'circle-o'},
          {action: this.desabilitar, icon: 'arrow-left', iconSecundario: 'arrow-right'},
          {action: this.modalAddFollowUp, icon: 'commenting-o', iconSecundario: 'commenting-o'}
        ]
      },
      tblFollowUp: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'ocorrencia', label: 'Ocorrencia', type: 'tooltip'},
          {name: 'dataGravacao', label: 'Data', type: 'date'},
          {name: 'usario_nome', label: 'Usuário', align: 'center'}
        ],
        key: 'id',
        button: true
      },
      frmFollowUp: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário', width: 3, type: 'label'},
              {name: 'devolver', label: 'Devolver', width: 3, type: 'checkbox'},
              {name: 'subtrair', label: 'Subtrair', width: 3, type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Ocorrência', type: 'textarea'}
            ]
          }
        ]
      },
      modalFollowUp: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        habilitar: this.habilitar,
        save: this.salvar_FollowUp
      },
      modalImportacao: {
        active: false,
        width: 'small'
      },
      liquidacoes: [],
      resumo: [],
      totalRodape: [],
      TotalSomaUF: {},
      totalLiquidacao: [],
      totalSobra: [],
      optionSelected: '',
      options: [],
      abas: utils.ufs,
      pendentes: utils.gerarUfs(),
      AbaGrupos: [],
      AbaGruposTotal: [],
      tab: -1,
      tabResumo: 0,
      qtdPendentes: 0,
      ufsPendentes: {},
      status: utils.liquidacaoStatus,
      dados: []
    }
  },
  created () {
    this.data = utils.today()
    this.getResumo()
  },
  methods: {
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/liquidacao/${this.data}`).then((res) => {
        this.getResumo()
        this.success('Arquivo carregado com sucesso.')
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    toggleModalImportacao () {
      this.modalImportacao.active = true
    },
    salvar_FollowUp () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha a Ocorrência')
      } else {
        axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.modalFollowUp.active = false
        })
        if (this.frmFollowUp.data.devolver === true) {
          axios.post(`api/v1/batimento/liquidacao/conciliacao/devolver`, this.frmFollowUp.data).then((the) => {
            this.toggle_modalFollowUp()
            this.frmFollowUp.data = {}
            this.getResumo()
            this.modalFollowUp.active = false
          })
        }
      }
    },
    desabilitar (idx) {
      axios.put(`api/v1/batimento/custas/conciliacao/desabilitar/${idx}`).then((the) => {
        console.log('deu certo')
      })
    },
    importacao (arq) {
      this.showLoading()
      this.arquivo = arq.target.files || arq.dataTransfer.files
      this.arquivoNome = this.arquivo[0].name
      let formData = new FormData()
      formData.append('txt', this.arquivo[0])
      axios.post(`api/v1/batimento/arquivos/santander/retorno/`, formData).then((res) => {
        this.comprovantes = res.data
        this.closeLoading()
        this.getResumo()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
      this.modalImportacao.active = false
      this.tab = 1
    },
    modalAddFollowUp (idx) {
      this.get_followup(this.resumo[idx].id)
      this.frmFollowUp.data.idCusta = this.resumo[idx].id
      this.frmFollowUp.data.tela = 'L'
      this.toggle_modalFollowUp()
    },
    toggle_modalFollowUp () {
      this.modalFollowUp.active = !this.modalFollowUp.active
    },
    get_followup (idx) {
      axios.get(`api/v1/batimento/custas/followup/${idx}`).then((res) => {
        this.followup = res.data
      })
    },
    getResumo () {
      this.liquidacoes = []
      this.AbaGrupos = []
      this.resumo = []
      this.options = []
      axios.get(`api/v1/batimento/liquidacao/conciliacao/${this.data}`).then((res) => {
        this.liquidacoes = res.data
        this.montarAbasGruposUF(res.data, res.data[0].apresentante_codigo)
        this.montarSelect(res.data)
        this.resumo = this.monta_dados(res.data, res.data[0].uf, res.data[0].apresentante_codigo, 0)
        this.getTotalLiquidacao(res.data[0].uf)
        this.TotalUF(this.AbaGrupos[0].uf, res.data)
      })
      this.detalhes = 1
    },
    TotalUF (uf, dados) {
      var totalFinal = 0
      var valor = 0
      var vlimpo = 0
      var vlimpo1 = 0
      for (var i in dados) {
        if (uf === dados[i].uf) {
          valor = dados[i].valor_credito
          vlimpo = valor.replace('R$', '')
          vlimpo1 = vlimpo.trim()
          if ((dados[i].status === 'C') || (dados[i].status === '1')) {
            totalFinal = parseFloat(vlimpo1) + parseFloat(totalFinal)
          }
        } else {
          this.TotalSomaUF = totalFinal
        }
      }
    },
    montarGrid (codApre) {
      this.resumo = []
      this.resumo = this.monta_dados(this.liquidacoes, this.liquidacoes[0].uf, codApre, 1)
      this.montarAbasGruposUF(this.liquidacoes, codApre)
      console.log(this.montarAbasGruposUF)
      this.getTotalLiquidacao(this.resumo[0].uf)
    },
    montarSelect (dados) {
      var codApre = dados[0].apresentante_codigo
      this.optionSelected = dados[0].apresentante_codigo
      this.options.push({'text': dados[0].apresentante_nome_completo, 'value': dados[0].apresentante_codigo})
      for (var i in dados) {
        if (dados[i].apresentante_codigo !== codApre) {
          this.options.push({'text': dados[i].apresentante_nome_completo, 'value': dados[i].apresentante_codigo})
        }
      }
    },
    setarIten (idx) {
      var valor = this.resumo[idx].valor_credito
      var vlimpo = valor.replace('R$', '')
      var vlimpo1 = vlimpo.trim()

      for (var i in this.AbaGrupos) {
        if (this.AbaGrupos[i].uf === this.resumo[idx].uf) {
          var inicial = this.TotalSomaUF
          if (this.resumo[idx].status === '0') {
            this.AbaGrupos[i].soma = parseFloat(inicial) + parseFloat(vlimpo1)
            this.TotalSomaUF = this.AbaGrupos[i].soma
            this.resumo[idx].status = '1'
          } else {
            this.AbaGrupos[i].soma = parseFloat(inicial) - parseFloat(vlimpo1)
            this.TotalSomaUF = this.AbaGrupos[i].soma
            this.resumo[idx].status = '0'
          }
        }

        if (parseFloat(this.TotalSomaUF) === parseFloat(this.totalLiquidacao)) {
          document.getElementById('totalGerado').classList.add('is-success')
          document.getElementById('totalGerado').classList.remove('is-danger')
        } else {
          document.getElementById('totalGerado').classList.add('is-danger')
          document.getElementById('totalGerado').classList.remove('is-success')
        }
      }
    },
    getTotalLiquidacao (uf) {
      axios.get(`api/v1/batimento/liquidacao/${this.data}`).then((res) => {
        this.totalLiquidacao = 0
        for (var i in res.data) {
          if (uf === res.data[i].uf) {
            this.totalLiquidacao = res.data[i].total
          }
          if (parseFloat(this.TotalSomaUF) === parseFloat(this.totalLiquidacao)) {
            document.getElementById('totalGerado').classList.add('is-success')
            document.getElementById('totalGerado').classList.remove('is-danger')
          } else {
            document.getElementById('totalGerado').classList.add('is-danger')
            document.getElementById('totalGerado').classList.remove('is-success')
          }
        }
      })
    },
    monta_dados (dados, conf, codigo, step) {
      var lista = []
      if (dados.length === 0) {
        return lista
      }
      var totalFinal = 0
      var valor = 0
      var vlimpo = 0
      var vlimpo1 = 0
      for (var i in dados) {
        if ((conf === dados[i].uf) && (codigo === dados[i].apresentante_codigo)) {
          lista.push(dados[i])
          for (var x in this.AbaGrupos) {
            if (this.AbaGrupos[x].uf === conf) {
              if (this.AbaGrupos[x].soma === 0) {
                valor = dados[i].valor_credito
                vlimpo = valor.replace('R$', '')
                vlimpo1 = vlimpo.trim()
                if ((dados[i].status === 'C') || (dados[i].status === '1')) {
                  totalFinal = parseFloat(vlimpo1) + parseFloat(totalFinal)
                }
              } else {
                totalFinal = this.AbaGrupos[x].soma
              }
            }
          }
        }
      }
      this.TotalSomaUF = totalFinal
      return lista
    },
    setFiltro (idx) {
      console.log(idx)
    },
    getLiquidacao () {
      this.liquidacoes = []
      axios.post(`api/v1/batimento/liquidacao/sintetico`, this.filtro).then((res) => {
        for (var i in res.data) {
          res.data[i]['status_nome'] = this.status[res.data[i].status]
        }
        this.liquidacoes = res.data
      })
      this.detalhes = 0
    },
    montar_abas (data) {
      this.pendentes = utils.gerarUfs()
      if (data.length > 0) {
        this.tab = data[0].uf
      }
      for (var i in data) {
        this.pendentes[data[i].uf].push(data[i])
      }
    },
    montarAbasGruposUF (data, codigo) {
      if (data.length > 0) {
        this.tab = data[0].grupo
      } else {
        return
      }
      this.AbaGrupos = []
      this.AbaGrupos.push({'uf': data[0].uf, 'soma': 0})
      var uf = data[0].uf
      for (var i in data) {
        if (i > 0) {
          if ((uf !== data[i].uf) && (codigo === data[i].apresentante_codigo)) {
            this.AbaGrupos.push({'uf': data[i].uf, 'soma': 0})
            uf = data[i].uf
          }
        }
      }
    },
    set_tab (aba) {
      this.tab = aba
    },
    setTab (idx, uf, codigo) {
      this.resumo = this.monta_dados(this.liquidacoes, uf, codigo, 1)
      this.getTotalLiquidacao(uf)

      if (parseFloat(this.TotalSomaUF) === parseFloat(this.totalLiquidacao)) {
        document.getElementById('totalGerado').classList.add('is-success')
        document.getElementById('totalGerado').classList.remove('is-danger')
      } else {
        document.getElementById('totalGerado').classList.add('is-danger')
        document.getElementById('totalGerado').classList.remove('is-success')
      }
    },
    postergar () {
      var pendente = {
        ids: []
      }
      for (var i in this.liquidacoes) {
        if (this.liquidacoes[i].gerar === true && this.liquidacoes[i].processado === false) {
          pendente.ids.push(this.liquidacoes[i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/postergar`, pendente).then((res) => {
        this.getLiquidacao()
        this.countPententes()
      })
    },
    trazer () {
      var pendente = {
        ids: []
      }
      for (var i in this.pendentes[this.tab]) {
        if (this.pendentes[this.tab][i].gerar === true) {
          pendente.ids.push(this.pendentes[this.tab][i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/trazer`, pendente).then((res) => {
        this.getResumo()
        this.countPententes()
      })
    },
    countPententes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes/count`).then((res) => {
        this.qtdPendentes = res.data
      })
    },
    countUfsPendentes () {
      axios.get(`api/v1/batimento/liquidacao/pendentes/uf/count`).then((res) => {
        this.ufsPendentes = utils.gerarUfs()
        for (var i in res.data) {
          this.ufsPendentes[res.data[i].uf] = res.data[i].count
        }
      })
    },
    gerarMapa () {
      if (parseFloat(this.TotalSomaUF) !== parseFloat(this.totalLiquidacao)) {
        alert('Confira o valor Total com Total Liquidação - Obrigatório')
      } else {
        this.showLoading()
        axios.patch(`api/v1/batimento/liquidacao/conciliacao/gerarmapa/${this.data}`, this.resumo).then((res) => {
          this.getResumo()
          this.success(res.data)
        }).catch((err) => {
          this.error(err.response.data)
          console.log(err.response)
        })
      }
    },
    adiarMapa () {
      this.showLoading()
      axios.put(`api/v1/batimento/liquidacao/adiarmapa/${this.data}/${this.tabResumo}`).then((res) => {
        this.getResumo()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiarPagamento () {
      this.showLoading()
      var ids = []
      for (var i in this.liquidacoes) {
        if (this.liquidacoes[i].gerar === true) {
          ids.push(this.liquidacoes[i].id)
        }
      }
      axios.post(`api/v1/batimento/liquidacao/adiarpagamento`, ids).then((res) => {
        this.getLiquidacao()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  }
}
</script>
