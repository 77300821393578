<template>
  <div>
    <es-title title="Movimento">
      <div slot=left>
        <es-datepicker v-model="data" type="button" @selected="get_pagamentos()"></es-datepicker>
      </div>
    </es-title>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="(conta, idx) in contas" :class="{'is-active': tab == idx}" @click="toggle_tab(idx)">
          <a><span>{{ conta.numero }}</span></a>
        </li>
      </ul>
    </div>
    <es-table :config="table" :rows="pagamentos"></es-table>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      contas: [],
      pagamentos: [],
      tab: 0,
      table: {
        fields: [
          {name: 'arquivo_nome', label: 'Arquivo'},
          {name: 'uf', label: 'UF'},
          {name: 'origem', label: 'Origem'},
          {name: 'count', label: 'Qtd'},
          {name: 'total', label: 'Valor', type: 'currency'}
        ],
        group: {field: 'origem', agg: 'total'},
        sum: 'total',
        button: true
      }
    }
  },
  created () {
    this.data = moment().format('DD/MM/YYYY')
    this.get_contas()
  },
  methods: {
    get_contas () {
      axios.get('api/v1/batimento/contas_debito').then((res) => {
        this.contas = res.data
        this.get_pagamentos()
      })
    },
    get_pagamentos () {
      axios.get(`api/v1/batimento/autenticacao/${this.contas[this.tab].numero}/${this.data}`).then((res) => {
        var origem = utils.origens
        for (var idx in res.data) {
          res.data.origem = origem[res.data[idx].origem]
        }
        this.pagamentos = this.monta_dados(res.data, this.table)
      })
    },
    monta_dados (dados, conf) {
      var lista = []
      if (dados.length === 0) {
        return lista
      }
      var groupTotal = 0.0
      var groupValue = dados[0][conf.group.field]
      for (var i in dados) {
        if (groupValue !== dados[i][conf.group.field]) {
          var row = {}
          for (var j in conf.fields) {
            if (conf.fields[j].name === conf.group.field) {
              row[conf.fields[j].name] = groupValue
            } else if (conf.fields[j].name === conf.group.agg) {
              row[conf.fields[j].name] = Number(groupTotal)
            } else {
              row[conf.fields[j].name] = ''
            }
          }
          groupTotal = 0.0
          groupValue = dados[i][conf.group.field]
          row.group = true
          lista.push(row)
        }
        groupTotal += Number(dados[i][conf.group.agg])
        dados[i].group = false
        lista.push(dados[i])
      }
      row = {}
      for (j in conf.fields) {
        if (conf.fields[j].name === conf.group.field) {
          row[conf.fields[j].name] = groupValue
        } else if (conf.fields[j].name === conf.group.agg) {
          row[conf.fields[j].name] = groupTotal
        } else {
          row[conf.fields[j].name] = ''
        }
      }
      row.group = true
      lista.push(row)
      return lista
    },
    toggle_tab (idx) {
      this.tab = idx
      this.get_pagamentos()
    }
  }
}
</script>

<style>

</style>
