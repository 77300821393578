<template>
  <div>
    <es-title title="Gravação">
      <div slot="left">
        <es-datepicker v-model="data" type="button" @selected="getMovimento()"></es-datepicker>
      </div>
      <div slot="right">
        <div class="button is-danger" @click="btnMudaTabOnClick()"><icon name="circle"></icon></div>
        <div class="button is-info" @click="mostrarModal()"><icon name="plus"></icon></div>
        <div class="button is-primary" @click="getPostergadas()"> Postergadas </div>
        <div class="button is-warning" @click="getRepasse()"> Repasse</div>
        <div class="button is-success" @click="load()"><icon name="download"></icon></div>
      </div>
    </es-title>
    <div v-if="tab == 1">
      <es-table :config="tblPagamentos" :rows="pagamentos">
        <div slot="buttons">
          <div class="button" @click="gerarGravacoes()">Gerar</div>
          <div class="button" @click="cancelarGravacoes()">Cancelar</div>
        </div>
      </es-table>
      <es-modal title="Importar Gravações" :config="mdlRepasse" @save="incluir" @cancel="cancel">
        <textarea class="textarea" v-model="dados" rows="15"></textarea>
      </es-modal>
    </div>
    <div v-else-if="tab == 2">
      <es-table :config="tblGravacoes" :rows="gravacoes" key="tblGravacoes">
        <div slot="buttons">
          <div class="button" @click="fechar()">Fechar</div>
        </div>
      </es-table>
    </div>
    <div v-else-if="tab == 3">
      <es-table :config="tblPostegadas" :rows="postergadas">
        <div slot="buttons">
          <button class="button" @click="trazer()">Trazer</button>
        </div>
      </es-table>
    </div>
    <div v-else>
      <div class="tabs is-boxed">
        <ul>
          <li v-for="(aba, idx) in abas " :key="idx" :class="{'is-active': tabMovimento == idx}" @click="setTab(idx)">
            <a>
              <span>{{ aba }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="tabMovimento == 1">
        <es-table :config="tabelaBancos" key="tblBancos" :rows="movimentoBancos"></es-table>
      </div>
      <div v-else>
        <es-table :config="tabelaEmpresas" key="tblEmpresas" :rows="movimentoEmpresas"></es-table>
      </div>
    </div>
    <es-modal :config="mdlGravacao">
      <h3 class="header">{{ frmGravacao.data.ids }}</h3>
      <es-form :config="frmGravacao"></es-form>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      posicaoEstados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'],
      posicaoEmpresas: ['873', '869', '091', '097', 'AMB', '085', '224', '376', '133'],
      tab: 1,
      tabMovimento: 1,
      postergadas: [],
      linhas: [],
      lista: [],
      dados: '',
      movimento: [],
      movimentoBancosLimpo: [],
      movimentoEmpresasLimpo: [],
      abas: {
        1: 'Bancos',
        2: 'Empresas'
      },
      pagamentos: [],
      movimentoBancos: [],
      tblPagamentos: {
        fields: [
          {name: 'nome', label: 'Instituto'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta', label: 'conta', align: 'right'},
          {name: 'quantidade', label: 'Títulos', align: 'center'},
          {name: 'total', label: 'Total', type: 'currency'},
          {name: 'valor_retido', label: 'Retido', type: 'currency'},
          {name: 'valor', label: 'Repasse', type: 'currency'}
        ],
        sum: 'valor',
        key: 'id'
      },
      tabelaBancos: {
        fields: [
          {name: 'CRA', label: 'CRA', align: 'center'},
          {name: '237', label: 'BRADESCO', align: 'center'},
          {name: '033', label: 'SANTANDER', align: 'center'},
          {name: '399', label: 'HSBC', align: 'center'},
          {name: '320', label: 'BIC', align: 'center'},
          {name: '001', label: 'BB', align: 'center'},
          {name: '389', label: 'MERCANTIL', align: 'center'},
          {name: '707', label: 'DAYCOVAL', align: 'center'},
          {name: '756', label: 'BANCOOB', align: 'center'},
          {name: '745', label: 'CITIBANK', align: 'center'},
          {name: '422', label: 'SAFRA', align: 'center'},
          {name: '755', label: 'BANK OF AMERICA', align: 'center'},
          {name: '748', label: 'SICREDI', align: 'center'},
          {name: '041', label: 'BANRISUL', align: 'center'},
          {name: '246', label: 'ABC', align: 'center'},
          {name: '962', label: 'ECOCRED', align: 'center'},
          {name: '341', label: 'ITAÚ', align: 'center'},
          {name: '604', label: 'BIB', align: 'center'},
          {name: '655', label: 'BV', align: 'center'},
          {name: '643', label: 'PINE', align: 'center'},
          {name: '021', label: 'BANESTES', align: 'center'},
          {name: '136', label: 'UNICRED', align: 'center'},
          {name: '637', label: 'SOFISA', align: 'center'},
          {name: '336', label: 'BANCO C6 SA', align: 'center'},
          {name: '752', label: 'BANCO PARAIBAS', align: 'center'},
          {name: '243', label: 'BANCO MÁXIMA', align: 'center'},
          {name: '487', label: 'DEUSTSCHE BANK', align: 'center'},
          {name: '611', label: 'PAULISTA', align: 'center'},
          {name: '329', label: 'BANCO QI', align: 'center'},
          {name: '025', label: 'BANCO ALFA', align: 'center'}
        ],
        key: 'id'
      },
      tabelaEmpresas: {
        fields: [
          {name: 'CRA', label: 'CRA', align: 'center'},
          {name: '873', label: 'CASA', align: 'center'},
          {name: '869', label: 'BCR', align: 'center'},
          {name: '091', label: 'UNICRED', align: 'center'},
          {name: '097', label: 'CREDSIS', align: 'center'},
          {name: '085', label: 'AILOS', align: 'center'},
          {name: '224', label: 'BANCO FIBRA', align: 'center'},
          {name: '376', label: 'BANCO JP MORGAN', align: 'center'},
          {name: '133', label: 'CRESOL', align: 'center'},
          {name: 'A', label: '', align: 'center'},
          {name: 'B', label: '', align: 'center'},
          {name: 'C', label: '', align: 'center'},
          {name: 'D', label: '', align: 'center'},
          {name: 'E', label: '', align: 'center'},
          {name: 'F', label: '', align: 'center'},
          {name: 'G', label: '', align: 'center'},
          {name: 'H', label: '', align: 'center'},
          {name: 'I', label: '', align: 'center'},
          {name: 'J', label: '', align: 'center'},
          {name: 'K', label: '', align: 'center'},
          {name: 'L', label: '', align: 'center'},
          {name: 'M', label: '', align: 'center'}
        ],
        key: 'id'
      },
      tblGravacoes: {
        fields: [
          {name: 'apresentante_nome', label: '', header: true},
          {name: 'total', label: 'Total', type: 'number', style: 'fn-bg-soft-grey'}
        ],
        formats: [
          {style: 'es-grey', field: 'status', condition: '=', value: 'G'},
          {style: 'es-blue', field: 'status', condition: '=', value: 'D'}
        ]
      },
      tblPostegadas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'data_gravacao', label: 'Data', type: 'date'},
          {name: 'quantidade', label: 'Qtd.', align: 'right'},
          {name: 'valor_gravacao', label: 'Valor Gravação', type: 'currency'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar'
      },
      mdlRepasse: {
        active: false,
        width: 'small',
        save: this.incluir
      },
      mdlGravacao: {
        active: false,
        width: 'medium',
        save: this.salvarGravacao,
        buttons: [
          {label: 'Postergar', action: this.postergar}
        ]
      },
      frmGravacao: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'quantidade', label: 'Quantidade', type: 'text'},
              {name: 'valor_gravacao', label: 'Valor Gravação', type: 'text'},
              {name: 'valor', label: 'Valor', type: 'text'}
            ]
          }
        ]
      },
      filtro: {
        fields: [
          {name: 'data', label: 'Data', placeholder: 'DD/MM/AAAA', type: 'text'}
        ],
        button: {label: 'Localizar', action: 'get_movimento', side: 'right'}
      },
      gravacoes: []
    }
  },
  created () {
    this.tab = 0
    this.movimentoBancosLimpo = [
      {CRA: 'AC', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'AL', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'AM', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'AP', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'BA', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'CE', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'DF', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'ES', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'GO', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'MA', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'MG', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'MS', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'MT', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'PA', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'PB', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'PE', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'PI', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'PR', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'RJ', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'RN', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'RO', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'RR', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'RS', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'SC', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'SE', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'SP', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''},
      {CRA: 'TO', '237': '', '033': '', '399': '', '320': '', '001': '', '389': '', '707': '', '756': '', '745': '', '422': '', '755': '', '748': '', '041': '', '246': '', '962': '', '341': '', '021': '', '752': '', '243': '', '487': '', '604': '', '655': '', '643': '', '611': '', '239': ''}
    ]
    this.movimentoEmpresasLimpo = [
      {CRA: 'AC', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'AL', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'AM', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'AP', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'BA', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'CE', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'DF', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'ES', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'GO', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'MA', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'MG', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'MS', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'MT', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'PA', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'PB', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'PE', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'PI', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'PR', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'RJ', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'RN', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'RO', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'RR', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'RS', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'SC', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'SE', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'SP', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'TO', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''}
    ]
    this.movimentoBancos = this.movimentoBancosLimpo
    this.movimentoEmpresas = this.movimentoEmpresasLimpo
    this.data = utils.lastUtilDay()
    this.getMovimento()
    this.montaColunas()
  },
  methods: {
    mostrarModal () {
      this.tab = 1
      this.mdlRepasse.active = true
    },
    editar (idx) {
      this.form.data = Object.assign({}, this.pagamentos[idx])
      this.mostrarModal()
    },
    montaColunas () {
      for (var i in utils.ufs) {
        this.tblGravacoes.fields.push(
          {
            name: `${utils.ufs[i]}.valor`,
            label: utils.ufs[i],
            type: 'number',
            clickable: true,
            sum: true,
            onclick: this.tblGravacaoCellClick,
            formats: [
              `>(*,${utils.ufs[i]}.quantidade,${utils.ufs[i]}.valor_gravacao):es-danger`,
              `<(*,${utils.ufs[i]}.quantidade,${utils.ufs[i]}.valor_gravacao):es-link`
            ]
          }
        )
      }
    },
    tblGravacaoCellClick (idx, field) {
      this.frmGravacao.data = this.gravacoes[idx][field] === undefined ? {} : Object.assign({}, this.gravacoes[idx][field])
      this.frmGravacao.data.ids = this.gravacoes[idx].gravacoes === undefined ? [] : this.gravacoes[idx].gravacoes
      this.frmGravacao.data.apresentante = this.gravacoes[idx].apresentante_codigo
      this.frmGravacao.data.uf = field
      this.mdlGravacao.subtitle = `${this.gravacoes[idx].apresentante_nome} - ${field}`
      this.mdlGravacao.active = true
    },
    getValores () {
      this.gravacoes = []
      this.showLoading()
      axios.post(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.gravacoes = res.data
        this.closeLoading()
        this.tab = 2
      }).catch((err) => {
        this.error('Erro ao carregar as gravações')
      })
    },
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.closeLoading()
      }).catch((err) => {
        this.closeLoading()
      })
    },
    incluir () {
      this.showLoading()
      var linhas = this.dados.split(';')
      axios.patch(`api/v1/batimento/gravacao/pagamento`, linhas).then((res) => {
        this.pagamentos = res.data
        this.mdlRepasse.active = false
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.linhas = []
        this.modal.active = false
        this.getMovimento()
      })
      this.modal.active = false
    },
    salvarGravacao () {
      var apre = this.frmGravacao.data.apresentante
      var uf = this.frmGravacao.data.uf
      axios.put(`api/v1/batimento/gravacao/${this.data}/${apre}/${uf}`, this.frmGravacao.data).then((res) => {
        this.mdlGravacao.active = false
        this.success(res.data)
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    cancel () {
      this.modal.active = false
    },
    gerarGravacoes () {
      this.showLoading()
      axios.post(`api/v1/batimento/gravacao/pagamento`, this.pagamentos).then((res) => {
        this.pagamentos = []
        this.linhas = []
        this.dados = ''
        this.tab = 2
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getRepasse () {
      this.showLoading()
      axios.put(`api/v1/batimento/gravacao`).then((res) => {
        this.pagamentos = res.data
        this.tab = 1
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    cancelarGravacoes () {
      this.pagamentos = []
      this.linhas = []
      this.dados = ''
      this.tab = 0
    },
    setTab (idx) {
      this.tabMovimento = idx
    },
    limpaGrid () {
      var movimentoKeys = []
      for (var movimento in this.movimento) {
        movimentoKeys = Object.keys(this.movimento[movimento])
        for (var keys in movimentoKeys) {
          if (movimentoKeys[keys] !== 'CRA' && movimentoKeys[keys] !== '') {
            this.movimento[movimento][movimentoKeys[keys]] = ' '
          }
        }
      }
    },
    getQuantidades () {
      this.showLoading()
      this.movimento = this.movimentoBancosLimpo
      this.limpaGrid()
      this.movimento = this.movimentoEmpresasLimpo
      this.limpaGrid()
      axios.get(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.movimento = res.data
        console.log(res.data)
        for (var movimento in this.movimento) {
          if (this.posicaoEmpresas.indexOf(this.movimento[movimento].apresentante_codigo) === -1) {
            this.movimentoBancos[this.posicaoEstados.indexOf(this.movimento[movimento].uf)][this.movimento[movimento].apresentante_codigo] = this.movimento[movimento].quantidade
          } else {
            this.movimentoEmpresas[this.posicaoEstados.indexOf(this.movimento[movimento].uf)][this.movimento[movimento].apresentante_codigo] = this.movimento[movimento].quantidade
          }
        }
        this.closeLoading()
      }).catch((err) => {
        this.error(err)
      })
    },
    btnMudaTabOnClick () {
      this.tab = this.tab === 2 ? 0 : 2
      this.getMovimento()
    },
    getPostergadas () {
      axios.get(`api/v1/batimento/gravacao`).then((res) => {
        this.postergadas = res.data
        this.tab = 3
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getMovimento () {
      switch (this.tab) {
        case 0:
          this.getQuantidades()
          break
        case 2:
          this.getValores()
          break
      }
    },
    gravacoesValores () {
      this.get()
    },
    postergar () {
      var apre = this.frmGravacao.data.apresentante
      var uf = this.frmGravacao.data.uf
      axios.post(`api/v1/batimento/gravacao/${this.data}/${apre}/${uf}`).then((res) => {
        this.mdlGravacao.active = false
        this.success(res.data)
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    fechar () {
      axios.put(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.pagamentos = res.data
        this.tab = 1
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazer () {
      axios.patch(`api/v1/batimento/gravacao/trazer/${this.data}`, utils.getIDs(this.postergadas)).then((res) => {
        this.tab = 2
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  }
}
</script>

<style>

</style>
