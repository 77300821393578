<template>
<div>
  <div class="columns is-multiline" v-for="(level, idx) in config.levels" >
    <div v-for="field in level.fields"  :class="column_concat(field.width)" v-if="field.width != 0">
      <div :class="{field: true, 'is-horizontal': type == 'horizontal'}" v-if="(field.type == 'text' || field.type == 'email' || field.type == 'pass')">
        <label> {{ field.label }} </label>
        <div class="control">
          <input v-if="field.name === 'banco' " maxlength="3"  class="input" :id="field.name" :name="field.name" :type="field.type" :placeholder="field.placeholder" :mask="field.mask" v-model="config.data[field.name]"  @blur="execute(field.onExit)"></input>
          <input v-else class="input" :id="field.name" :name="field.name" :type="field.type" :placeholder="field.placeholder" :mask="field.mask" v-model="config.data[field.name]"  :disabled="field.disable == true" @blur="execute(field.onExit)"></input>
          <span style="font-size: 12px;color: red;" v-if="field.info !== '' ">{{ field.info }}</span>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'document'">
        <label> {{ field.label }} </label>
        <div class="control">
          <the-mask class="input" :id="field.name" :name="field.name" :type="field.type" :placeholder="field.placeholder" v-model="config.data[field.name]" :mask="['###.###.###-##', '##.###.###/####-##']" :disabled="field.disable == true" @blur.native="execute(field.onExit)"/>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'select'">
        <div class="control">
          <label> {{ field.label }}</label>
        </div>
        <div class="control">
          <div class="select is-fullwidth">
            <select :id="field.name" :name="field.name" v-model="config.data[field.name]" placeholder="field.placeholder" :disabled="field.disable == true" @blur="execute(field.onExit)">
              <option v-for="(item, index) in field.values" :value="item.value" :key="index">{{ item.text }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'checkbox'">
        <label class="checkbox">
          <input type="checkbox" :name="field.name" :checked="field.checked" :id="field.id" v-model="config.data[field.name]" :disabled="field.disable == true">
          {{ field.label }}
        </label>
      </div>
      <div class="field" v-else-if="field.type == 'radio'">
        <div class="control">
          <label> {{ field.label }}
            <label class="radio" v-for="item in field.values">
              <input type="radio" :name="field.name" :id="item.id" v-model="config.data[field.name]" :disabled="field.disable == true">
              {{ item.text }}
            </label>
          </label>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'label'">
        <div class="control">
          <label> {{ field.label }}
            <label class="label" v-if="field.name == 'usuariologado'">
              <span>{{ $session.get('nome_usuario') }}</span>
            </label>
          </label>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'date'">
        <div class="control">
          <label> {{ field.label }}
            <label class="label">
              <input class="input" type="date" :name="field.name" v-model="config.data[field.name]">
            </label>
          </label>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'data'" v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" >
        <label> {{ field.label }}
          <es-datepicker v-model="config.data[field.name]" type="input"></es-datepicker>
        </label>
      </div>
      <div class="field" v-else-if="field.type == 'textarea'">
        <div class="control">
          <label> {{ field.label }}
            <label class="label">
              <textarea class="textarea" v-model="config.data[field.name]"></textarea>
            </label>
          </label>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'moeda'">
        <div class="control">
          <label> {{ field.label }}
            <label class="label">
              <input class="input" id="moeda" type="text" :placeholder="field.placeholder" v-model="config.data[field.name]" :disabled="field.disable == true" @keyup="execute(field.onKeyup)">
            </label>
          </label>
        </div>
      </div>
      <div class="field" v-else-if="field.type == 'moeda_custa'">
        <div class="control">
          <label> {{ field.label }}
            <label class="label">
              <input class="input" v-bind:id="field.name" type="text"  v-model="config.data[field.name]" v-on:keyup="aplicaMascara(config.data[field.name], field.name)"  :disabled="field.disable == true" v-on:blur="execute(field.onExit)">
            </label>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    config: Object,
    type: {
      type: String,
      default: 'normal',
      prop: '0'
    }
  },
  created () {
    this.configure()
  },
  methods: {
    aplicaMascara (n, name) {
      document.getElementById(name).value = ''
      var v = n
      v = v.replace(/\D/g, '') // permite digitar apenas numero
      v = v.replace(/(\d{1})(\d{14})$/, '$1.$2') // coloca ponto antes dos ultimos digitos
      v = v.replace(/(\d{1})(\d{11})$/, '$1.$2') // coloca ponto antes dos ultimos 11 digitos
      v = v.replace(/(\d{1})(\d{8})$/, '$1.$2') // coloca ponto antes dos ultimos 8 digitos
      v = v.replace(/(\d{1})(\d{5})$/, '$1.$2') // coloca ponto antes dos ultimos 5 digitos
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos
      document.getElementById(name).value = v
    },
    configure () {
    },
    execute (action) {
      if (typeof action === 'function') {
        action()
      }
    },
    column_concat (arg) {
      if (arg === '' || arg === undefined) {
        return 'column'
      } else {
        return 'column is-' + arg
      }
    }
  }

}
</script>

<style lang="sass">
  .select.witdh
    width: 100% !important

</style>
