<template>
  <div>
    <es-title title="Valores Pendentes">
      <div slot="right">
        <div class="columns">
          <div class="column">
            <div>
              <div class="button is-success" @click="toggleModalImportacao()"><icon name="download"></icon> &nbsp;&nbsp;Carregar Planilha </div>
            </div>
          </div>
          <div class="column">
            <div v-if="this.lista_acesso_usuario[0]==='AVP'">
              <div class="button is-info" @click="novo()"><icon name="plus"></icon> &nbsp;&nbsp;Novo Valor Pendente</div>
            </div>
          </div>
        </div>
      </div>
    </es-title>
    <div>
      <es-table :config="tabelaValoresPendentes" :rows="valorespendentes" @rowclick="modalAddFollowUp"></es-table>
    </div>
    <es-modal title="Adicionar novo valor pendente" :config="modal">
      <es-form :config="form"></es-form>
    </es-modal>
    <es-modal title="Inserir dados Favorecido/Conta" :config="modalEditarValoresPendentes">
      <b> Você esta editando valores pendentes correspondentes a :</b> <br>
      <b> Data crédito :</b> {{ this.form_valores_pendentes.data.data_credito | date }} - - 
      <b> Valor do crédito : </b> {{ this.form_valores_pendentes.data.valor | currency }} <br>
      <b> Descrição do lançamento :</b> {{ this.form_valores_pendentes.data.observacao }}<br>
      <b style="color: red;">Após cadastrar, o pagamento será enviado para o PAGFOR</b>
      <hr>
      <es-form v-if="tabFavorecido===0" :config="form_valores_pendentes"></es-form>
      <es-table v-else-if="tabFavorecido===1" :config="tabelaFavorecido" :rows="favorecidos" @rowclick="setFavorecido"></es-table>
    </es-modal>

    <es-modal title="Planilha - Pendentes" :config="modalImportacao">
      <div class="field">

        <div class="file is-info has-name is-boxed" style="display: block;">
          <label class="file-label">
            <input class="file-input" type="file" id="arquivo_xls" name="arquivo_xls" @change="capturaFile" accept=".xls,.xlsx"/>
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-cloud-upload-alt"></i>
              </span>
              <icon id="icon-cloud" name="cloud" width="100"  height="100"></icon>
              <br>
            </span>
            <span class="file-name" style="max-width: 100%;"> {{ nomeDaPlanilha }} </span>
          </label>
          <hr>
          <span class="file-label">  Importação de planilha - Somente arquivo (xls, xlsx) </span>
        </div>
      </div>
    </es-modal>

    <es-modal title="Excluir pagamento" :config="modalFollowUp" style="z-index: 9999;">
      <h2 style="font-size: 20px;font-weight: bold;text-align: center;margin-bottom: 30px;">Deletando permanente</h2>
      <hr>
      <div class="columns is-multiline">
        <input type="hidden" id="id_valores_pagamento" v-model="this.frmFollowUp.data.id_valores_pagamento">
          <div class="column is-6">
            <label>Data crédito</label>
            <p>{{ this.frmFollowUp.data.data_credito | date }}</p>
          </div>
          <div class="column is-6">
            <label>Valor</label>
            <p>{{ this.frmFollowUp.data.valor | currency }}</p>
          </div>
          <div class="column is-12">
            <label>Detalhe do lançamento</label>
            <p>{{ this.frmFollowUp.data.observacao }}</p>
          </div>
      </div>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import settings from '@/settings'
import utils from '@/utils'

export default {
  data () {
    return {
      nome_usuario: '',
      lista_acesso_usuario: [],
      idx: 0,
      tabAbas: 0,
      abas: [],
      data: '',
      nomeDaPlanilha: 'Selecione sua planilha',
      tabelaValoresPendentes: {
        fields: [
          {name: 'data_credito', label: 'Data Crédito', type: 'date', align: 'center'},
          {name: 'observacao', label: 'Lançamento', type: 'text', align: 'right'},
          {name: 'conta_debito_numero', label: 'Conta Débito', align: 'center'},          
          {name: 'valor', label: 'Valor', type: 'currency', align: 'right'}
        ],
        sum: 'valor',
        key: 'id',
        actions: [
          {action: this.modal_Editar_ValoresPendentes, icon: 'eye'}
        ],
        button: true
      },
      // Coluna padrão para ordernar
      colunaSort: 'ID',
      // Opção de ascendente ou descendente, padrão inicia como asc
      colunaOrder: 'asc',
      arquivo: '',
      arquivoNome: '',
      pagamentos: {},
      pagamento: [],
      pagamentosFiltrados: {},
      favorecidos: [],
      tab: 0,
      tabFavorecido: 0,
      comprovantes: [],
      valorespendentes: [],
      contaSelecionada: '',
      contasDebitos: [],
      achou: {},
      modalImportacao: {
        active: false,
        width: 'medium',
        cancel: this.limparModalExcel,
        save: this.gerarPlanilhaPendentes
      },
      contasDebitosFiltradas: [],
      tabelaFavorecido: {
        fields: [
          {name: 'conta_id', label: 'ID', align: 'right'},
          {name: 'favorecido_nome', label: 'Nome'},
          {name: 'favorecido_documento', label: 'Documento', type: 'documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      tabela: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'codigo', label: 'Código', align: 'center'},
          {name: 'data_movimento', label: 'Dt. Movimento', type: 'date'},
          {name: 'comarca_custa', label: 'Comarca', type: ''},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'status', label: 'Status', align: 'center'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'tooltip', data: 'observacao'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'agencia', align: 'right'},
          {name: 'favorecido_conta', label: 'conta', align: 'right'},
          {name: 'usuario', label: 'Usuário', type: 'toupper', align: 'center'},
          {name: 'conta_debito_numero', label: 'Conta Débito', align: 'center'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'ocorrencia', label: 'Ocorrência', align: 'center'},
          {name: 'temComprovante', label: '', type: 'comprovante'}
        ],
        sum: 'valor',
        key: 'id',
        button: true
      },
      modalFollowUp: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        delete: this.deletar_valores_pendentes,
      },
      modalDetalhe: {
        active: false,
        width: 'large',
        id: 'modalDetalhe'
      },
      modal: {
        active: false,
        cancel: this.cancel,
        save: this.salvar,
        width: 'large'
      },
      modalEditarValoresPendentes: {
        active: false,
        cancel: this.cancel,
        save: this.inserirApreFavConta,
        width: 'large'
      },
      form_valores_pendentes: {
        data: {
          favorecido: '',
          banco: '',
          agencia: '',
          favorecido_conta: '',
          tipo_conta: '',
          contas: []
        },
        row: {},
        levels: [
          {
            fields: [
            {
                name: 'tipo_favorecido',
                label: 'Tipo de favorecido',
                placeholder: 'texto',
                type: 'select',
                width: 3,
                values: [
                  {value: '0', text: 'Cartório'},
                  {value: '1', text: 'Cliente'}
                ]
              },
              {name: 'documento', label: 'Documento', placeholder: 'CPF, CNPJ ...', type: 'document', width: 3, onExit: this.getFavorecido},
              {name: 'favorecido', label: 'Favorecido', placeholder: 'Nome', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'banco', label: 'Banco', placeholder: '000', type: 'text', width: 1, onExit: this.validaDigitoBanco},
              {name: 'agencia', label: 'Agência/Dígito', info: 'Ex: 0001-2', placeholder: '00000-0', type: 'text', width: 2, onExit: this.aplicaMascaraAgencia},
              {name: 'favorecido_conta', label: 'Conta/Dígito', info: 'Ex: 00000-2', placeholder: '00000-0', type: 'text', onExit: this.aplicaMascaraConta},
              {
                name: 'tipo_conta',
                label: 'Tipo de Conta',
                placeholder: 'texto',
                type: 'select',
                width: 3,
                values: [
                  {value: 'C', text: 'Conta Corrente'},
                  {value: 'P', text: 'Conta Poupança'}
                ]
              }
            ]
          },
          {
            fields: [
              {name: 'codigo', label: 'Lançamento', maxlength: '140', placeholder: 'Insera o lançamento detalhado', width: 12, type: 'textarea'}
            ]
          }
        ]
      },
      form: {
        data: {
          contas: [],
          conta_debito_numero: '',
          data_credito: ''
        },
        row: {},
        levels: [
          {
            fields: [
              {name: 'data_credito', label: 'Data Crédito', type: 'data', width: 4, value: ''},
              {name: 'conta_debito_numero', label: 'Conta Débito', width: 4, type: 'select', values: []},
              {name: 'valor', label: 'Valor', placeholder: 'R$ 0.00', type: 'moeda', width: 4, onKeyup: this.aplicaMascaraMoeda},
              {name: 'observacao', maxlength: '140', label: 'Descrição do lançamento', placeholder: 'Identificação detalhada', width: 12, type: 'textarea'}
              
            ]
          }
        ]
      },
      modalRegerar: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        id: 'modalRegerar'
      },
      frmFollowUp: {
        data: {
          id_valores_pagamento: '',
          data_credito: '',
          valor: '0.0',
          observacao: ''
        }
      }
    }
  },
  created () {
    this.abas = utils.origens
    this.pagamentos = utils.gerarOrigens()
    this.form.data.tipo_conta = 'C'
    this.form.data.codigo = '0'
    this.form.data['apresentante_id'] = 0
    this.getValoresPendentes()
    this.getContas()
    this.nome_usuario = this.$session.get('nome_usuario')
    this.lista_acesso_usuario = this.$session.get('acesso')
  },
  methods: {
    capturaFile (event) {
      this.arquivo_planilha_vl_pendentes = event.target.files || event.dataTransfer.files
      let lista_arquivo_nome = event.target.value.split("\\")
      this.nomeDaPlanilha = lista_arquivo_nome[lista_arquivo_nome.length - 1]
    },
    gerarPlanilhaPendentes () {
      if (this.nomeDaPlanilha == 'Selecione sua planilha') {
        this.error('Selecione uma planilha!')
      } else {
        this.showLoading()
        let formData = new FormData()
        formData.append('xls', this.arquivo_planilha_vl_pendentes[0])
        axios.post(`api/v1/batimento/arquivos/valoresPendentes/`, formData).then((res) => {
          this.limparModalExcel()
          this.success('Processado com sucessso!')
          this.getValoresPendentes()
          this.modalImportacao.active = false
          this.closeLoading()
        }).catch((err) => {
          this.limparModalExcel()
          this.error(err.response.data)
          this.modalImportacao.active = false
          this.closeLoading()
        })
      }
    },
    limparModalExcel () {
      this.arquivo_planilha_vl_pendentes = ''
      document.getElementById('arquivo_xls').value = ''
      this.nomeDaPlanilha = 'Selecione sua planilha'
    },
    toggleModalImportacao () {
      this.limparModalExcel()
      this.modalImportacao.active = true
    },
    inserirApreFavConta () {
      console.log(this.form_valores_pendentes)

      var okGravar = 'True'
      if (this.form_valores_pendentes.data.banco.length < 3) {
        this.error('Informe 3 dígitos para o banco')
        okGravar = 'False'
        document.getElementById('banco').focus()
      } else if (this.form_valores_pendentes.data.agencia.length === 0) {
        this.error('Informe a agência')
        okGravar = 'False'
        document.getElementById('agencia').focus()
      } else if (this.form_valores_pendentes.data.favorecido_conta.length === 0) {
        this.error('Informe a conta')
        okGravar = 'False'
        document.getElementById('favorecido_conta').focus()
      } else if ((this.form_valores_pendentes.data.codigo === undefined) || (this.form.data.codigo === '')) {
        this.form.data.codigo = '000'
        okGravar = 'True'
      }
      if (okGravar === 'True') {
        axios.post(`api/v1/batimento/pagamentos/valorespendentes/cadastrar/favorecidoconta`, this.form_valores_pendentes.data).then((res) => {
          this.form_valores_pendentes.data = {}
          this.modalEditarValoresPendentes.active = false
          this.form_valores_pendentes.data.codigo = ''
          this.getValoresPendentes()
        })
        this.form_valores_pendentes.data['apresentante_id'] = '0'
      }

    },
    modal_Editar_ValoresPendentes (idx) {
      var item = this.valorespendentes[idx]
      this.$set(this.form_valores_pendentes.data, 'id_valores_pagamento', item.id)
      this.$set(this.form_valores_pendentes.data, 'data_credito', item.data_credito)
      this.$set(this.form_valores_pendentes.data, 'valor', item.valor)
      this.$set(this.form_valores_pendentes.data, 'observacao', item.observacao)

      this.toggle_editar_valores_pendentes()
    },
    deletar_valores_pendentes () {
      var id_valores_pagamento = this.frmFollowUp.data.id_valores_pagamento

      axios.delete(`api/v1/batimento/pagamentos/valorespendentes/excluir/${id_valores_pagamento}`).then((res) => {
        console.log(res.data)
        if(res.data.status === 'erro'){
          this.error(res.data.descricao)
        } else {
          this.success(res.data.descricao)
        }
        this.getValoresPendentes()
        this.toggle_modalFollowUp()
      })
    },
    modalAddFollowUp (idx) {
      var item = this.valorespendentes[idx]
      this.frmFollowUp.data.id_valores_pagamento = item.id
      this.frmFollowUp.data.data_credito = item.data_credito
      this.frmFollowUp.data.valor = item.valor
      this.frmFollowUp.data.observacao = item.observacao
      this.toggle_modalFollowUp()
    },
    salvar_FollowUp () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      this.frmFollowUp.data.fk_pg = this.pagamento.id
      this.frmFollowUp.data.status = this.pagamento.status
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        this.error('Informe o motivo.')
      } else {
        axios.post(`api/v1/batimento/pagamentos/regerar`, this.frmFollowUp.data).then((the) => {
          if (the.data === '0') {
            this.error('Nenhum pagamento afetado, confira os dados!')
          } else {
            this.success(the.data)
            this.toggle_modalFollowUp()
            this.modalDetalhe.active = false
            let arg = {}
            arg.data = this.f1.fields[1].value
            arg.dataF = this.f1.fields[2].value
            arg.apresentante_id = this.f1.fields[3].value
            arg.UF = this.f1.fields[4].value
            arg.documento = this.f1.fields[5].value
            this.localizar(arg)
          }
        })
      }
    },
    getContas () {
      this.form.data.data_credito = this.getDiaUtilMenosUm()
      axios.get(`api/v1/batimento/contas_debito/5`).then((res) => {
        this.form.levels[0].fields[1].values = []
        for (var i in res.data) {
          this.form.levels[0].fields[1].values.push({value: res.data[i], text: res.data[i]})
        }
        this.form.data.conta_debito_numero = '302765'
      })
    },
    getDiaUtilMenosUm () {
      var semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

      var d = new Date();
      var yesterday = new Date(d.setDate(d.getDate() - 1));
      console.log(semana[yesterday.getDay()])
      for (let i = 0; i < 7; i++){
        if((semana[yesterday.getDay()] === 'Domingo') || (semana[yesterday.getDay()] === 'Sábado')){
          // reduzir um dia
          yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1));
        } else {
          break;
        }
      }
      
      return yesterday.toLocaleDateString()
    },
    montar_abas (data) {
      if (data.length > 0) {
        this.tabAbas = data[0].origem
      }
      for (var i in data) {
        this.pagamentos[data[i].origem].push(data[i])
      }
    },
    novo () {
      this.form.data['apresentante_id'] = '0'
      this.tabFavorecido = 0
      this.modal.active = true
    },
    setarConta () {
      document.getElementById('conta').value = this.contaSelecionada
    },
    salvar () {
      var okGravar = 'True'
      if (this.form.data.data_credito === undefined) {
        this.error('Informe  a data de credito')
        okGravar = 'False'
      } else if (this.form.data.conta_debito_numero === undefined) {
        this.error('Informe  a conta débito')
        okGravar = 'False'
        document.getElementById('conta_debito_numero').focus()
      } else if (this.form.data.valor === undefined) {
        this.error('Informe o valor do pagamento')
        okGravar = 'False'
        document.getElementById('moeda').focus()
      } else if ((this.form.data.valor === '0') || (this.form.data.valor === '0,0') || (this.form.data.valor === '0,00') || (this.form.data.valor === '')) {
        this.error('Informe o valor do pagamento')
        okGravar = 'False'
        document.getElementById('moeda').focus()
      } else if ((this.form.data.observacao === undefined) || 
      (this.form.data.observacao === '')) {
        this.error('Informe  a descrição do lançamento')
        okGravar = 'False'
        document.getElementById('conta_debito_numero').focus()
      } else if ((this.form.data.codigo === undefined) || (this.form.data.codigo === '')) {
        this.form.data.codigo = '000'
        okGravar = 'True'
      } 
      if (okGravar === 'True') {
        this.form.data['lancamento'] = 'M'
        this.form.data.valor = this.form.data.valor.replace('.', '')
        this.form.data.valor = this.form.data.valor.replace(',', '.')
        axios.post(`api/v1/batimento/pagamentos/valorespendentes/cadastrar`, this.form.data).then((res) => {
          this.form.data = {}
          this.form.data.data_credito = this.getDiaUtilMenosUm()
          this.form.data.tipo_conta = 'C'
          this.form.data.codigo = '0'
          this.form.data['apresentante_id'] = 0
          this.getContas()
          this.modal.active = false
          this.getValoresPendentes()
        })
      }
    },
    cancel () {
      this.form.data = {}
    },
    toggle_modalFollowUp () {
      if (this.lista_acesso_usuario[0] === 'AVP')
        this.modalFollowUp.active = !this.modalFollowUp.active
    },
    toggle_editar_valores_pendentes () {
      this.modalEditarValoresPendentes.active = !this.modalEditarValoresPendentes.active
    },
    toggleModalRegerar () {
      this.modalRegerar.active = true
      var element = document.getElementById('modalRegerar')
      element.setAttribute('style', 'z-index: 100;')
    },
    importacao (arq) {
      this.showLoading()
      this.arquivo = arq.target.files || arq.dataTransfer.files
      this.arquivoNome = this.arquivo[0].name
      let formData = new FormData()
      formData.append('zip', this.arquivo[0])
      axios.post(`api/v1/batimento/pagamentos/comprovantes/`, formData).then((res) => {
        this.comprovantes = res.data
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
      this.tab = 1
    },
    getValoresPendentes (arq) {
      this.showLoading()
      axios.get(`api/v1/batimento/pagamentos/valorespendentes/`).then((res) => {
        this.valorespendentes = res.data
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    comprovanteDownload (idx) {
      axios.get(`/api/v1/batimento/pagamentos/comprovantes/download/${this.pagamentos[this.tabAbas][idx].numero}`).then((res) => {
        if (res.data === 'erro') {
          this.error('Pagamento sem retorno processado.Por favor, verificar junto ao Banco emissor.!')
        } else {
          var uriContent = 'data:application/pdf,' + escape(res.data)
          var link = document.createElement('a')
          link.href = uriContent
          link.download = 'Comprovante.pdf'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    detalhePagamento (idx) {
      this.idx = idx
      this.pagamento = Object.assign({}, this.pagamentos[this.tabAbas][idx])
      this.modalDetalhe.active = true
    },
    relatorioXls () {
      this.showLoading()
      var dt = this.f1.fields[1].value
      var dtf = this.f1.fields[2].value
      var aprecod = this.f1.fields[3].value
      var UF = this.f1.fields[4].value
      var contaid = this.f1.fields[6].value
      var doc = ''
      if ((this.f1.fields[5].value === undefined) || (this.f1.fields[5].value === '')) {
        doc = '0'
      } else {
        doc = this.f1.fields[5].value
      }
      axios.get(`api/v1/batimento/pagamentos/relatorio/xls/${dt}/${dtf}/${aprecod}/${UF}/${doc}/${contaid}`).then((res) => {
        window.location.href = `${settings.baseURL}/api/v1/batimento/pagamentos/comprovantes/download/Relatorio_Pagamento.xlsx`
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioPdf () {
      this.showLoading()
      var dt = this.f1.fields[1].value
      var dtf = this.f1.fields[2].value
      var aprecod = this.f1.fields[3].value
      var UF = this.f1.fields[4].value
      var contaid = this.f1.fields[6].value
      var doc = this.f1.fields[5].value
      if ((this.f1.fields[5].value === undefined) || (this.f1.fields[5].value === '')) {
        doc = '0'
      } else {
        doc = this.f1.fields[5].value
      }
      axios.get(`api/v1/batimento/pagamentos/relatorio/pdf/${dt}/${dtf}/${aprecod}/${UF}/${doc}/${contaid}/${this.tabAbas}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    validaDigitoBanco () {
      if (this.form_valores_pendentes.data.banco.length < 3) {
        this.error('Informe 3 dígitos para o banco')
      }
    },
    aplicaMascaraMoeda () {
      var v = this.form.data.valor
      var valor = String(v)
      valor = valor.replace(/\D/g, '') // permite digitar apenas numero
      valor = valor.replace(/(\d{1})(\d{14})$/, '$1.$2') // coloca ponto antes dos ultimos digitos
      valor = valor.replace(/(\d{1})(\d{11})$/, '$1.$2') // coloca ponto antes dos ultimos 11 digitos
      valor = valor.replace(/(\d{1})(\d{8})$/, '$1.$2') // coloca ponto antes dos ultimos 8 digitos
      valor = valor.replace(/(\d{1})(\d{5})$/, '$1.$2') // coloca ponto antes dos ultimos 5 digitos
      valor = valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos
      this.form.data.valor = valor
      return v
    },
    aplicaMascaraConta () {
      var v = this.form.data.favorecido_conta
      var valor = String(v)
      // valor = valor.replace(/\D/g, '') // permite digitar apenas numero
      // valor = valor.replace(/(\d{1})(\d{1,1})$/, '$1-$2') // coloca virgula antes dos ultimos 2 digitos
      this.form.data.favorecido_conta = valor
      return 'ok'
    },
    aplicaMascaraAgencia () {
      var v = this.form.data.agencia
      var valor = String(v)
      // valor = valor.replace(/\D/g, '') // permite digitar apenas numero
      // valor = valor.replace(/(\d{1})(\d{1,1})$/, '$1-$2') // coloca virgula antes dos ultimos 2 digitos
      this.form.data.agencia = valor
      return 'ok'
    },
    getFavorecido () {
      var documento = utils.documentoNumero(this.form_valores_pendentes.data.documento)
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.form_valores_pendentes.data.favorecido = ''
        this.form_valores_pendentes.data.banco = ''
        this.form_valores_pendentes.data.agencia = ''
        this.form_valores_pendentes.data.favorecido_conta = ''
        this.form_valores_pendentes.data.tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
        } else {
          this.warning('Favorecido não encontrado.')
          this.form.data.favorecido = ''
          this.form.data.banco = ''
          this.form.data.agencia = ''
          this.form.data.favorecido_conta = ''
          this.form.data.tipo_conta = ''
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.form_valores_pendentes.data.favorecido = this.favorecidos[idx].favorecido_nome
      this.form_valores_pendentes.data.banco = this.favorecidos[idx].favorecido_banco
      this.form_valores_pendentes.data.agencia = this.favorecidos[idx].favorecido_agencia
      this.form_valores_pendentes.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.form_valores_pendentes.data.tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    },
    set_tab (idx) {
      this.tabAbas = idx
    },
    getOrigem () {
      axios.get(`api/v1/batimento/pagamentos/`)
    },
    cell_function (idx) {
      alert(idx)
    }
  },
  computed: {
    formatarDoc: function () {
      var value = this.pagamento.documento
      if (value === null) {
        return '-'
      } else {
        let v = value
        v = v.replace(/\D/g, '')
        if (v.length < 14) {
          v = v.replace(/(\d{3})(\d)/, '$1.$2')
          v = v.replace(/(\d{3})(\d)/, '$1.$2')
          v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
        } else {
          v = v.replace(/^(\d{2})(\d)/, '$1.$2')
          v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
          v = v.replace(/(\d{4})(\d)/, '$1-$2')
        }
        return v
      }
    }
  }
}
</script>


<style lang="scss">

a.info {
font-family:verdana,arial,helvetica,sans-serif;
position:relative;
border-bottom:1px dashed #ccc;
text-decoration:none;
color:#333333;
}
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
 display:block;
 position:absolute;
 z-index:1;
 bottom:1em;
 width:500px !important;
 border:1px solid #F2AF1D;
 background-color:#FDF1D5;
 color:#000;
 text-align:justify;
 font-size:12px;
 font-weight:normal;
 padding:3px;
 line-height:15px;
}
.fa-icon {
  float: left !important;
}
.regerar {
  z-index: 100 !important;
}
.modal-background {
  background-color: rgba(10, 10, 10, 0.21) !important;
}
.area-main {
  display: block !important;
}
.modal-card-body{
  min-height: 100px !important;
}

.modal-card-body {
  overflow: none !important;
}

</style>
