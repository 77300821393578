<template>
  <div>
    <es-title title="Pendentes"></es-title>
    <div class="columns">
      <div class="column">
        <div class="control">
          <div class="tabs is-boxed">
            <ul>
              <li v-for="(aba, idx) in abas " v-if="pagamentos[idx].length > 0" :class="{'is-active': tab == idx}" @click="tab = idx">
                <a>
                  <span>{{ aba }}</span>
                  <span class="tag is-danger is-rounded">{{ pagamentos[idx].length }}</span>
                </a>
              </li>
            </ul>
          </div>
          <es-table :config="tabela" :rows="pagamentos[tab]" @rowclick="editar">
            <div slot="buttons">
              <div class="button" @click="gerar_pagamentos(tab)">Gerar Selecionas</div>
              <div class="button is-danger" @click="excluir_pagamentos(tab)">Excluir Selecionados</div>
            </div>
          </es-table>
        </div>
      </div>
    </div>
    <es-modal title="Edição Pagamento" :config="modal">
      <span class="is-size-7 is-bold">* Após efetuar a alteração do favorecido, a custa irá para aba de Aprovação.</span>
      <es-form  v-if="tabFavorecido==0" :config="form"></es-form>
      <es-table v-else-if="tabFavorecido==1" :config="tabelaFavorecido" :rows="favorecidos" @rowclick="setFavorecido"></es-table>
      <hr>
      <p>Histório de pagamento <b>( 2 ÚLTIMOS PAGAMENTOS )</b></p>
      <es-table :config="tabelaHistoricos" :rows="historicos" ></es-table>
    </es-modal>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      tab: '',
      abas: utils.origens,
      pagamentos: utils.gerarOrigens(),
      pagamento: [],
      favorecidos: [],
      historicos: [],
      tabFavorecido: 0,
      tabelaFavorecido: {
        fields: [
          {name: 'conta_id', label: 'ID', align: 'right'},
          {name: 'favorecido_nome', label: 'Nome', type: 'toupper'},
          {name: 'favorecido_documento', label: 'Documento', type: 'documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      tabelaHistoricos: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'municipio', label: 'Município', align: 'center'},
          {name: 'cartorio__numero', label: 'Cart.', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'usuario', label: 'Usuário', type: 'toupper', align: 'center'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'ocorrencia', label: 'Ocorrência', align: 'center'}
        ],
        sum: 'valor',
        key: 'id'
      },
      tabela: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'lancamento', label: 'Tipo', align: 'center'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'municipio', label: 'Município', align: 'center'},
          {name: 'cartorio__numero', label: 'Cart.', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'tooltip', data: 'observacao'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'numero_conta_debito', label: 'Conta Débito', align: 'center'},
          {name: 'usuario', label: 'Usuário', type: 'toupper', align: 'center'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'ocorrencia', label: 'Ocorrência', align: 'center'}
        ],
        sum: 'valor',
        key: 'id',
        toggle: 'gerar',
        button: true
      },
      modal: {
        active: false,
        cancel: this.cancel,
        save: this.salvar,
        width: 'large'
      },
      contas: [],
      form: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {
                name: 'origem',
                label: 'Origem',
                type: 'select',
                width: 3,
                values: [
                  {value: 0, text: 'Avulso'},
                  {value: 1, text: 'Custas Diárias'},
                  {value: 2, text: 'Certidão IP'},
                  {value: 3, text: 'Cancelamento'},
                  {value: 4, text: 'Obrigação de fazer'},
                  {value: 5, text: 'Devolução'},
                  {value: 6, text: 'Liquidação'},
                  {value: 7, text: 'Gravação Eletrônica'}
                ]
              },
              {name: 'apresentante_id', label: 'Apresentante', width: 0, type: 'hidden'},
              {name: 'documento', label: 'Documento', type: 'document', width: 3, onExit: this.getFavorecido},
              {name: 'favorecido', label: 'Favorecido', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'banco', label: 'Banco', type: 'text', width: 1},
              {name: 'agencia', label: 'Agência', type: 'text', width: 2},
              {name: 'favorecido_conta', label: 'Conta', type: 'text'},
              {
                name: 'tipo_conta',
                label: 'Tipo de Conta',
                type: 'select',
                width: 3,
                values: [
                  {value: 'C', text: 'Conta Corrente'},
                  {value: 'P', text: 'Conta Poupança'}
                ]
              },
              {name: 'valor', label: 'Valor', type: 'text', width: 3}
            ]
          },
          {
            fields: [
                {name: 'conta_debito_numero', label: 'Conta Debito', width: 3, type: 'select', values: []}
            ]
          }
        ]
      }
    }
  },
  created () {
    this.get()
    this.getContas()
  },
  methods: {
    montar_abas (data) {
      this.pagamentos = utils.gerarOrigens()
      if (data.length > 0) {
        this.tab = data[0].origem
      }
      for (var i in data) {
        this.pagamentos[data[i].origem].push(data[i])
      }
    },
    getContas () {
      axios.put(`api/v1/batimento/contas_debito`).then((res) => {
        this.form.levels[2].fields[0].values = []
        for (var i in res.data) {
          this.form.levels[2].fields[0].values.push({value: res.data[i].numero, text: res.data[i].numero})
        }
      })
    },
    gerar_pagamentos (tab) {
      this.showLoading()
      axios.post(`api/v1/batimento/pagamentos/cadastrarAll`, this.getIds(this.pagamentos[tab])).then((res) => {
        this.success('Gerado com sucessso!')
        this.get()
        this.form.data = {}
        this.modal.active = false
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    excluir_pagamentos (tab) {
      console.log(this.getIds(this.pagamentos[tab]))
      this.showLoading()
      axios.put(`api/v1/batimento/pagamentos/excluirAll`, this.getIds(this.pagamentos[tab])).then((res) => {
        this.success('Excluido com sucessso!')
        this.get()
        this.form.data = {}
        this.modal.active = false
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getIds (lista) {
      var ids = []
      for (var i in lista) {
        if (lista[i].gerar) {
          ids.push(lista[i].id)
        }
      }
      return ids
    },
    toggle_gerar (idx) {
      this.pagamentos[1][idx].gerar = !this.pagamentos[1][idx].gerar
    },
    get () {
      axios.get(`api/v1/batimento/pagamentos/pendentes/`).then((res) => {
        this.montar_abas(res.data)
        console.log(res.data)
      })
    },
    editar (idx) {
      this.idx = idx
      this.pagamento = this.pagamentos[this.tab][idx]
      this.form.data = Object.assign({}, this.pagamento)
      this.getHistorico(this.form.data.documento)
      this.form.data.origemOLD = this.form.data.origem
      if (this.form.data.origem === 10) {
        this.form.data.origem = 1
      }
      this.tabFavorecido = 0
      this.modal.active = true
    },
    salvar () {
      if (this.form.data.valor === this.form.data.valor.replace(',', '.')) {
        this.form.data.valor = this.form.data.valor.replace(',', '.')
      } else {
        this.form.data.valor = this.form.data.valor.replace('.', '')
        this.form.data.valor = this.form.data.valor.replace(',', '.')
      }
      this.form.data['apresentante_id'] = '0'
      this.form.data.codigo = '0'
      axios.post(`api/v1/batimento/pagamentos/cadastrar`, this.form.data).then((res) => {
        this.get()
        this.form.data = {}
        this.modal.active = false
      })
    },
    cancel () {
      this.form.data = {}
    },
    set_tab (idx) {
      this.tab = idx
    },
    getFavorecido () {
      var documento = utils.documentoNumero(this.form.data.documento)
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.form.data.favorecido = ''
        this.form.data.banco = ''
        this.form.data.agencia = ''
        this.form.data.favorecido_conta = ''
        this.form.data.tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
          this.getHistorico(documento)
        } else {
          this.warning('Favorecido não encontrado.')
          this.form.data.favorecido = ''
          this.form.data.banco = ''
          this.form.data.agencia = ''
          this.form.data.favorecido_conta = ''
          this.form.data.tipo_conta = ''
        }
      })
    },
    getHistorico (documento) {
      axios.get(`api/v1/batimento/pagamentos/historicos/${documento}`).then((res) => {
        if (res.data.length > 0) {
          this.historicos = res.data
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.form.data.favorecido = this.favorecidos[idx].favorecido_nome
      this.form.data.banco = this.favorecidos[idx].favorecido_banco
      this.form.data.agencia = this.favorecidos[idx].favorecido_agencia
      this.form.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.form.data.tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    }
  }
}
</script>
<style lang="scss">
.es-table-wrapper .es-table-scroller {
  overflow-x: inherit !important;
}
</style>