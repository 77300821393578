import Vue from 'vue'
import App from './App'
import router from './router'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import EsTitle from '@/components/EsTitle'
import EsTable from '@/components/EsTable'
import EsFilter from '@/components/EsFilter'
import EsModal from '@/components/EsModal'
import EsForm from '@/components/EsForm'
import EsDatepicker from '@/components/EsDatepicker'
import EsLoading from '@/components/EsLoading'
import VueSession from 'vue-session'
import axios from 'axios'
import filters from './filters'
import settings from './settings'
import Notifications from 'vue-notification'
import Snotify from 'vue-snotify'
//import { Circle2 } from 'vue-loading-spinner'
import loading from 'vue-full-loading'
import VueTheMask from 'vue-the-mask'

Vue.component('icon', Icon)
Vue.component('es-table', EsTable)
Vue.component('es-title', EsTitle)
Vue.component('es-filter', EsFilter)
Vue.component('es-modal', EsModal)
Vue.component('es-form', EsForm)
Vue.component('es-datepicker', EsDatepicker)
//Vue.component('circle-2', Circle2)
Vue.component('loading', loading)
Vue.component('es-loading', EsLoading)
Vue.use(Notifications)
Vue.use(Snotify)
Vue.use(VueSession, {persist: true})
Vue.use(filters)
Vue.use(VueTheMask)

Vue.config.productionTip = settings.mode !== 'dev'
Vue.config.devtools = settings.mode === 'dev'
axios.defaults.baseURL = settings.baseURL

Vue.mixin({
  data () {
    return {
      loading: false
    }
  },
  created () {
    this.is_authenticated()
  },
  methods: {
    logout () {
      this.$session.destroy()
      axios.defaults.headers.common.Authorization = ''
    },
    is_authenticated () {
      if (!this.$session.get('authenticated')) {
        this.$session.destroy()
        axios.defaults.headers.common.Authorization = ''
        console.log(this.$route.path)
        if (this.$route.path !== '/login'){
          this.$router.push('/login')
        }
      } else if (axios.defaults.headers.common.Authorization === undefined) {
        axios.defaults.headers.common.Authorization = 'Token ' + this.$session.get('token')
      }      
    },
    success (text) {
      this.$notify({text: text, type: 'success'})
      this.closeLoading()
    },
    warning (text) {
      this.$notify({text: text, type: 'warning'})
      this.closeLoading()
    },
    error (text) {
      this.$notify({text: text, type: 'error'})
      this.closeLoading()
    },
    showLoading () {
      this.loading = true
    },
    closeLoading () {
      this.loading = false
    }
  },
  computed: {
    nomeUsuario () {
      return this.$session.get('nome_usuario')
    }
  }
})

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  template: '<App/>',
  components: { App }
}).$mount('#app')
