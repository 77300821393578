<template>
  <div>
    <es-title title="Pagamentos">
      <div slot="left">
        <div v-if="tab==1" class="button" @click="tab=0, get()">Voltar</div>
      </div>
      <div slot="right">
        <div v-if="tab==0">
          <div class="button is-info" @click="novo()"><icon name="plus"></icon></div>
          <div class="button is-danger" @click="toggleModalImportacao()">Comprovantes</div>
        </div>
      </div>
    </es-title>
    <!-- <div v-if="Object.keys(achou).length > 0" slot="left">
        <span class="subtitle is-3" style="margin: 15px;"">Escolha uma conta</span>
        <div class="select">
          <select v-model="contaSelecionada" @change="setarConta()" >
            <option disabled value="">Escolha uma conta</option>
            <option v-for="(option, index) in contasDebitosFiltradas" v-bind:value="option.valor" >
              {{ option.texto }}
            </option>
          </select>
        </div>
      </div> -->
    <div v-if="tab==0">
      <es-filter :config="f1" :contasDebitosFiltradas="contasDebitosFiltradas" ref="filtro"></es-filter>
      <br>
        <div class="columns">
          <div class="column">
            <div class="tabs is-boxed">
              <ul>
                <li v-for="(aba, idx) in abas" v-if="pagamentos[idx].length > 0" :class="{'is-active': tabAbas == idx}" @click="set_tab(idx)">
                  <a>
                    <span>{{ aba }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <es-table :config="tabela" :rows="pagamentos[tabAbas]" @rowclick="detalhePagamento">
              <div slot="buttons">
                <div class="button is-danger is-outlined" @click="relatorioPdf()">
                  <icon name="file-pdf"></icon><span> Relatório PDF (Sintético)</span>
                </div>
                <div class="button is-success is-outlined" @click="relatorioXls()">
                  <icon name="file-pdf"></icon><span> Relatório Xls (Sintético)</span>
                </div>
              </div>
            </es-table>
        </div>
      </div>
    </div>
    <div v-else-if="tab==1">
      <es-table :config="tabelaComprovantes" :rows="comprovantes"></es-table>
    </div>
    <es-modal title="Novo Pagamento" :config="modal">
      <es-form v-if="tabFavorecido==0" :config="form"></es-form>
      <es-table v-else-if="tabFavorecido==1" :config="tabelaFavorecido" :rows="favorecidos" @rowclick="setFavorecido"></es-table>
    </es-modal>
    <es-modal title="Importação de comprovantes" :config="modalImportacao">
      <div class="field">
        <div class="file is-centered is-boxed is-success has-name">
          <label class="file-label">
            <input class="file-input" type="file" @change="importacao" accept="application/x-zip-compressed">
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">
                Selecionar arquivo ZIP
              </span>
            </span>
            <span class="file-name">
              {{ arquivoNome }}
            </span>
          </label>
        </div>
      </div>
    </es-modal>
    <es-modal title="Regerar pagamento" :config="modalRegerar">
      <div class="field">
        <div class="file is-centered is-boxed is-success has-name">
          <es-form :config="frmFollowUp"></es-form>
          <div style="margin-top: 10px;">
          </div>
        </div>
      </div>
    </es-modal>
    <es-modal title="Cadastrar Follow Up" :config="modalFollowUp" style="z-index: 9999;">
      <es-form :config="frmFollowUp"></es-form>
      <div style="margin-top: 10px;">
        <!-- es-table :config="tblFollowUp" :rows="followup">
        </es-table -->
      </div>
    </es-modal>
    <es-modal :title="'ID: ' +  pagamento.id" :config="modalDetalhe">
      <es-title style="float: none;display: block;" :title="pagamento.favorecido">
        <br><br><br>
        <div slot="right">
          <a v-if="(pagamento.status == 5 && pagamento.comprovante == true)" class="button is-info" @click="comprovanteDownload(idx)">
            <span>Comprovante </span>
            <span class="icon">
              <icon name="download"></icon>
            </span>
          </a>
          <a v-if="(pagamento.comprovante == true)" class="button is-success" @click="modalAddFollowUp()">
            <span>Enviar Para Pendente </span>
            <span class="icon">
              <icon name="refresh"></icon>
            </span>
          </a>
          <div v-else class="button is-danger" @click="toggleModalRegerar()">Regerar</div>
        </div>
      </es-title>
        <div class="columns">
          <div class="column is-6">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Banco</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.banco" readonly>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Agência</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.agencia" readonly>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Conta</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.favorecido_conta" readonly>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Documento</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="formatarDoc" readonly>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Valor</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.valor"  readonly>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Arquivo</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.arquivo_nome" readonly>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Data</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.data | date" readonly>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Número</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="pagamento.numero" readonly>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import settings from '@/settings'
import utils from '@/utils'

export default {
  data () {
    return {
      idx: 0,
      tabAbas: 0,
      conta_id: 0,
      abas: [],
      data: '',
      f1: {
        fields: [
          {name: 'id', label: 'ID', placeholder: 'ID', width: 1, type: 'text'},
          {name: 'data', label: 'Data Inicial', type: 'data', width: 1, value: ''},
          {name: 'dataF', label: 'Data Final', type: 'dataF', width: 1, value: ''},
          {
            name: 'apresentante_id',
            label: 'Apresentante',
            type: 'select',
            width: 2,
            values: [
              {value: 0, text: 'Todos Apresentante'}
            ]
          },
          {
            name: 'UF',
            label: 'Estado',
            placeholder: 'UF',
            type: 'select',
            width: 1,
            values: [
              {value: '0', text: 'Todos'},
              {value: 'AC', text: 'Acre'},
              {value: 'AL', text: 'Alagoas'},
              {value: 'AP', text: 'Amapá'},
              {value: 'AM', text: 'Amazonas'},
              {value: 'BA', text: 'Bahia'},
              {value: 'CE', text: 'Ceará'},
              {value: 'DF', text: 'Distrito Federal'},
              {value: 'ES', text: 'Espírito Santo'},
              {value: 'GO', text: 'Goiás'},
              {value: 'MA', text: 'Maranhão'},
              {value: 'MT', text: 'Mato Grosso'},
              {value: 'MS', text: 'Mato Grosso do Sul'},
              {value: 'MG', text: 'Minas Gerais'},
              {value: 'PA', text: 'Pará'},
              {value: 'PB', text: 'Paraíba'},
              {value: 'PR', text: 'Paraná'},
              {value: 'PE', text: 'Pernambuco'},
              {value: 'PI', text: 'Piauí'},
              {value: 'RJ', text: 'Rio de Janeiro'},
              {value: 'RN', text: 'Rio Grande do Norte'},
              {value: 'RS', text: 'Rio Grande do Sul'},
              {value: 'RO', text: 'Rondônia'},
              {value: 'RR', text: 'Roraima'},
              {value: 'SC', text: 'Santa Catarina'},
              {value: 'SP', text: 'São Paulo'},
              {value: 'SE', text: 'Sergipe'},
              {value: 'TO', text: 'Tocantins'}
            ]
          },
          {name: 'documento', label: 'Documento', placeholder: 'CPF/CNPJ', type: 'document', width: 2, value: ''},
          {
            name: 'conta_id',
            label: 'Contas',
            type: 'contaSelect',
            width: 2,
            values: [
              {value: 0, text: 'Escolha uma conta'}
            ]
          },
          {name: 'conta', label: 'Conta', placeholder: 'numero conta', type: 'contaText', width: 1, value: ''}
        ],
        button: {label: 'Localizar', side: 'right', action: this.localizar},
        buttonLimpar: {label: 'Limpar', side: 'right', action: this.limparFiltro}
      },
      // Coluna padrão para ordernar
      colunaSort: 'ID',
      // Opção de ascendente ou descendente, padrão inicia como asc
      colunaOrder: 'asc',
      arquivo: '',
      arquivoNome: '',
      pagamentos: {},
      pagamento: [],
      pagamentosFiltrados: {},
      favorecidos: [],
      tab: 0,
      tabFavorecido: 0,
      comprovantes: [],
      contaSelecionada: '',
      contasDebitos: [],
      achou: {},
      filtros_buscados:[],
      contasDebitosFiltradas: [],
      tabelaComprovantes: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'conta_numero', label: 'Conta Débito', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency', align: 'right'},
          {name: 'numero', label: 'Comprovante', align: 'center'},
          {name: 'data', label: 'Data', type: 'date', align: 'center'},
          {name: 'origem', label: 'Origem', align: 'left'}
        ],
        key: 'id'
      },
      tabelaFavorecido: {
        fields: [
          {name: 'conta_id', label: 'ID', align: 'right'},
          {name: 'favorecido_nome', label: 'Nome'},
          {name: 'favorecido_documento', label: 'Documento', type: 'documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      tabela: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'codigo', label: 'Código', align: 'center'},
          {name: 'data_movimento', label: 'Dt. Movimento', type: 'date'},
          {name: 'comarca_custa', label: 'Comarca', type: ''},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'status', label: 'Status', align: 'center'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'tooltip', data: 'observacao'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'agencia', align: 'right'},
          {name: 'favorecido_conta', label: 'conta', align: 'right'},
          {name: 'usuario', label: 'Usuário', type: 'toupper', align: 'center'},
          {name: 'conta_debito_numero', label: 'Conta Débito', align: 'center'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'ocorrencia', label: 'Ocorrência', align: 'center', data: 'ocorrencia_descricao'},
          {name: 'temComprovante', label: '', type: 'comprovante'}
        ],
        sum: 'valor',
        key: 'id',
        button: true
      },
      modalFollowUp: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        habilitar: this.habilitar,
        save: this.salvar_FollowUp
      },
      modalDetalhe: {
        active: false,
        width: 'large',
        id: 'modalDetalhe'
      },
      modal: {
        active: false,
        cancel: this.cancel,
        save: this.salvar,
        width: 'large'
      },
      modalImportacao: {
        active: false,
        width: 'small',
        id: 'modalImportacao'
      },
      form: {
        data: {
          favorecido: '',
          banco: '',
          agencia: '',
          favorecido_conta: '',
          tipo_conta: '',
          contas: []
        },
        row: {},
        levels: [
          {
            fields: [
              {
                name: 'origem',
                label: 'Origem',
                placeholder: 'texto',
                type: 'select',
                width: 3,
                onExit: this.getContas,
                values: [
                  {value: 0, text: 'Avulso'},
                  {value: 5, text: 'Devolução'},
                  {value: 3, text: 'Cancelamento'},
                  {value: 2, text: 'Certidão'},
                  {value: 4, text: 'Obrigação de fazer'}
                ]
              },
              {name: 'np_apresentante_id', label: 'Apresentante', width: 3, type: 'select', values: []},
              {name: 'documento', label: 'Documento', placeholder: 'CPF, CNPJ ...', type: 'document', width: 3, onExit: this.getFavorecido},
              {name: 'favorecido', label: 'Favorecido', placeholder: 'Nome', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'banco', label: 'Banco', placeholder: '000', type: 'text', width: 1, onExit: this.validaDigitoBanco},
              {name: 'agencia', label: 'Agência/Dígito', info: 'Ex: 0001-2', placeholder: '00000-0', type: 'text', width: 2, onExit: this.aplicaMascaraAgencia},
              {name: 'favorecido_conta', label: 'Conta/Dígito', info: 'Ex: 00000-2', placeholder: '00000-0', type: 'text', onExit: this.aplicaMascaraConta},
              {
                name: 'tipo_conta',
                label: 'Tipo de Conta',
                placeholder: 'texto',
                type: 'select',
                width: 3,
                values: [
                  {value: 'C', text: 'Conta Corrente'},
                  {value: 'P', text: 'Conta Poupança'}
                ]
              },
              {name: 'valor', label: 'Valor', placeholder: 'R$ 0.00', type: 'moeda', width: 3, onKeyup: this.aplicaMascaraMoeda}
            ]
          },
          {
            fields: [
              {name: 'conta_debito_numero', label: 'Conta Debito', width: 3, type: 'select', values: []},
              {name: 'codigo', label: 'Identificação', placeholder: '0000', width: 9, type: 'text'}
            ]
          }
        ]
      },
      modalRegerar: {
        active: false,
        width: 'medium',
        cancel: this.cancel,
        save: this.salvar_FollowUp,
        id: 'modalRegerar'
      },
      frmFollowUp: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário Logado', width: 12, type: 'label'}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Motivo para regerar', type: 'textarea'}
            ]
          }
        ]
      }
    }
  },
  created () {
    this.abas = utils.origens
    this.pagamentos = utils.gerarOrigens()
    this.form.levels[0].fields[1].values.push({value: '0', text: 'Nenhum Apresentante'})
    this.form.data.tipo_conta = 'C'
    this.form.data.codigo = '0'
    this.getApresentantes()
    this.form.data['apresentante_id'] = 0
    this.form.data['conta_id'] = 0
  },
  mounted () {
    this.$refs.filtro.filter = { apresentante_id: 0, UF: '0', conta_id: 0}
    var data = new Date()
    var dia = data.getDate().toString().padStart(2, '0')
    var mes = (data.getMonth() + 1).toString().padStart(2, '0')
    var ano = data.getFullYear()
    this.$refs.filtro['data'] = dia + '/' + mes + '/' + ano
    this.$refs.filtro['dataF'] = dia + '/' + mes + '/' + ano
  },
  methods: {
    modalAddFollowUp () {
      this.frmFollowUp.data.tela = 'P'
      this.toggle_modalFollowUp()
    },
    salvar_FollowUp () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      this.frmFollowUp.data.fk_pg = this.pagamento.id
      this.frmFollowUp.data.status = this.pagamento.status
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        this.error('Informe o motivo.')
      } else {
        axios.post(`api/v1/batimento/pagamentos/regerar`, this.frmFollowUp.data).then((the) => {
          if (the.data === '0') {
            this.error('Nenhum pagamento afetado, confira os dados!')
          } else {
            this.success(the.data)
            this.toggle_modalFollowUp()
            this.modalDetalhe.active = false
            let arg = {}
            arg.data = this.f1.fields[1].value
            arg.dataF = this.f1.fields[2].value
            arg.apresentante_id = this.f1.fields[3].value
            arg.UF = this.f1.fields[4].value
            arg.documento = this.f1.fields[5].value
            arg.conta_id = this.f1.fields[6].value
            this.localizar(arg)
          }
        })
      }
    },
    mascaraNumero () {
      alert(this.form.data.valor)
    },
    getContas () {
      var origem = this.form.data.origem
      axios.get(`api/v1/batimento/contas_debito/${origem}`).then((res) => {
        this.form.levels[2].fields[0].values = []
        for (var i in res.data) {
          this.form.levels[2].fields[0].values.push({value: res.data[i], text: res.data[i]})
        }
      })

      if (origem === 8) {
        origem = 1
        axios.get(`api/v1/batimento/apresentantes/${origem}`).then((res) => {
          this.form.levels[0].fields[1].values = []
          this.form.levels[0].fields[1].values.push({ value: '0', text: 'Nenhum Apresentante' })
          for (var i in res.data) {
            this.form.levels[0].fields[1].values.push({value: res.data[i].id, text: res.data[i].codigo + ' - ' + res.data[i].nome})
          }
        })
      }
    },
    montar_abas (data) {
      if (data.length > 0) {
        this.tabAbas = data[0].origem
        console.log(this.tabAbas)
      }
      for (var i in data) {
        this.pagamentos[data[i].origem].push(data[i])
      }
    },
    novo () {
      this.form.data['apresentante_id'] = '0'
      this.tabFavorecido = 0
      this.modal.active = true
    },
    setarConta () {
      document.getElementById('conta').value = this.contaSelecionada
    },
    salvar () {
      var okGravar = 'True'
      if (this.form.data.banco.length < 3) {
        this.error('Informe 3 dígitos para o banco')
        okGravar = 'False'
        document.getElementById('banco').focus()
      } else if (this.form.data.agencia.length === 0) {
        this.error('Informe a agência')
        okGravar = 'False'
        document.getElementById('agencia').focus()
      } else if (this.form.data.favorecido_conta.length === 0) {
        this.error('Informe a conta')
        okGravar = 'False'
        document.getElementById('favorecido_conta').focus()
      } else if (this.form.data.valor.length === 0) {
        this.error('Informe o valor')
        okGravar = 'False'
        document.getElementById('moeda').focus()
      } else if ((this.form.data.valor === '0') || (this.form.data.valor === '0,0') || (this.form.data.valor === '0,00') || (this.form.data.valor === '')) {
        this.error('Informe o valor do pagamento')
        okGravar = 'False'
        document.getElementById('moeda').focus()
      } else if ((this.form.data.codigo === undefined) || (this.form.data.codigo === '')) {
        this.form.data.codigo = '000'
        okGravar = 'True'
      }
      if ((okGravar === 'True') || (this.form.data.origem !== 5)) {
        this.form.data['lancamento'] = 'M'
        this.form.data.valor = this.form.data.valor.replace('.', '')
        this.form.data.valor = this.form.data.valor.replace(',', '.')
        axios.post(`api/v1/batimento/pagamentos/cadastrar`, this.form.data).then((res) => {
          this.form.data = {}
          this.modal.active = false
          this.form.data.codigo = '0'
        })
        this.form.data['apresentante_id'] = '0'
      }
    },
    cancel () {
      this.form.data = {}
    },
    toggle_modalFollowUp () {
      this.modalFollowUp.active = !this.modalFollowUp.active
    },
    toggleModalImportacao () {
      this.modalImportacao.active = true
    },
    toggleModalRegerar () {
      this.modalRegerar.active = true
      var element = document.getElementById('modalRegerar')
      element.setAttribute('style', 'z-index: 100;')
    },
    importacao (arq) {
      this.showLoading()
      this.arquivo = arq.target.files || arq.dataTransfer.files
      this.arquivoNome = this.arquivo[0].name
      let formData = new FormData()
      formData.append('zip', this.arquivo[0])
      axios.post(`api/v1/batimento/pagamentos/comprovantes/`, formData).then((res) => {
        this.comprovantes = res.data
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
      this.modalImportacao.active = false
      this.tab = 1
    },
    comprovanteDownload (idx) {
      console.log(this.pagamentos[this.tabAbas][idx])
      axios.get(`/api/v1/batimento/pagamentos/comprovantes/download/${this.pagamentos[this.tabAbas][idx].numero}`).then((res) => {
        if (res.data === 'erro') {
          this.error('Pagamento sem retorno processado.Por favor, verificar junto ao Banco emissor.!')
        } else {
          var uriContent = 'data:application/pdf,' + escape(res.data)
          var link = document.createElement('a')
          link.href = uriContent
          link.download = 'Comprovante.pdf'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    detalhePagamento (idx) {
      this.idx = idx
      this.pagamento = Object.assign({}, this.pagamentos[this.tabAbas][idx])
      this.modalDetalhe.active = true
    },
    relatorioXls () {
      this.showLoading()
      var dt = this.f1.fields[1].value
      var dtf = this.f1.fields[2].value
      var aprecod = this.f1.fields[3].value
      var UF = this.f1.fields[4].value
      var contaid = this.f1.fields[6].value
      var doc = ''
      console.log(this.f1.fields[5].value)
      if ((this.f1.fields[5].value === undefined) || (this.f1.fields[5].value === '')) {
        doc = '0'
      } else {
        doc = this.f1.fields[5].value
      }
      axios.get(`api/v1/batimento/pagamentos/relatorio/xls/${dt}/${dtf}/${aprecod}/${UF}/${doc}/${contaid}`,
                {responseType: 'arraybuffer'}
      ).then((res) => {
        const arquivo = new Blob([res.data]),
        link = document.createElement('a')

        link.href = window.URL.createObjectURL(arquivo)
        link.download = 'Relatorio_Pagamento.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioPdf () {
      this.showLoading()
      var dt = this.f1.fields[1].value
      var dtf = this.f1.fields[2].value
      var aprecod = this.f1.fields[3].value
      var UF = this.f1.fields[4].value
      var contaid = this.f1.fields[6].value
      var doc = this.f1.fields[5].value
      if ((this.f1.fields[5].value === undefined) || (this.f1.fields[5].value === '')) {
        doc = '0'
      } else {
        doc = this.f1.fields[5].value
      }
      axios.get(`api/v1/batimento/pagamentos/relatorio/pdf/${dt}/${dtf}/${aprecod}/${UF}/${doc}/${contaid}/${this.tabAbas}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },

    limparFiltro(arg){
      this.f1.fields[1].value = ''
      this.f1.fields[2].value = ''
      this.f1.fields[3].value = ''
      this.f1.fields[4].value = ''
      this.f1.fields[6].value = ''
      this.f1.fields[6].name = ''
      this.f1.fields[7].value = ''
      this.f1.fields[7].name = ''
      var data = new Date()
      var dia = data.getDate().toString().padStart(2, '0')
      var mes = (data.getMonth() + 1).toString().padStart(2, '0')
      var ano = data.getFullYear()
      this.$refs.filtro['data'] = dia + '/' + mes + '/' + ano
      this.$refs.filtro['dataF'] = dia + '/' + mes + '/' + ano
      arg.conta_id = ''
      this.contasDebitosFiltradas.splice(0,this.contasDebitosFiltradas.length)
      this.contasDebitosFiltradas = []
      console.log(this.f1.fields[6].name)

    },
    localizar (arg) {
      if (arg.conta_id==0){
        arg.conta_id = ''
        var str = this.contaSelecionada
      }
      else{
        var str = arg.conta_id
      }
      console.log(arg)
      var posicao = str.indexOf('-')
      var strLimpa = str.substring(0, posicao)
      arg.conta = strLimpa
      if (strLimpa === '') {
        this.f1.fields[6].value = '0'
      } else {
        this.f1.fields[6].value = strLimpa
      }
      this.showLoading()
      this.pagamentos = utils.gerarOrigens()
      var data = arg.data
      var dataF = arg.dataF
      this.f1.fields[1].value = data
      this.f1.fields[2].value = dataF
      this.f1.fields[3].value = arg.apresentante_id
      this.f1.fields[4].value = arg.UF
      this.f1.fields[5].value = arg.documento
      if (arg.documento !== undefined) {
        arg.documento = utils.documentoNumero(arg.documento)
      }
      if ((dataF === undefined) || (data === '') || dataF === '') {
        this.error('Preencha os dois campos Data')
      } else {
        axios.post(`api/v1/batimento/pagamentos/relatorio`, arg).then((res) => {
          this.pagamentos = utils.gerarOrigens()
          this.pagamento = []

          if ((res.data.length !== undefined) && (parseInt(res.data.length) > 0)) {
            this.montar_abas(res.data)
            this.closeLoading()
            this.contaSelecionada = res.data[0].conta_debito_numero
            this.contasDebitos = [];
            // this.f1.fields[6].values = [];

            this.f1.fields[6].values = [{ value: 0, text: 'Escolha uma conta' }];

            const uniqueAccounts = new Set();
            for (var i in res.data) {
              const contaDebitoNumero = res.data[i].conta_debito_numero;
              if (!uniqueAccounts.has(contaDebitoNumero)) {
                this.contasDebitos.push({ 'value': contaDebitoNumero, 'text': contaDebitoNumero });
                this.f1.fields[6].values.push({ 'value': contaDebitoNumero, 'text': contaDebitoNumero });
                uniqueAccounts.add(contaDebitoNumero);
              }
            }
            
            this.contaSelecionada = 0;
            this.achou = 'true'
            this.contasDebitosFiltradas = []
            this.contasDebitosFiltradas.push({'valor': this.contasDebitos[0].value, 'texto': this.contasDebitos[0].value})

            for (var x in this.contasDebitos) {
              for (var y in this.contasDebitosFiltradas) {
                if (String(this.contasDebitosFiltradas[y].valor) === String(this.contaSelecionada)) {
                  this.achou = 'true'
                }
              }

              if (this.achou === 'false') {
                this.contasDebitosFiltradas.push({'valor': this.contaSelecionada, 'texto': this.contaSelecionada})
              }
              this.contaSelecionada = this.contasDebitos[x].value
              this.achou = 'false'
            }
            this.contaSelecionada = ''
            this.closeLoading()
          } else {
            this.closeLoading()
            this.error('Filtro não possui dados')
          }
        }).catch((err) => {
          this.error(err.response.data)
        })
      }
    },
    validaDigitoBanco () {
      if (this.form.data.banco.length < 3) {
        this.error('Informe 3 dígitos para o banco')
      }
    },
    aplicaMascaraMoeda () {
      var v = this.form.data.valor
      var valor = String(v)
      valor = valor.replace(/\D/g, '') // permite digitar apenas numero
      valor = valor.replace(/(\d{1})(\d{14})$/, '$1.$2') // coloca ponto antes dos ultimos digitos
      valor = valor.replace(/(\d{1})(\d{11})$/, '$1.$2') // coloca ponto antes dos ultimos 11 digitos
      valor = valor.replace(/(\d{1})(\d{8})$/, '$1.$2') // coloca ponto antes dos ultimos 8 digitos
      valor = valor.replace(/(\d{1})(\d{5})$/, '$1.$2') // coloca ponto antes dos ultimos 5 digitos
      valor = valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos
      this.form.data.valor = valor
      console.log(valor)
      return v
    },
    aplicaMascaraConta () {
      var v = this.form.data.favorecido_conta
      var valor = String(v)
      // valor = valor.replace(/\D/g, '') // permite digitar apenas numero
      // valor = valor.replace(/(\d{1})(\d{1,1})$/, '$1-$2') // coloca virgula antes dos ultimos 2 digitos
      this.form.data.favorecido_conta = valor
      return 'ok'
    },
    aplicaMascaraAgencia () {
      var v = this.form.data.agencia
      var valor = String(v)
      // valor = valor.replace(/\D/g, '') // permite digitar apenas numero
      // valor = valor.replace(/(\d{1})(\d{1,1})$/, '$1-$2') // coloca virgula antes dos ultimos 2 digitos
      this.form.data.agencia = valor
      return 'ok'
    },
    getApresentantes () {
      axios.get(`api/v1/batimento/apresentantes/filtro/`).then((res) => {
        for (var i in res.data) {
          this.f1.fields[3].values.push({value: res.data[i].codigo, text: res.data[i].codigo + ' - ' + res.data[i].nome})
          this.form.levels[0].fields[1].values.push({value: res.data[i].id, text: res.data[i].codigo + ' - ' + res.data[i].nome})
        }
      })
    },
    getFavorecido () {
      var documento = utils.documentoNumero(this.form.data.documento)
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.form.data.favorecido = ''
        this.form.data.banco = ''
        this.form.data.agencia = ''
        this.form.data.favorecido_conta = ''
        this.form.data.tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
        } else {
          this.warning('Favorecido não encontrado.')
          this.form.data.favorecido = ''
          this.form.data.banco = ''
          this.form.data.agencia = ''
          this.form.data.favorecido_conta = ''
          this.form.data.tipo_conta = ''
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.form.data.favorecido = this.favorecidos[idx].favorecido_nome
      this.form.data.banco = this.favorecidos[idx].favorecido_banco
      this.form.data.agencia = this.favorecidos[idx].favorecido_agencia
      this.form.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.form.data.tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    },
    set_tab (idx) {
      this.tabAbas = idx
    },
    getOrigem () {
      axios.get(`api/v1/batimento/pagamentos/`)
    },
    cell_function (idx) {
      alert(idx)
    }
  },
  computed: {
    formatarDoc: function () {
      var value = this.pagamento.documento
      console.log(value)
      if (value == null) {
        return '-'
      } else {
        let v = value
        v = v.replace(/\D/g, '')
        if (v.length < 14) {
          v = v.replace(/(\d{3})(\d)/, '$1.$2')
          v = v.replace(/(\d{3})(\d)/, '$1.$2')
          v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
        } else {
          v = v.replace(/^(\d{2})(\d)/, '$1.$2')
          v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
          v = v.replace(/(\d{4})(\d)/, '$1-$2')
        }
        return v
      }
    }
  }
}
</script>


<style lang="scss">

a.info {
font-family:verdana,arial,helvetica,sans-serif;
position:relative;
border-bottom:1px dashed #ccc;
text-decoration:none;
color:#333333;
}
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
 display:block;
 position:absolute;
 z-index:1;
 bottom:1em;
 width:500px !important;
 border:1px solid #F2AF1D;
 background-color:#FDF1D5;
 color:#000;
 text-align:justify;
 font-size:12px;
 font-weight:normal;
 padding:3px;
 line-height:15px;
}
.fa-icon {
  float: left !important;
}
.regerar {
  z-index: 100 !important;
}
.modal-background {
  background-color: rgba(10, 10, 10, 0.21) !important;
}
.area-main {
  display: block !important;
}
</style>
