<template>
  <div>
    <es-title title="Configuração do Sistema">
    </es-title>
    <div v-if="tab == 1">
      <es-modal title="Importar Gravações" :config="mdlRepasse" @save="incluir" @cancel="cancel">
        <textarea class="textarea" v-model="dados" rows="15"></textarea>
      </es-modal>
    </div>
    <div v-else-if="tab == 2">
    </div>
    <div v-else-if="tab == 3">
    </div>
    <div v-else>
      <div class="tabs is-boxed">
        <ul>
          <li v-for="(aba, idx) in abas " :class="{'is-active': tabMovimento == idx}" @click="setTab(idx)">
            <a>
              <span>{{ aba }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="tabMovimento == 1">
        <es-table :config="tabelaBancos" key="tblBancos" :rows="movimentoBancos"></es-table>
      </div>
      <div v-else>
        <es-table :config="tabelaEmpresas" key="tblEmpresas" :rows="movimentoEmpresas"></es-table>
      </div>
    </div>
    <es-modal :config="mdlGravacao">
      <h3 class="header">{{ frmGravacao.data.ids }}</h3>
      <es-form :config="frmGravacao"></es-form>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      posicaoEstados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'],
      posicaoEmpresas: ['237', '033', '399', '320', '001', '389', '707', '756', '745', '422', '755', '748', '041', '246', '962', '341', '604', '655', '643'],
      posicaoEmp: ['873', '869', '091', '097', '085', '224', '376'],
      tab: 1,
      tabMovimento: 1,
      postergadas: [],
      linhas: [],
      lista: [],
      dados: '',
      movimento: [],
      movimentoBancosLimpo: [],
      movimentoEmpresasLimpo: [],
      abas: {
        1: 'Habilitar UFs',
        2: 'Contas'
      },
      pagamentos: [],
      movimentoBancos: [],
      tblPagamentos: {
        fields: [
          {name: 'nome', label: 'Instituto'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta', label: 'conta', align: 'right'},
          {name: 'quantidade', label: 'Títulos', align: 'center'},
          {name: 'total', label: 'Total', type: 'currency'},
          {name: 'valor_retido', label: 'Retido', type: 'currency'},
          {name: 'valor', label: 'Repasse', type: 'currency'}
        ],
        sum: 'valor',
        key: 'id'
      },
      tabelaBancos: {
        fields: [
          {name: 'CRA', label: 'CRA', align: 'center'},
          {name: 'AC', label: 'AC', align: 'center'},
          {name: 'AL', label: 'AL', align: 'center'},
          {name: 'AM', label: 'AM', align: 'center'},
          {name: 'AP', label: 'AP', align: 'center'},
          {name: 'BA', label: 'BA', align: 'center'},
          {name: 'CE', label: 'CE', align: 'center'},
          {name: 'DF', label: 'DF', align: 'center'},
          {name: 'ES', label: 'ES', align: 'center'},
          {name: 'GO', label: 'GO', align: 'center'},
          {name: 'MA', label: 'MA', align: 'center'},
          {name: 'MG', label: 'MG', align: 'center'},
          {name: 'MS', label: 'MS', align: 'center'},
          {name: 'MT', label: 'MT', align: 'center'},
          {name: 'PA', label: 'PA', align: 'center'},
          {name: 'PB', label: 'PB', align: 'center'},
          {name: 'PE', label: 'PE', align: 'center'},
          {name: 'PI', label: 'PI', align: 'center'},
          {name: 'PR', label: 'PR', align: 'center'},
          {name: 'RJ', label: 'RJ', align: 'center'},
          {name: 'RN', label: 'RN', align: 'center'},
          {name: 'RO', label: 'RO', align: 'center'},
          {name: 'RR', label: 'RR', align: 'center'},
          {name: 'RS', label: 'RS', align: 'center'},
          {name: 'SC', label: 'SC', align: 'center'},
          {name: 'SE', label: 'SE', align: 'center'},
          {name: 'SP', label: 'SP', align: 'center'},
          {name: 'TO', label: 'TO', align: 'center'}
        ],
        key: 'id'
      },
      tabelaEmpresas: {
        fields: [
          {name: 'CRA', label: 'CRA', align: 'center'},
          {name: 'AC', label: 'AC', align: 'center'},
          {name: 'AL', label: 'AL', align: 'center'},
          {name: 'AM', label: 'AM', align: 'center'},
          {name: 'AP', label: 'AP', align: 'center'},
          {name: 'BA', label: 'BA', align: 'center'},
          {name: 'CE', label: 'CE', align: 'center'},
          {name: 'DF', label: 'DF', align: 'center'},
          {name: 'ES', label: 'ES', align: 'center'},
          {name: 'GO', label: 'GO', align: 'center'},
          {name: 'MA', label: 'MA', align: 'center'},
          {name: 'MG', label: 'MG', align: 'center'},
          {name: 'MS', label: 'MS', align: 'center'},
          {name: 'MT', label: 'MT', align: 'center'},
          {name: 'PA', label: 'PA', align: 'center'},
          {name: 'PB', label: 'PB', align: 'center'},
          {name: 'PE', label: 'PE', align: 'center'},
          {name: 'PI', label: 'PI', align: 'center'},
          {name: 'PR', label: 'PR', align: 'center'},
          {name: 'RJ', label: 'RJ', align: 'center'},
          {name: 'RN', label: 'RN', align: 'center'},
          {name: 'RO', label: 'RO', align: 'center'},
          {name: 'RR', label: 'RR', align: 'center'},
          {name: 'RS', label: 'RS', align: 'center'},
          {name: 'SC', label: 'SC', align: 'center'},
          {name: 'SE', label: 'SE', align: 'center'},
          {name: 'SP', label: 'SP', align: 'center'},
          {name: 'TO', label: 'TO', align: 'center'}
        ],
        key: 'id'
      },
      tblGravacoes: {
        fields: [
          {name: 'apresentante_nome', label: '', header: true},
          {name: 'total', label: 'Total', type: 'number', style: 'fn-bg-soft-grey'}
        ],
        formats: [
          {style: 'es-grey', field: 'status', condition: '=', value: 'G'},
          {style: 'es-blue', field: 'status', condition: '=', value: 'D'}
        ]
      },
      tblPostegadas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'data_gravacao', label: 'Data', type: 'date'},
          {name: 'quantidade', label: 'Qtd.', align: 'right'},
          {name: 'valor_gravacao', label: 'Valor Gravação', type: 'currency'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar'
      },
      mdlRepasse: {
        active: false,
        width: 'small',
        save: this.incluir
      },
      mdlGravacao: {
        active: false,
        width: 'medium',
        save: this.salvarGravacao,
        buttons: [
          {label: 'Postergar', action: this.postergar}
        ]
      },
      frmGravacao: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'quantidade', label: 'Quantidade', type: 'text'},
              {name: 'valor_gravacao', label: 'Valor Gravação', type: 'text'},
              {name: 'valor', label: 'Valor', type: 'text'}
            ]
          }
        ]
      },
      filtro: {
        fields: [
          {name: 'data', label: 'Data', placeholder: 'DD/MM/AAAA', type: 'text'}
        ],
        button: {label: 'Localizar', action: 'get_movimento', side: 'right'}
      },
      gravacoes: []
    }
  },
  created () {
    this.tab = 0
    this.movimentoBancosLimpo = [
    ]
    this.movimentoEmpresasLimpo = [
      {CRA: 'CASA', '873': ''},
      {CRA: 'BCR', '869': ''},
      {CRA: 'UNICRED', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'CREDSIS', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'AILOS', '873': '', '869': '', '091': '', '097': '', 'AMB': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'BANCO FIBRA', '873': '', '869': '', '091': '', '097': '', 'AMB': '224', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''},
      {CRA: 'BANCO JP MORGAN', '873': '', '869': '', '091': '', '097': '', 'AMB': '376', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': '', '': ''}
    ]
    this.movimentoBancos = this.movimentoBancosLimpo
    this.movimentoEmpresas = this.movimentoEmpresasLimpo
    this.data = utils.lastUtilDay()
    this.getMovimento()
    this.montaColunas()
  },
  methods: {
    mostrarModal () {
      this.tab = 1
      this.mdlRepasse.active = true
    },
    editar (idx) {
      this.form.data = Object.assign({}, this.pagamentos[idx])
      this.mostrarModal()
    },
    montaColunas () {
      for (var i in utils.ufs) {
        this.tblGravacoes.fields.push(
          {
            name: `${utils.ufs[i]}.valor`,
            label: utils.ufs[i],
            type: 'number',
            clickable: true,
            sum: true,
            onclick: this.tblGravacaoCellClick,
            formats: [
              `>(*,${utils.ufs[i]}.quantidade,${utils.ufs[i]}.valor_gravacao):es-danger`,
              `<(*,${utils.ufs[i]}.quantidade,${utils.ufs[i]}.valor_gravacao):es-link`
            ]
          }
        )
      }
    },
    tblGravacaoCellClick (idx, field) {
      this.frmGravacao.data = this.gravacoes[idx][field] === undefined ? {} : Object.assign({}, this.gravacoes[idx][field])
      this.frmGravacao.data.ids = this.gravacoes[idx].gravacoes === undefined ? [] : this.gravacoes[idx].gravacoes
      this.frmGravacao.data.apresentante = this.gravacoes[idx].apresentante_codigo
      this.frmGravacao.data.uf = field
      this.mdlGravacao.subtitle = `${this.gravacoes[idx].apresentante_nome} - ${field}`
      this.mdlGravacao.active = true
    },
    getValores () {
      this.gravacoes = []
      this.showLoading()
      axios.post(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.gravacoes = res.data
        this.closeLoading()
        this.tab = 2
      }).catch((err) => {
        this.error('Erro ao carregar as gravações')
      })
    },
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.closeLoading()
      }).catch((err) => {
        this.closeLoading()
      })
    },
    incluir () {
      this.showLoading()
      var linhas = this.dados.split(';')
      axios.patch(`api/v1/batimento/gravacao/pagamento`, linhas).then((res) => {
        this.pagamentos = res.data
        this.mdlRepasse.active = false
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.linhas = []
        this.modal.active = false
        this.getMovimento()
      })
      this.modal.active = false
    },
    salvarGravacao () {
      var apre = this.frmGravacao.data.apresentante
      var uf = this.frmGravacao.data.uf
      axios.put(`api/v1/batimento/gravacao/${this.data}/${apre}/${uf}`, this.frmGravacao.data).then((res) => {
        this.mdlGravacao.active = false
        this.success(res.data)
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    cancel () {
      this.modal.active = false
    },
    gerarGravacoes () {
      this.showLoading()
      axios.post(`api/v1/batimento/gravacao/pagamento`, this.pagamentos).then((res) => {
        this.pagamentos = []
        this.linhas = []
        this.dados = ''
        this.tab = 2
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getRepasse () {
      this.showLoading()
      axios.put(`api/v1/batimento/gravacao`).then((res) => {
        this.pagamentos = res.data
        this.tab = 1
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    cancelarGravacoes () {
      this.pagamentos = []
      this.linhas = []
      this.dados = ''
      this.tab = 0
    },
    setTab (idx) {
      this.tabMovimento = idx
    },
    limpaGrid () {
      var movimentoKeys = []
      for (var movimento in this.movimento) {
        movimentoKeys = Object.keys(this.movimento[movimento])
        for (var keys in movimentoKeys) {
          if (movimentoKeys[keys] !== 'CRA' && movimentoKeys[keys] !== '') {
            this.movimento[movimento][movimentoKeys[keys]] = ' '
          }
        }
      }
    },
    getQuantidades () {
      this.showLoading()
      this.movimento = this.movimentoBancosLimpo
      this.limpaGrid()
      this.movimento = this.movimentoEmpresasLimpo
      this.limpaGrid()
      axios.get(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.movimento = res.data
        for (var movimento in this.movimento) {
          if (this.posicaoEmpresas.indexOf(this.movimento[movimento].apresentante_codigo) !== -1) {
            this.movimentoBancos[this.posicaoEmpresas.indexOf(this.movimento[movimento].apresentante_codigo)][this.movimento[movimento].uf] = this.movimento[movimento].quantidade
          } else if (this.posicaoEmp.indexOf(this.movimento[movimento].apresentante_codigo) !== -1) {
            this.movimentoEmpresas[this.posicaoEmp.indexOf(this.movimento[movimento].apresentante_codigo)][this.movimento[movimento].uf] = this.movimento[movimento].quantidade
          }
        }
        this.closeLoading()
      }).catch((err) => {
        this.error(err)
      })
    },
    btnMudaTabOnClick () {
      this.tab = this.tab === 2 ? 0 : 2
      this.getMovimento()
    },
    getPostergadas () {
      axios.get(`api/v1/batimento/gravacao`).then((res) => {
        this.postergadas = res.data
        this.tab = 3
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getMovimento () {
      switch (this.tab) {
        case 0:
          this.getQuantidades()
          break
        case 2:
          this.getValores()
          break
      }
    },
    gravacoesValores () {
      this.get()
    },
    postergar () {
      var apre = this.frmGravacao.data.apresentante
      var uf = this.frmGravacao.data.uf
      axios.post(`api/v1/batimento/gravacao/${this.data}/${apre}/${uf}`).then((res) => {
        this.mdlGravacao.active = false
        this.success(res.data)
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    fechar () {
      axios.put(`api/v1/batimento/gravacao/${this.data}`).then((res) => {
        this.pagamentos = res.data
        this.tab = 1
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazer () {
      axios.patch(`api/v1/batimento/gravacao/trazer/${this.data}`, utils.getIDs(this.postergadas)).then((res) => {
        this.tab = 2
        this.getMovimento()
      }).catch((err) => {
        this.error(err.response.data)
      })
    }
  }
}
</script>

<style>

</style>
