<template>
  <div>
    <es-title title="Arquivo">
      <div slot="left">
        <es-datepicker v-model="data" @selected="get()"></es-datepicker>
      </div>
    </es-title>
    <es-modal title="Pagamentos " :config="m1">
      <es-title :title="arquivo.nome" :size="2"><div slot='right'><a class="button is-success" @click="downloadArquivo(idx)"><icon name='download'></icon></a></div></es-title>
      <es-table :config="tabelaPagamentos" :rows="pagamentos"></es-table>
    </es-modal>
    <div>
      <es-table :config="tabela" :rows="arquivos" @rowclick="this.ver_pagamentos"></es-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import utils from '@/utils'
import settings from '@/settings'

export default {
  data () {
    return {
      lancamento: {
        'A': 'AUTOMÁTICO',
        'M': 'MANUAL'
      },
      idx: 0,
      origens: [],
      data: '',
      arquivos: [],
      arquivo: '',
      pagamentos: [],
      tabela: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data do Arquivo', type: 'date'},
          {name: 'sequencia', label: 'Sequência', align: 'right'},
          {name: 'nome', label: 'Nome do Arquivo'},
          {name: 'nome_usuario', label: 'Usuário', align: 'center', type: 'toupper'},
          {name: 'total', label: 'Valor', type: 'currency'}
        ],
        sum: 'total',
        key: 'id',
        button: true
      },
      tabelaPagamentos: {
        fields: [
          {name: 'pagamento_id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', align: 'center', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'favorecido_nome', label: 'Favorecido', align: 'lef', type: 'toupper'},
          {name: 'favorecido_documento', label: 'Documento', type: 'documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'center'},
          {name: 'favorecido_conta', label: 'Conta', align: 'center'},
          {name: 'usuario_gerador_nome', label: 'Usuário', align: 'left', type: 'toupper'},
          {name: 'lancamento', label: 'Tipo', align: 'center'},
          {name: 'origem', label: 'Origem', align: 'left'},
          {name: 'conta_debito_numero', label: 'Conta Débito', align: 'center'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        key: 'id',
        button: true
      },
      m1: {
        active: false,
        width: 'huge',
        buttons: [
          {label: 'Acao', action: 'console_oi', type: 'danger'}
        ]
      }
    }
  },
  created () {
    this.data = moment().format('DD/MM/YYYY')
    this.origens = utils.origens
    this.get(this.origens)
  },
  methods: {
    get () {
      axios.get(`api/v1/batimento/arquivos/${this.data}`).then((res) => {
        this.arquivos = res.data
      })
    },
    ver_pagamentos (idx) {
      this.idx = idx
      this.arquivo = this.arquivos[idx]
      this.pagamentos = []
      axios.get(`api/v1/batimento/arquivos/pagamentos/${this.arquivo.id}`).then((res) => {
        var pagamento = res.data
        for (var pag in pagamento) {
          pagamento[pag].origem = this.origens[pagamento[pag].origem]
          this.pagamentos.push(pagamento[pag])
        }
      })
      this.m1.active = true
    },
    downloadArquivo (idx) {
      window.location.href = `${settings.baseURL}/api/v1/batimento/arquivos/download/${this.arquivos[idx].nome}`
      this.success('Download feito com sucesso!')
    }
  }
}
</script>
