import Vue from 'vue'
import utils from '@/utils'
import moment from 'moment'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('currency', function (value) {
  if (!value) return ''
  return `R$ ${utils.numberFormat(value)}`
})

Vue.filter('number', function (value) {
  if (!value) return ''
  return `${utils.numberFormat(value)}`
})

Vue.filter('date', function (value) {
  if (!value) return ''
  return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
})

Vue.filter('type', function (value, type) {
  if (!value) return ''
  switch (type) {
    case 'currency':
      return `R$ ${utils.numberFormat(value)}`
    case 'number':
      return `${utils.numberFormat(value)}`
    case 'date':
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    case 'documento':
      return utils.documento(value)
    case 'nozero':
      return value === '0' ? '' : value
    case 'toupper':
      var val = value.toString()
      return val.toUpperCase()
    default:
      return value
  }
})

Vue.filter('nozero', function (value) {
  if (!value) return ''
  return value === '0' ? '' : value
})

Vue.filter('documento', function (value) {
  return utils.documento(value)
})
export default {
}
