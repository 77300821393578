<template>
    <div class="level">
      <div class="level-left">
        <div class="level-item"><p :class="{'title':  true, 'is-3': size === '1', 'is-4': size === '2', 'is-5': size === '3'}">{{ title }}</p></div>
        <div class="level-item"><slot name="left"></slot></div>
      </div>
      <div class="level-right">
        <slot name="right"></slot>
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    title: {
      default: ''
    },
    size: {
      default: '1'
    }
  }
}
</script>

<style lang="sass">

</style>
