<template>
  <div>
    <es-title title="Solicitação">
      <div slot="right"><div class="button is-success" @click="load"><icon name="download"></icon></div></div>
    </es-title>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="key in Object.keys(solicitacoes)" v-if="solicitacoes[key].dados.length > 0" :class="{'is-active': tab == key}" @click="tab = key">
          <a>
            <span>{{ solicitacoes[key].nome }}</span>
            <span class="tag is-danger is-rounded">{{ solicitacoes[key].dados.length }}</span>
          </a>
        </li>
      </ul>
    </div>
    <es-table :config="tbl" :rows="solicitacoes[tab].dados" @rowclick="mdlOpen">
      <div slot="buttons">
        <div class="button" @click="gerarPagamentos()">Gerar</div>
      </div>
    </es-table>
    <es-modal title="Dados favorecido" :config="mdl">
      <es-form v-if="tabFavorecido == 0" :config="frm"></es-form>
      <es-table v-else-if="tabFavorecido == 1" :config="tblFavorecido" :rows="favorecidos" @rowclick="setFavorecido"></es-table>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      solicitacoes: {
        'T': {nome: 'CERTIDÃO', dados: []},
        'C': {nome: 'CANCELAMENTO', dados: []}
      },
      tab: 'T',
      tabFavorecido: 0,
      favorecidos: [],
      tbl: {
        fields: [
          {name: 'codigo', label: 'Código', align: 'right'},
          {name: 'processo', label: 'Processo', align: 'center'},
          {name: 'protocolo', label: 'Protocolo', align: 'right'},
          {name: 'apresentante_nome', label: 'Apresentante', type: 'toupper'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_cidade', label: 'Cidade'},
          {name: 'cartorio_numero', label: 'Cartório', align: 'center'},
          {name: 'favorecido_documento', label: 'Documento', type: 'documento'},
          {name: 'favorecido_nome', label: 'Favorecido', type: 'toupper'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        toggle: 'gerar'
      },
      frm: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'favorecido_documento', label: 'Documento', width: 4, type: 'document', onExit: this.getFavorecido},
              {name: 'favorecido_nome', label: 'Favorecido', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'favorecido_banco', label: 'Banco', width: 2, type: 'text'},
              {name: 'favorecido_agencia', label: 'Agência', width: 3, type: 'text'},
              {name: 'favorecido_conta', label: 'Conta', width: 3, type: 'text'},
              {name: 'favorecido_tipo_conta', label: 'Tipo Conta', type: 'select', values: [{value: 'C', text: 'CORRENTE'}, {value: 'P', text: 'POUPANÇA'}]}
            ]
          }
        ]
      },
      tblFavorecido: {
        fields: [
          {name: 'favorecido_nome', label: 'Nome', type: 'toupper'},
          {name: 'favorecido_documento', label: 'Documento', onExit: this.getFavorecido},
          {name: 'favorecido_banco', label: 'Banco'},
          {name: 'favorecido_agencia', label: 'Agência'},
          {name: 'favorecido_conta', label: 'Conta'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      mdl: {
        active: false,
        cancel: this.cancel,
        save: this.salvar,
        width: 'medium'
      }
    }
  },
  created () {
    this.get()
  },
  methods: {
    get () {
      axios.get(`api/v1/batimento/solicitacao`).then((res) => {
        this.tab = ''
        this.montaDados(res.data)
      })
    },
    montaDados (data) {
      this.solicitacoes = {
        'T': {nome: 'CERTIDÃO', dados: []},
        'C': {nome: 'CANCELAMENTO', dados: []}
      }
      for (var i in data) {
        this.solicitacoes[data[i].tipo].dados.push(data[i])
      }
      this.tab = this.solicitacoes['T'].dados.length > 0 ? 'T' : 'C'
    },
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/solicitacao`).then((res) => {
        this.get()
        this.success('Solicitações carregadas com sucesso!')
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    mdlOpen (idx) {
      this.mdl.active = true
      this.frm.data = Object.assign({}, this.solicitacoes[this.tab].dados[idx])
    },
    salvar (idx) {
      axios.put(`api/v1/batimento/solicitacao`, this.frm.data).then((res) => {
        this.get()
        this.mdl.active = false
        this.frm.data = {}
      }).catch((err) => {
        this.mdl.active = false
        this.frm.data = {}
      })
    },
    gerarPagamentos () {
      this.showLoading()
      axios.post(`api/v1/batimento/solicitacao`, this.getIds()).then((res) => {
        this.get()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getIds () {
      var lista = []
      for (var i in this.solicitacoes[this.tab].dados) {
        if (this.solicitacoes[this.tab].dados[i].gerar) {
          lista.push(this.solicitacoes[this.tab].dados[i].id)
        }
      }
      return lista
    },
    getFavorecido () {
      var documento = this.frm.data.favorecido_documento
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.frm.data.favorecido_nome = ''
        this.frm.data.favorecido_banco = ''
        this.frm.data.favorecido_agencia = ''
        this.frm.data.favorecido_conta = ''
        this.frm.data.favorecido_tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${this.frm.data.favorecido_documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
        } else {
          this.warning('Favorecido não encontrado.')
          this.frm.data.favorecido_nome = ''
          this.frm.data.favorecido_banco = ''
          this.frm.data.favorecido_agencia = ''
          this.frm.data.favorecido_conta = ''
          this.frm.data.favorecido_tipo_conta = ''
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.frm.data.favorecido_nome = this.favorecidos[idx].favorecido_nome
      this.frm.data.favorecido_banco = this.favorecidos[idx].favorecido_banco
      this.frm.data.favorecido_agencia = this.favorecidos[idx].favorecido_agencia
      this.frm.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.frm.data.favorecido_tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    }
  }
}
</script>

<style>

</style>
