<template>
  <div class="">
    <es-title title="Pagfor">
      <div v-if="view > 0" slot="left">
        <div class="button" @click="view = 0">Voltar</div>
      </div>
    </es-title>
    <div v-if="view == 0" class="columns" style="display: block;">
      <div v-for="item in origens" class="column is-2" style="float: left;">
        <div class="card es-card">
          <header class="card-header">
            <p class="card-header-title">
              {{ origens_nome[item.origem].toUpperCase() }}
            </p>
          </header>
          <div class="card-content" >
            <div class="content">
              <a @click="get(item.origem, item.data_movimento_custa)">Quantidade: {{ item.quantidade }}</a><br>
              <a>Movimento: {{ item.data_movimento_custa | date}}</a>
              <h3>{{ item.total | currency }}</h3>
            </div>
          </div>
          <footer class="card-footer">
            <div class="button is-success card-footer-item" v-if="item.origem === 9"
              @click="gerarPorOrigemNovoSantander(item.origem, item.data_movimento_custa)">Gerar
            </div>
            <div v-else class="button is-info card-footer-item" @click="gerarPorOrigem(item.origem, item.data_movimento_custa)">Gerar</div>
            <br />
          </footer>
        </div>
      </div>
    </div>
    <div v-if="view == 1">
      <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
      <es-table :config="tb1" :rows="pagamentos">
        <div slot="buttons">
          <div class="button" @click="gerar()">Gerar</div>
        </div>
      </es-table>
    </div>
    <es-loading :show="loading"></es-loading>
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'
import settings from '@/settings'

export default {
  data () {
    return {
      view: 0,
      origens: [],
      origens_nome: utils.origens,
      pagamentos: [],
      subtitulo: '',
      origem: 0,
      abas: utils.origens,
      tb1: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'apresentante_nome_uf', label: 'Apresentante - UF', align: 'left'},
          {name: 'favorecido', label: 'Favorecido'},
          {name: 'documento', label: 'CPF/CNPJ', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'numero_conta_debito', label: 'Conta Débito', align: 'center'},
          {name: 'usuario', label: 'Usuário', align: 'center', type: 'toupper'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        sum: 'valor',
        toggle: 'gerar',
        key: 'id',
        button: true
      }
    }
  },
  created () {
    this.get_resumo()
  },
  methods: {
    get_resumo () {
      axios.get(`api/v1/batimento/pagamentos/pagfor/resumo`).then((res) => {
        this.origens = res.data
        this.view = 0
      })
    },
    get (origem, data) {
      this.showLoading()
      axios.get(`api/v1/batimento/pagamentos/pagfor/${origem}/${data}`).then((res) => {
        this.subtitulo = utils.origens[origem].toUpperCase()
        this.pagamentos = res.data
        this.view = 1
        this.closeLoading()
      })
    },
    montar_abas (data) {
      for (var i in data) {
        if (this.origens[data[i].origem] === undefined) {
          this.origens[data[i].origem] = {}
        }
        this.origens[data[i].origem] = data[i]
      }
    },
    gerar () {
      this.showLoading()
      var lista = []
      for (var i in this.pagamentos) {
        if (this.pagamentos[i].gerar) {
          lista.push(this.pagamentos[i].id)
        }
      }
      axios.patch('api/v1/batimento/pagamentos/pagfor', lista).then((res) => {
        this.get_resumo()
        //window.location.href = `${settings.baseURL}/api/v1/batimento/arquivos/download/${res.data.arquivo}`
        this.success(`Arquivo ${res.data.arquivo} gerado com sucesso!`)
      }).catch((err) => {
        this.error('Arquivo nao gerado!')
      })
    },
    gerarPorOrigem (origem, data) {
      this.showLoading()
      axios.post(`api/v1/batimento/pagamentos/pagfor/${origem}/${data}`).then((res) => {
        this.get_resumo()
        this.success(`Arquivo ${res.data.arquivo} gerado com sucesso!`)
        //window.location.href = `${settings.baseURL}/api/v1/batimento/arquivos/download/${res.data.arquivo}`
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    gerarPorOrigemNovoSantander (origem, data) {
      this.showLoading()
      axios.post(`api/v1/batimento/pagamentos/pagfor/novo/santander/${origem}/${data}`).then((res) => {
        this.get_resumo()
        this.success(`Arquivo ${res.data.arquivo} gerado com sucesso!`)
        //window.location.href = `${settings.baseURL}/api/v1/batimento/arquivos/download/${res.data.arquivo}`
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    gerarOrigens () {
      var obj = {}
      for (var i in this.origens) {
        obj[i] = {}
      }
      return obj
    },
    set_tab (idx) {
      this.tab = idx
    }
  }
}
</script>

<style lang="sass">
.card.es-card
  margin-left: 0.1em
  border-radius: 0.3em
  .card-header
    border-radius: 0.3em 0.3em 0em 0em
    border-bottom-color: 1px #ccc
    background: #fafafa
    p
      color: #206892
  .card-content.content
    display: flex
    justify-content: center
  .button
    border-radius: 0em 0em 0.3em 0.3em
</style>
