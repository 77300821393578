<template>
  <nav>
    <aside class="navigation">
      <ul>
        <li :class="(link.subMenu) ? 'has-sub':''" v-for="(link, idx, k) in anchors" :key="'rlk' + idx"  v-if="(acesso.indexOf(link.acesso) == -1) ">
          <router-link :to="link.to">{{ link.text }}
            <template v-if="(link.icon === undefined || link.icon === '')">
              <span v-if="(link.service != undefined && link.service != '' && count[link.to.slice(1)] > 0) && link.service == true" class="tag is-rounded is-light" style="float:right;">
                {{ count[link.to.slice(1)] }}
              </span>
            </template>
            <template v-else>
              <icon v-if="count[link.to.slice(1)]" name="circle" scale="0.7" style="float:right;color:#22c65b;"></icon>
            </template>
          </router-link>
          <ul v-if="(link.subMenu)">
            <li v-for="(links, idxs, ks) in link.subMenu">
              <router-link :to="links.to">{{ links.text }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  </nav>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      acesso: [],
      paused: true,
      count: {
        pendentes: 0,
        pagfor: 0,
        solicitacao: 0,
        aprovacao: 0,
        liquidacao: false,
        gravacao: false
      }
    }
  },
  props: ['anchors', 'open'],
  created () {
    this.execute()
    this.getMenu()
  },
  methods: {
    execute () {
      var self = this
      function exec (self) {
        if (!self.$session.exists()) {
          self.$router.push('/login')
        }
        if (!self.paused) {
          axios.get('api/v1/batimento/menu/count').then((res) => {
            self.count.pendentes = res.data.pendentes
            self.count.pagfor = res.data.pagfor
            self.count.solicitacao = res.data.solicitacao
            self.count.aprovacao = res.data.aprovacao
            self.count.liquidacao = res.data.liquidacao
            self.count.gravacao = res.data.gravacao
            self.paused = false
          }).catch((err) => {
            if (err.response.status === 401) {
              self.logout()
            }
            self.paused = false
          })
          self.paused = true
        }
      }
      exec(self)
      setInterval(() => {
        if (!self.$session.exists()) {
          if (this.$route.path !== '/login'){
            self.$router.push('/login')
          }
        }
        if (!self.paused) {
          axios.get('api/v1/batimento/menu/count').then((res) => {
            self.count.pendentes = res.data.pendentes
            self.count.pagfor = res.data.pagfor
            self.count.solicitacao = res.data.solicitacao
            self.count.aprovacao = res.data.aprovacao
            self.count.liquidacao = res.data.liquidacao
            self.count.gravacao = res.data.gravacao
            self.paused = false
          }).catch((err) => {
            if (err.response.status === 401) {
              self.logout()
            }
            self.paused = false
          })
          self.paused = true
        }
      }, 2000)
    },
    getMenu () {
      this.acesso = this.$session.get('acesso')
    }
  }
}
</script>

<style lang="css">
.navigation {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
  max-width:190px;
}

.navigation ul,
.navigation ul li,
.navigation ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation ul {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
}

.navigation ul li {
  float: left;
  min-height: 0.05em;
  line-height: 1em;
  vertical-align: middle;
  position: relative;
}

.navigation ul li.hover,
.navigation ul li:hover {
  position: relative;
  z-index: 510;
  cursor: default;
}

.navigation ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 520;
  width: 100%;
}

.navigation ul ul li { float: none; }

.navigation ul ul ul {
  top: 0;
  right: 0;
}

.navigation ul li:hover > ul { visibility: visible; }

.navigation ul ul {
  top: 0;
  left: 99%;
}

.navigation ul li { float: none; }
.navigation ul ul { margin-top: 0.05em; }

.navigation {
  background: #005088;
  font-family: 'roboto', Tahoma, Arial, sans-serif;
}


.navigation:before {
  content: '';
  display: block;
}

.navigation:after {
  content: '';
  display: table;
  clear: both;
}

.navigation a {
  display: block;
  padding: 1em 1.3em;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}

.navigation > ul > li > a {
  border-right: 0.3em solid #eee;
  color: #ffffff;
  border-bottom: 0.3px solid #eee;
  font-size: 13px;
}

.navigation > ul > li > a:hover { color: #060606; }

.navigation > ul > li a:hover,
.navigation > ul > li:hover a { background: #4c93c7; }

.navigation li { position: relative; }

.remover ul li.has-sub > a:after {
  content: '' !important;
  position: none !important;
  right: 0em !important;
}
.navigation ul li.has-sub > a:after {
  content: '»';
  position: absolute;
  right: 1em;
}

.navigation ul ul li.first {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.navigation ul ul li.last {
  -webkit-border-radius: 0 0 3px 0;
  -moz-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
  border-bottom: 0;
}

.navigation ul ul {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.navigation ul ul { border: 1px solid #eee; }

.navigation ul ul a {
 color: #060606;
 font-size:14px;
 text-transform:capitalize;
}

.navigation ul ul a:hover { color: #060606; }

.navigation ul ul li { border-bottom: 1px solid #818d95; }

.navigation ul ul li:hover > a {
  background: #4eb1ff;
  color: #ffffff;
}

.navigation.align-right > ul > li > a {
  border-left: 0.3em solid #4D2411;
  border-right: none;
}

.navigation.align-right { float: right; }

.navigation.align-right li { text-align: right; }

.navigation.align-right ul li.has-sub > a:before {
  content: '+';
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -6px;
}

.navigation.align-right ul li.has-sub > a:after { content: none; }

.navigation.align-right ul ul {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 598;
  width: 100%;
}

.navigation.align-right ul ul li.first {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}

.navigation.align-right ul ul li.last {
  -webkit-border-radius: 0 0 0 3px;
  -moz-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}

.navigation.align-right ul ul {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
</style>
